import React from 'react';
import { Button, Panel, PanelGroup, Popover, OverlayTrigger } from 'react-bootstrap';
import Loading from '../Loading';
import API from '../../utils/api';
import moment from 'moment';
import PanelInvitarAmigos from '../PanelInvitarAmigos';
import branchUtils from '../../utils/branchUtils';

const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

const popoverDetails = ( id, details ) => {
  return <Popover id={`popover-details-${ id }`}> <div className='flex-row '> <div className='flex-col col-center material-description'> { details } </div></div> </Popover>
}

const DetailsButton = ( { details, id, isRow } ) => {
  return (
    <OverlayTrigger trigger={ 'click' } rootClose placement={ isRow ? 'bottom' : 'right'} overlay={  popoverDetails( id,  details )  }>
      <Button className='btn-more flex-row flex-container justify align column'>
        <i className='ion-android-more-horizontal' style={ {fontSize:'1.2em'} }/>
      </Button>
    </OverlayTrigger>
  )
}

const GridItem = ( { material , handleShareFile} ) => {
  let formatIcon = null;
  switch (material.format) {
  case 'image':
    formatIcon = imageIcon;
    break;
  case 'video':
    formatIcon = videoIcon;
    break;
  case 'table':
    formatIcon = tableIcon;
    break;
  case 'text':
    formatIcon = textIcon;
    break;
  case 'gif':
    formatIcon = gifIcon;
    break;
  default:

  }

  // console.log('grid item');
  return (
    <div key={material.id} className='col-sm-12 col-md-4'>
      <div className='title-material flex-row row-no-padding row-center'>
        <div className='flex-col col-85 '>
          <div className=' name-material flex-row'>
            { material.name }
          </div>
          <div className='date-material flex-row'>
            { moment(material.date).format('MM/DD/YY') }
          </div>
        </div>
        <div className='flex-col col-10 col-center'>
          <DetailsButton details={ material.description} id={material.id} />
        </div>
      </div>
      <div className='flex-row row-center'>
        <div className='flex-col col-center text-center'>
          <img className='img-responsive materials-img' src={material.thumbnail} />
        </div>
      </div>
      <div className='flex-row row-center'>
        <div className='flex-col  col-center'>
          <div className='flex-col  col-center'>
            <a href={material.url} target='blank' >
              <Button className='btn-material btn-pick flex-row flex-container justify align column'> <i className='ion-android-download' /> </Button>
            </a>
          </div>
        </div>
        <div className='flex-col  col-center'>
          <Button onClick={ () => {  handleShareFile( material.url, material.category );  }} className='btn-material btn-blue flex-row flex-container justify align column'> <i className='ion-android-share'/> </Button>
        </div>
      </div>
    </div>
  )
}

const Materiales  = () => {

  const [materiales, setMateriales] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [showShareFile, setShowShareFile] = React.useState(false);
  const [branchLink, setBranchLink] = React.useState(null);
  const defaultLink = 'https://www.pickwin.mx/?referer=';

  const handleShareFile = (url, category) =>{
    setShowShareFile(!showShareFile);
    setBranchLink(url);
  }

  const whatsAppShareFile = () => {
    branchUtils.redirectToWhatsAppFile( branchLink, 'open');
  }

  React.useEffect(() => {
    API.fetchMaterials()
    .then( response => {
      const data = response.data;
      const grouped = groupBy(data.materials, 'category');
      console.log(grouped);
      setMateriales(grouped);
      setLoading(false);
    });
  }, []);

  const renderPanels = (categories) => {
    return Object.keys(categories).map((category, index) => {
      const materiales = categories[category].map(m => {
        return <GridItem material={m} key={m.id} handleShareFile={handleShareFile}/>
      });

      return (
        <Panel key={index} eventKey={index}>
          <Panel.Heading>
            <Panel.Title toggle>{category}</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <div className="flex-row row-center row-wrap">
              {materiales}
            </div>
          </Panel.Body>
        </Panel>
      );
    });
  };

  if ( loading ) {
    return (
      <div className="flex-row row-center">
        <div className="flex-col text-center">
          <Loading/>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex-row">
        <div className="flex-col">
          <h3>¿Necesitas Ayuda con diseño?</h3>
          <p>Usa nuestros materiales en tus publicaciones, ahorra tiempo y esfuezo</p>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <PanelInvitarAmigos
            isFile
            defaultLink={ defaultLink }
            title='¡Invita y gana recompensas!'
            branchLink={ branchLink }
            whatsAppInvitationReferer={ whatsAppShareFile }
            showPanel={ showShareFile }
            closePanel={ handleShareFile }
            />
          <PanelGroup accordion id="accordion-example">
            {renderPanels(materiales)}
          </PanelGroup>
        </div>
      </div>
    </>
  )

};


export default Materiales;