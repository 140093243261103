const localStorage = window.localStorage;
import numeral from 'numeral';
import utils from './utils';
import { registrate } from 'images/flechasIconBlanco-50.png';

// const fallback = 'https://pickwin.mx';
// const fallback = 'https://pickwin.net';
// const fallback = 'pickwin.test:8081';
// const fallback = 'https://www.pickwin.mx';
// const fallback = 'sales.pickwin.test:8080';

let fallback            = process.env.BRANCH_URL_TEST;
let branchKey           = process.env.BRANCH_KEY_TEST;

const buildProduction   = process.env.NODE_ENV == 'production';
const devMode           = process.env.NODE_ENV == 'development';


if( buildProduction ){
  fallback = process.env.BRANCH_URL_PROD;
  branchKey = process.env.BRANCH_KEY_PROD;
}

const branchUtils = {
  redirectChallengeToWhatsApp: (link, type, pool) => {
    /**
     * Check type of device
     * @type {string}
     */
    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    console.log(link, type, pool);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    console.log('redirectTOWA', link, type, device);
    let text = window.encodeURIComponent(
      `¿Ya viste esta quiniela? Éntrale ¡Tiene buena bolsa! ${link}`
    );

    // let text = window.encodeURIComponent(
    //   `Acepta el reto y juega la quiniela ${pool.name_replaced} ${link}`
    // );

    // console.log('text', text);
    if (type == 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    } else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  redirectReminderToWhatsApp: (link, type, copyType) => {
    /**
     * Check type of device
     * @type {string}
     */
    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    console.log(link, type, copyType);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    console.log('redirectTOWA', link, type, device);

    let text = window.encodeURIComponent(
      ` ${copyType} ${link}`
    );

    // console.log('text', text);
    if (type == 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    } else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  initBranchUser: () => {
    let userData = utils.getUserInfo();
    // console.log( 'initUser Branch', userData );

    if( !userData ){ return }
    branch.setIdentity( String(userData.id) , (err, data) => {
      // console.log(err);
      // console.log(data);
    });
  },
  redirectToWhatsApp: ( link, type ) => {
    /**
     * Check type of device
     * @type {string}
     */
    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    // console.log(navigator.userAgent);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    console.log('redirectTOWA', link, type, device);
    let text = window.encodeURIComponent(
      `Regístrate con este enlace para crear tu cuenta y poder jugar las mejores quinielas en Pickwin. ${link}`
    );

    console.log('text', text);
    if (type == 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    } else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  redirectToWhatsAppFile: ( link, type, category ) => {
    /**
     * Check type of device
     * @type {string}
     */

     let txt = null ;

    switch( category ){
      case 'materiales_info':
        txt ='Material Informativo';
        break;
      case 'materiales_venta':
        txt ='MAterial de venta';
        break;
      case 'tutoriales':
        txt ='Tutorial';
        break;
      case 'promociones':
        txt ='Promo';
        break;
      }



    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    // console.log(navigator.userAgent);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    console.log('redirectTOWA', link, type, device);
    let text = window.encodeURIComponent(
      `Te comparto este ${ txt } sobre Pickwin.  ${link}`
    );

    console.log('text', text);
    if (type == 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    }
    else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  initBranch: () => {
    console.log('Branch',branch);
    try{
      setTimeout( () => {
        utils.setSentryTag( 'adBlock', true );
        // utils.captureMessage(' Branch Unreachable ');
        return false
      } ,1500);
      branch.init(
        branchKey,
          (err, branchData) => {
            if( err ){
              console.error(err);
            }
            utils.setSentryTag( 'adBlock', false );
            console.log(branchData);
          let keys = null;
          if (!branchData || !branchData.data || branchData.data == '') {
            return;
          }
          if (branchData.hasOwnProperty('data_parsed')) {
            keys = Object.keys(branchData.data_parsed);
          }

          if (
            branchData.hasOwnProperty('data_parsed') &&
            keys !== null &&
            keys.length
          ) {
            let data = branchData.data_parsed;
            console.log('branchData', branchData );

            let userMetadata = { };


            if ( data.hasOwnProperty('~channel') ) {
              userMetadata['utm_source'] = data['~channel'];
            }
            if ( data.hasOwnProperty('~campaign') ) {
              userMetadata['utm_campaign'] = data['~campaign'];
            }
            //sobreescribir si traen otro distinto
            if ( data.hasOwnProperty('utm_source') ) {
              userMetadata['utm_source'] = data['utm_source'];
            }
            if ( data.hasOwnProperty('utm_campaign') ) {
              userMetadata['utm_campaign'] = data['utm_campaign'];
            }
            if ( data.hasOwnProperty('utm_medium') ) {
              userMetadata['utm_medium'] = data['utm_medium'];
            }
            if ( data.hasOwnProperty('utm_term') ) {
              userMetadata['utm_term'] = data['utm_term'];
            }
            if ( data.hasOwnProperty('utm_content') ) {
              userMetadata['utm_content'] = data['utm_content'];
            }
            if ( data.hasOwnProperty('referer') ) {
              userMetadata['referer'] = data['referer'];
            }
            if ( data.hasOwnProperty('u') ) {
              userMetadata['u'] = data['u'];
            }

            utils.setUserMetadata(userMetadata);

            if (!utils.isLogged()) {
              let redirObj;
              if (data.hasOwnProperty('poolId')) {
                redirObj = { id: data.poolId, game_type: 'pool' };
              } else if (data.hasOwnProperty('bracketId')) {
                redirObj = { id: data.bracketId, game_type: 'bracket' };
              }
              utils.saveDataNotLoggedUser(JSON.stringify(redirObj));
            }


            if ( !data.hasOwnProperty('$desktop_url') ) {
              console.log('%c Window Location Redirect', 'color: #2e8ccf; font-size:2em; ')
              console.log(data);

              if (
                data.hasOwnProperty('poolId') &&
                !data.hasOwnProperty('referer')
              ) {
                window.location = '/quinielaPop/quiniela-' + data.poolId + '/' + data.poolId;
              } else if ( data.hasOwnProperty('poolId') && data.hasOwnProperty('referer') ) {
                window.location = '/quinielaPop/quiniela-' + data.poolId + '/' + data.poolId + '?referer=' + data.referer;
              } else if (
                data.hasOwnProperty('bracketId') && data.hasOwnProperty('referer') ) {
                window.location = '/bracketPop/bracket-' + data.bracketId + '/' + data.bracketId;
              } else if (
                data.hasOwnProperty('bracketId') && data.hasOwnProperty('referer') ) {
                window.location = '/bracketPop/bracket-' + data.bracketId + '/' + data.bracketId + '?referer=' + data.referer;
              }
              else if (
                data.hasOwnProperty('survivorId') &&
                !data.hasOwnProperty('referer')
              ) {
                window.location =
                  '/survivorPop/survivor-' + data.survivorId + '/' + data.survivorId;
              } else if (
                data.hasOwnProperty('survivorId') &&
                data.hasOwnProperty('referer')
              ) {
                window.location =
                  '/survivorPop/survivor-' +
                  data.survivorId +
                  '/' +
                  data.survivorId +
                  '?referer=' +
                  data.referer;
              }
              else if( data.hasOwnProperty('$canonical_identifier') ){
                window.location =
                  '/landing/conoce-pickwin/?referer=' + data.referer;
              }
            }
          }
        }
      );
    }
    catch(error){
      console.error(error);
      utils.setSentryTag( 'adBlock', true );
      // utils.captureMessage('AdBlock on use');
    }

  },
  /**
   * @param { Object } game Ex. game.pool || game.bracket
   * @return {Promise}
   **/
  createLink: ( userData ) => {
    // console.log( '%c Create Link', 'font-size:2em;' );
    // console.log( userData.referalCode );

    let title, description, promise, linkData, dektopUrl;

    let utm_source   = encodeURIComponent('Vendedor');
    let utm_campaign = encodeURIComponent('Programa vendedores');
    let utm_term     = encodeURIComponent(userData.id);
    let utm_content  = encodeURIComponent('Link app');
    let nickName     = encodeURIComponent(userData.nick.trim());


    let imageUrl = null;
    title = 'Crea tu cuenta en Pickwin';
    description = 'Regístrate con este enlace para crear tu cuenta y poder jugar las mejores quinielas en Pickwin.';
    let urlLink  = `${fallback}/registrate?referer=${userData.referalCode}&u=${nickName}&utm_source=${utm_source}&utm_campaign=${utm_campaign}`;

    linkData = {
      // $fallback_url: fallback+'/survivorPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
      $desktop_url      : urlLink,
      $og_title         : title,
      $og_description   : description,
      $og_image_url     : imageUrl,
      utm_source        : utm_source,
      utm_term          : utm_term,
      utm_content       : utm_content,
      utm_campaign      : utm_campaign,
      referer           : userData.referalCode,
    };

    // console.log( linkData );
    promise = new Promise((resolve, reject) => {
      resolve(urlLink);
      //   console.log( branch);
      // setTimeout( () => {
      //   utils.setSentryTag( 'adBlock', true );
      //   // utils.captureMessage('Probably adBlock on use ');
      //   reject({error: 'Timeout'});
      // }, 2000);
      //   branch.link(
      //   {
      //     campaign: utm_campaign,
      //     channel: utm_source,
      //     data: linkData
      //   },
      //   (error, url) => {
      //     console.log(error, url);
      //     if (error) {
      //       reject(error);
      //     } else {
      //       resolve(url);
      //     }
      //   }
      // );
    });

    return promise;
  },
  /**
   * @param { Object } game Ex. game.pool || game.bracket
   * @return {Promise}
   **/
  createMaterialsLink: ( url, image, title ) => {
    console.log( '%c Create MAterials Link', 'font-size:2em;' );
    // console.log( userData.referalCode , url);
    let description, promise, linkData;
    let utm_source   = encodeURIComponent('Vendedor');
    let utm_campaign = encodeURIComponent('Programa vendedores');
    let utm_term     = encodeURIComponent(utils.getUserInfo().id);
    let utm_content  = encodeURIComponent('Link app');

    let imageUrl = image;
    title = title ;
    description = 'Mira...';

    linkData = {
      // $fallback_url: fallback+'/survivorPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
      $desktop_url      : '/',
      $og_title         : title,
      $og_description   : description,
      $og_image_url     : imageUrl,
      utm_source        : utm_source,
      utm_term          : utm_term,
      utm_content       : utm_content,
      utm_campaign      : utm_campaign,
    };

    console.log( linkData );
    promise = new Promise((resolve, reject) => {
      resolve('/');
      // console.log('branch promise');
      // branch.link(
      //   {
      //     campaign: utm_campaign,
      //     channel: utm_source,
      //     data: linkData
      //   },
      //   (error, url) => {
      //     // console.log(error, url);
      //     if (error) {
      //       reject(error);
      //     } else {
      //       resolve(url);
      //     }
      //   }
      // );
    });
    return promise;
  },
  createLinkInvitePool: ( userData, game  , gameType ) => {
    console.log( game );
    let title, description, promise, linkData;
    let available             = game.available / 100;
    let formatted             = new Intl.NumberFormat('es-MX', { style: 'currency', currency:'MXN' }).format(available);
    let utm_source            = encodeURIComponent('Vendedor');
    let utm_campaign          = encodeURIComponent('Programa vendedores');
    let utm_term              = encodeURIComponent(userData.id);
    let utm_content           = encodeURIComponent('Link app');
    let urlLink               =  '' ;
    let nickName              = encodeURIComponent(userData.nick.trim());

    let imageUrl = '';

    switch (gameType) {
      case 'pool':
      console.log( 'createLink invite pool', userData, game, gameType, fallback );

          urlLink = `${fallback}/quinielaPop/${!game.slug ? 'quinielaPrivada' : game.slug }/${ game.private ? game.pool_id : game.id}?referer=${userData.referalCode}&u=${nickName}&utm_source=${ utm_source }&utm_campaign=${utm_campaign}`;

          title='Te invito a jugar la quiniela '+game.name_replaced;
          description = `${nickName} te invito a jugar la quiniela ${ game.name_replaced} con la bolsa ${game.guaranteed_prize ? 'garantizada' : ''} de ${ formatted }`;
          linkData = {
            $desktop_url        : urlLink,
            $og_title           : title,
            $og_description     : description,
            $og_image_url       : imageUrl,
            poolId              : game.private ? game.pool_id : game.id  ,
            utm_source          : utm_source,
            utm_campaign        : utm_campaign,
            utm_term            : utm_term,
            utm_content         : utm_content,
            referer             : userData.referalCode,
          }
        break;
      case 'survivor':
          title='Te invito a jugar el survivor'+game.name_replaced;
          description = `${ nickName } te invito a jugar el survivor ${ game.name_replaced} con la bolsa ${ game.guarranteed_prize ? 'garantizada' : '' } de ${ formatted }`;
          urlLink = `${ fallback }/survivorPop/${ !game.slug ? 'survivorPrivado' : game.slug }/${ game.private ? game.survivor_id : game.id }?referer=${ userData.referalCode }&u=${ nickName }&utm_source=${ utm_source }&utm_campaign=${utm_campaign}`;
          linkData = {
            $desktop_url        : urlLink,
            $og_title           : title,
            $og_description     : description,
            $og_image_url       : imageUrl,
            poolId              : game.private ? game.survivor_id : game.id,
            utm_source          : utm_source,
            utm_campaign        : utm_campaign,
            utm_term            : utm_term,
            utm_content         : utm_content,
            referer             : userData.referalCode,
          }
        break;
        case 'bracket':
            title='Te invito a jugar el bracket'+game.name_replaced;
            description = `${nickName } te invito a jugar el bracket ${ game.name_replaced} con la bolsa ${ game.guarranteed_prize ? 'garantizada' : '' } de ${ formatted }`;
            urlLink = `${fallback}/bracketPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${nickName}&utm_source=${ utm_source }&utm_campaign=${utm_campaign}`;
            linkData = {
              $desktop_url        : urlLink,
              $og_title           : title,
              $og_description     : description,
              $og_image_url       : imageUrl,
              poolId              : game.id,
              utm_source          : utm_source,
              utm_campaign        : utm_campaign,
              utm_term            : utm_term,
              utm_content         : utm_content,
              referer             : userData.referalCode,
            }
        break;
        case 'group_pool':
            title='Te invito a jugar la quiniela '+game.name_replaced;
            description = `${nickName } te invito a jugar la quiniela ${ game.name_replaced} con la bolsa ${ game.guarranteed_prize ? 'garantizada' : '' } de ${ formatted }`;
            urlLink = `${fallback}/quinielaGrupoPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${nickName}&utm_source=${ utm_source }&utm_campaign=${utm_campaign}`;
            linkData = {
              $desktop_url        : urlLink,
              $og_title           : title,
              $og_description     : description,
              $og_image_url       : imageUrl,
              poolId              : game.id,
              utm_source          : utm_source,
              utm_campaign        : utm_campaign,
              utm_term            : utm_term,
              utm_content         : utm_content,
              referer             : userData.referalCode,
            }
        break;
    }

    // console.log( linkData );
    promise = new Promise( (resolve, reject) => {
      resolve(urlLink);
      // setTimeout( () => {
      //   debugger
      //   console.log('Time out exceeded! ');
      //   utils.setSentryTag( 'adBlock', true );
      //   utils.captureMessage('Probably adBlock on use ');
      //   resolve( urlLink );
      // }, 3500);
      // console.log('Branch Promiseinvite ', branch);
      // branch.link( {
      //     campaign: utm_campaign,
      //     channel: utm_source,
      //     data: linkData
      //   } ,
      // ( error , url ) => {
      //   console.log(error, url);
      //   if(error){
      //     reject( urlLink );
      //   } else {
      //       resolve(url);
      //     }
      //   }
      // )
    });

    // console.log( promise );
    return promise

  }
};


export default branchUtils;
