import React from 'react';

const ErrorField = ( { errorLabel,errorType, showError } ) => {
	console.log(errorLabel, errorType, showError);

	if( !showError ){		return null }

	return(
		<div className='error-field flex-row row-center'>
			<i className='ion-alert-circled alert-color ' style={ { fontSize:'1.2em'} }/>
			<div style={{marginLeft: '3px'}}> {errorLabel} </div>
		</div>
	)
}

export default ErrorField;
