import { withFormsy } from 'formsy-react';
import React from 'react';
import cvvHelper from 'images/cvvHelper.png';
import {
  Form,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  Popover,
  OverlayTrigger,
  ButtonToolbar,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  HelpBlock} from 'react-bootstrap/lib';
import MediaQuery from 'react-responsive';
import API from '../../utils/api';
import Slider from 'react-rangeslider';

import moment from 'moment';

import futbol from 'images/FutbolBall-50.png';
import americano from 'images/AmericanoBall-50.png';
import hockey from 'images/Hockey.png';


class ReportIssueFormsy extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.changeValue = ( event ) => {
    // console.log('event',event);
    if( !event.currentTarget ){
      this.props.setValue(event.value)
    } else {
      this.props.setValue(event.currentTarget.value)
    }
    }
  }

  render(){
    let conditionalComponent = null;
    const errorMessage = this.props.getErrorMessage();
    let helpMessages= this.props.help != undefined ? this.props.help.map((help, i) => (
      <HelpBlock key={i} >{help}</HelpBlock>
      ))
      : null;
      switch(this.props.type){
        case 'TEXT':
          conditionalComponent = (
            <div className='flex-col'>
              <FormGroup controlId={this.props.name}>
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl
                  componentClass='textarea'
                  type='email'
                  placeholder={this.props.placeholder}
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || '' }
                  onChange={(e) =>{this.changeValue(e); this.props.inputHandler(e);}}>
                </FormControl>
              </FormGroup>
              <span className='error-formulario'>{errorMessage}</span>
              {helpMessages}
            </div>
          )
        break;
        case 'NAME':
          conditionalComponent = (
            <div className='flex-col'>
              <FormGroup controlId={this.props.name}>
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl
                  type='text'
                  pattern="[A-Za-z0-9\s]*"
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) =>{this.changeValue(e); this.props.inputHandler(e);}}>
                </FormControl>
              </FormGroup>
              <span className='error-formulario'>{errorMessage}</span>
              {helpMessages}
            </div>
          )
        break;
        case 'MAIL':
          conditionalComponent = (
            <div className='flex-col'>
              <FormGroup controlId={this.props.name}>
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl
                  type='text'
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) =>{this.changeValue(e); this.props.inputHandler(e);}}>
                </FormControl>
              </FormGroup>
              <span className='error-formulario'>{errorMessage}</span>
              {helpMessages}
            </div>
          )
        break;
      }
      return (conditionalComponent)
  }
}

export default withFormsy(ReportIssueFormsy);
