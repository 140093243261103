import { withFormsy } from 'formsy-react';
import React from 'react';
import { FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import  { Button } from 'react-bootstrap/lib'
import ErrorField from '../ErrorField';

class RegisterInput extends React.Component{
  constructor(props){
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }
  changeValue(event) {
    if(event.currentTarget.value === ' ' ){return}
    this.props.setValue(event.currentTarget.value);
  }
  render() {
    const errorMessage = this.props.getErrorMessage();
    let conditionalInput = null;
    switch ( this.props.type ) {
    case 'CHECKBOX':
				//console.log( 'checkbox val' , this.props.name , this.props.checkboxValue );
      conditionalInput = (
        <React.Fragment>
          <label className="flex-row " >
            <div className="">
              <input
                type="checkbox"
                name={ this.props.name }
                id={ this.props.name }
                checked={ this.props.checkboxValue == true ? true : false }
                onChange={ e => { this.changeValue(e) ; this.props.inputHandler(e); } }
              />
              <div className={` checkbox-custom `} />
            </div>
            <div className="flex-col ">
              <span>{ this.props.label}</span>
            </div>
          </label>
      </React.Fragment>
        )
      break;
    case 'PASSWORD':
      conditionalInput = (
        <React.Fragment>
        <div className='flex-row row-center responsive-sm'>
          <FormGroup controlId={this.props.name} className='flex-col col-90 col-no-padding'>
          <ControlLabel>{ this.props.label }</ControlLabel>
            <FormControl
              type={this.props.viewPass ? 'text' : 'password'}
              placeholder={ this.props.label }
              minLength={ this.props.minLength }
              maxLength={ this.props.maxLength }
              defaultValue={ this.props.defaultValue }
              value={ this.props.getValue() || '' }
              onChange={ (e) => {this.changeValue(e); this.props.inputHandler(e);} }/>
          </FormGroup>
          {/*<Button
            className={`flex-col col-10 col-no-padding btn-view-pass btn-default ${ this.props.viewPass ? 'inactive' : 'active' }`}
            onClick={( e ) => { this.props.handleViewPass() } }>
              <i className={` ${ !this.props.viewPass ? 'ion-eye-disabled' : 'ion-eye' }`}/>
          </Button>*/}
        </div>
        <span className=' flex-row row-center error-formulario warn'>{ errorMessage }</span>
      </React.Fragment>

        )
      break;
    case 'NAME':
      conditionalInput = (
        <div className='flex-row row-center responsive-sm'>
          <FormGroup controlId={this.props.name} className='flex-col col-90 col-no-padding'>
            <ControlLabel> { this.props.label } </ControlLabel>
            <FormControl
              type={'text'}
              placeholder={this.props.label}
              defaultValue={this.props.defaultValue}
              value={this.props.getValue() || ''}
              onChange={(e) => {this.changeValue(e); this.props.inputHandler(e);}}/>
            <span className='error-formulario warn'>{errorMessage}</span>
          </FormGroup>
        </div>
     )
      break;
    case 'NICK':
      conditionalInput = (
        <div className='flex-row row-center responsive-sm'>
          <FormGroup controlId={this.props.name} className='flex-col col-90 col-no-padding'>
            <ControlLabel> { this.props.label } </ControlLabel>
            <FormControl
              type={'text'}
              placeholder={ this.props.label }
              minLength={ this.props.minLength }
              maxLength={ this.props.maxLength }
              defaultValue={this.props.defaultValue}
              value={this.props.getValue() || ''}
              onChange={(e) => {this.changeValue(e); this.props.inputHandler(e);}}/>
            <span className='error-formulario warn'>{errorMessage}</span>
            <ErrorField
              showError={ this.props.showError }
              errorLabel={ this.props.errorLabel }
              errorType={ this.props.errorType }
            />
          </FormGroup>
        </div>
     )
      break;
      case 'PHONE':
        conditionalInput = (
          <div className='flex-row row-center responsive-sm'>
            <FormGroup controlId={this.props.name} className='flex-col col-90 col-no-padding'>
            <ControlLabel> { this.props.label } </ControlLabel>
              <FormControl
                type={'number'}
                pattern="[0-9]*"
                inputMode='numeric'
                min={0}
                placeholder={ '5555555555' }
                defaultValue={this.props.defaultValue}
                value={this.props.getValue() || ''}
                onChange={(e) => {this.changeValue(e); this.props.inputHandler(e);}}/>
              <span className='error-formulario warn'>{errorMessage}</span>
            </FormGroup>
          </div>
       )
        break;
    case 'MAIL':
      conditionalInput = (
        <div className='flex-row row-center responsive-sm'>
          <FormGroup controlId={this.props.name} className='flex-col col-90 col-no-padding'>
          <ControlLabel> { this.props.label } </ControlLabel>
            <FormControl
              type={'email'}
              placeholder={ this.props.label }
              defaultValue={this.props.defaultValue}
              value={this.props.getValue() || ''}
              onChange={(e) => {this.changeValue(e); this.props.inputHandler(e);}}/>
            <span className='error-formulario warn'>{errorMessage}</span>
              <ErrorField
                showError={ this.props.showError }
                errorLabel={ this.props.errorLabel }
                errorType={ this.props.errorType }
              />
          </FormGroup>
        </div>
     )
      break;

    }

    return conditionalInput;
  }
}

export default withFormsy(RegisterInput);
