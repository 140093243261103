import React from 'react';
import {Modal, Button} from 'react-bootstrap/lib';
import Formsy from 'formsy-react';

import LoginInput from '../FormularioLogin/LoginInput'
import utils from '../../utils/utils';
import API from '../../utils/api';

import { Redirect } from 'react-router';

import { CSSTransitionGroup } from 'react-transition-group';


class LoggingOutPanel extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      canSubmit: false,
      errorLogin: false,
      fingerprint: '',
      showPanel: false,
      submitting: false,
      successLogin: false,
      userData: utils.getUserInfo(),
      userPassword: '',
    }

    this.initFingerprint = utils.initFingerprint.bind(this);

    this.hidePanel = () => {
      this.setState({ showPanel: false}, () => {
        setTimeout( () => {
          this.setState({ errorLogin: false ,successLogin: false , userPassword:'', fingerprint:''})
         } , 500)

      })
    }
    this.handlePassword = ( e ) => {
      console.log(e);
      this.setState( {userPassword: e.target.value} );
    }

    this.enableButton = ( ) => {
      this.setState({ canSubmit: true, } );
    }

    this.disableButton = ( ) => {
      this.setState({ canSubmit: false, } );
    }

    this.callbackLogin = ( data ) => {
      if(data.success){
        this.setState({
          submitting:false,
          errorIngresar: false,
          successLogin: true,
        }, () => {
          setTimeout( (  ) => { this.hidePanel() }, 1000)
        });
      }
      else {
        this.setState({
          submitting: false,
          successLogin: false,
          errorIngresar: true,
        });
      }
    }

    this.sendCredentials = ( ) => {

      this.setState({ submitting: true, errorIngresar: false, successLogin: false });

      if(utils.isLogged()){
        API.postLogoutData()
        .then( responseLogout => {
          console.log('LogOut...',responseLogout);
          if( responseLogout.data.success){
            API.postLoginData(
              this.state.userData.nick,
              this.state.userPassword,
              this.state.fingerprint,
            ).then( responseLogin => {
              console.log(responseLogin);
              if( !responseLogin ){
                console.error('Error Login');
                this.setState({
                  submitting:false,
                  errorIngresar: true,
                  successLogin: false,
                })
              }
              else {
                this.callbackLogin(responseLogin.data);
              }
            })
          }
          else {
            console.error( 'Error Logging Out');
            this.setState({
              submitting: false,
              successLogin: false,
              errorIngresar: false,
            })
          }
        })
      }
      else {
        API.postLoginData(
          this.state.userData.nick,
          this.state.userPassword,
          this.state.fingerprint,
        ).then( responseLogin => {
          console.log(responseLogin);
          if( !responseLogin ){
            console.error('Error Login');
            this.setState({
              submitting:false,
              errorIngresar: true,
              successLogin: false,
            })
          }
          else {
            this.callbackLogin(responseLogin.data);
          }
        })
      }
    }

    this.redirectAndLogout= ( ) => {
      if( utils.isLogged() && !this.state.redirect ) {
        API.postLogoutData()
        .then( response => {
          // console.log(response);
          // if(response.data.success){
            this.setState({ redirect: true, })
          // }
          // else {
          //   console.error( 'Error Logging Out');
          //     this.setState({
          //       submitting: false,
          //       successLogin: false,
          //       errorIngresar: false,
          //     })
          // }
        })
      }
    }

  }

  componentDidMount(){
    this.setState({
      showPanel: utils.isTokenExpired( utils.getToken() ),
    }, () => {
      if( window.requestIdleCallback ){
        requestIdleCallback( ( ) => {
          // console.log('requestIdleCallback');
          this.setState({
            fingerprint:this.initFingerprint()
          })
        });

      }
      else {
        setTimeout( () => {
          // console.log('requestIdleCallback Didnt exist');
          this.setState({
            fingerprint:this.initFingerprint()
          })
        }, 500)
      }

    })
  }

  componentDidUpdate(prevProps, prevState){
    let isTokenExpired = utils.isTokenExpired( utils.getToken());
    // console.log( isTokenExpired );
    if( isTokenExpired && !prevState.showPanel){
      this.setState({ showPanel : true})
    }
    if( this.props.type == 'expirationLogout' && !prevState.showPanel && isTokenExpired && !this.state.redirect ){
      setTimeout( () => {
        console.log('Redirect');
        this.redirectAndLogout();
      }, 1500 )
    }
  }

  render (){
    let conditionalComponent = null;




    if( this.state.redirect ){
      conditionalComponent = (
        <Redirect push to={{ pathname: `/ingresar` }}/>
        )
    }
    else {

      switch(this.props.type){
        case 'expired':
        if(this.state.successLogin){
            conditionalComponent = (
              <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={200}
                transitionEnter={false}
                transitionLeave={true}
                transitionLeaveTimeout={200}>
              <div className="panel-abonar">
              <div id="panelTitle"
                className={`flex-row row-no-padding panel-abonar-titulo `}>
                  <span>Tú sesión se reaundó con éxito</span>
                </div>
                <div className="flex-row pickwin-panel">
                  <div className='flex-col col-80 col-offset-10'>
                    <div className="flex-container justify align column text-center" style={ { padding:'10px 0' } } >
                      <i className="ion-load-a loading " style={ { fontSize:'3em' } }/>
                      <strong> Estamos recuperando tu información... </strong>
                    </div>
                  </div>
                </div>
              </div>
              </CSSTransitionGroup>
          )
        }
        else {
          conditionalComponent = (
            <div className="panel-abonar">
            <div id="panelTitle"
              className={`flex-row row-no-padding panel-abonar-titulo `}>
                <span>Tú sesión ha expirado</span>
              </div>
              <div className="flex-row panel-pickwin">
                <div className='flex-col col-80 col-offset-10'>
                  <div className="flex-container justify align column text-center" style={ { padding:'10px 0' } } >
                    <i className="ion-alert-circled " style={ { fontSize:'3em', color:'#d6242b' } }/>
                    <strong> Si quieres reanudar tu sesión como <span style={ {color:'#78bc28'} }> {this.state.userData.nick} </span> ingresa tu contraseña nuevamente.</strong>
                  </div>
                </div>
              </div>
              <div className='flex-row'>
              <Formsy
                className='flex-col col-60 col-offset-20'
                onValidSubmit={this.sendCredentials}
                onValid={this.enableButton}
                onInvalid={this.disableButton}>
                <LoginInput
                  inputHandler={this.handlePassword}
                  label='Contraseña'
                  name="password"
                  required
                  type='PASS'
                  validations={ 'isExisty' }
                />
                {
                  this.state.errorIngresar
                    ? <div className="flex-row error-formulario">
                      <i className="ion-alert-circled"></i>Esta contraseña no pertence a {this.state.userData.nick} intentalo nuevamente.
                    </div>
                    : null
                }
                <div className='flex-row'>
                  <Button disabled={ this.state.submitting }  onClick={() => { this.redirectAndLogout(); }} className='flex-col col-45  btn-pick btn-rojo'> No, Gracias </Button>
                  <Button disabled={ !this.state.canSubmit || this.state.submitting } onClick={this.sendCredentials} className='flex-col col-45 col-offset-10 btn-pick '> Ingresar </Button>
                </div>
              </Formsy>
              </div>
            </div>
          )
        }
        break;
        case 'loggingOut':
          conditionalComponent = (
            <div className="panel-abonar">
            <div id="panelTitle"
              className={`flex-row row-no-padding panel-abonar-titulo `}>
                <span>Cerrando sesión </span>
              </div>
              <div className="flex-row panel-pickwin">
                <div className='flex-col col-80 col-offset-10'>
                  <div className="flex-container justify align column text-center" >
                    <i className="ion-ios-stopwatch-outline expiredTime"/>
                    <span> Te estamos redirigiendo. </span>
                    <strong> Inicia sesión nuevamente para poder jugar. </strong>
                  </div>
                </div>
              </div>
              </div>
          )
        break;
        case 'expirationLogout':
          conditionalComponent = (
            <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={200}
                transitionEnter={false}
                transitionLeave={true}
                transitionLeaveTimeout={200}>
              <div className="panel-abonar">
              <div id="panelTitle"
                className={`flex-row row-no-padding panel-abonar-titulo `}>
                  <span> {!this.state.userData ? 'Usuario':  this.state.userData.nick } tú sesión ha expirado</span>
                </div>
                <div className="flex-row pickwin-panel">
                  <div className='flex-col col-80 col-offset-10'>
                    <div className="flex-container justify align column text-center" style={ { padding:'10px 0' } } >
                      <i className="ion-load-a loading " style={ { fontSize:'3em' } }/>
                      <strong> Te estamos redireccionando... </strong>
                    </div>
                  </div>
                </div>
              </div>
              </CSSTransitionGroup>
        );
        break;
      }
    }

    return (
      <Modal className='confirmationPanelPopUp ' bsSize="sm" dialogClassName="confirmationPanelPopUp" backdropClassName='confirmationPanelBackdrop' show={this.state.showPanel} keyboard={false}>
          {conditionalComponent}
      </Modal>
    )
  }
}

export default LoggingOutPanel;
