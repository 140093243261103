import 'url-search-params-polyfill';
import ReactDOM from 'react-dom';
import React from 'react';
import './assets/stylesheets/affiliates.scss';

import {Route, Router, } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import utils from './utils/utils';
import API from './utils/api';
import branchUtils from './utils/branchUtils';
import Login from "./components/Login";

import * as Sentry from '@sentry/browser';
import App from './components/App';

const history = require('history').createBrowserHistory();

try{
  branchUtils.initBranch();
  if( utils.isLogged() ){
    branchUtils.initBranchUser(  );
  }
}
catch(err){
  console.error('Adblocker blocking branch');
}

axios.defaults.headers.common['PW-Platform'] = 'web';
axios.defaults.headers.common['PW-Version'] = '2.0';
let networkAvailable ;

API.fetchIpValidation()
  .then(response => {
    networkAvailable = true;
    // console.log('IP VAlidation Status',response);
    ipIsValid = response.data.status;
    if (!ipIsValid) {
      window.location.assign('https://na.pickwin.net');
    }
  })
  .catch(error => {
    networkAvailable = false;
    Sentry.captureException( new Error( 'error', 'Network isnt available') );
  }
);


if (process.env.NODE_ENV === 'production') {
  utils.startRaven('production');
}
else {
  utils.startRaven('debug');
}

const _jwtoken = utils.getToken();
if (!utils.isLogged()) {
  let url = window.location.href;
  url = url.split("/");
  if (url[3] !== "password_perdido") {
    history.push("/ingresar");
  }
} else {
  console.log("HISTORY", history.location.pathname);
  if (history.location.pathname === "/") {
    history.push("/reportes/principal");
  }
}

if (_jwtoken) {
  let decode = jwt_decode(_jwtoken);
  /**
   * If has less than 5 minnutes to expiration time
   * logout the user
   */
  if (Date.now() > decode.exp * 1000 - 300000) {
    console.log('expired');
    utils.logout();
  } else {
    axios.defaults.headers.common['Authorization'] = `Bearer ${ _jwtoken }`;
  }
} else {
  // Sentry.captureException(new Error('Token didnt exist'));
  // utils.logout();
}

ReactDOM.render(
  <App history={history} />,
  // <Router history={ history}>
  //   <Switch>
	// 	 <Route path='/' exact component={Home}/>
  //    <Route exact path="/legal/terminos" render={() => <Terminos which="terminos" /> } />
  //    <Route exact path="/legal/privacidad" render={() => <Terminos which="privacidad" />} />
  //    <PrivateRoute exact path='/usuario' component={ UserInfo } />
  //    <PrivateRoute path="/usuario/:section/editar/:gameType/:poolId" exact component={ ( props ) => <UserInfo {...props} /> }/>
  //    <PrivateRoute path="/usuario/:section" component={ ( props ) => <UserInfo {...props} /> }/>
  //    <PrivateRoute exact path='/materiales'component={ props => <Materiales {...props}/> }/>
  //    <PrivateRoute path="/materiales/:section" component={ ( props ) => <Materiales {...props} /> }/>
  //    <Route exact path='/ingresar'component={Login}/>
	// 	 <Route exact path='/registro' component={Registro}/>
  //    <Route path="/password_perdido" component={RecuperarPass} />
  //    <Route exact path='/salir' component={Salir}/>
  //    <Route component={NoMatch}/>
	//  </Switch>
  // </Router>,
  document.getElementById('root')
);

if (module && module.hot) {
  module.hot.accept();
}
