//FIX State for conditional component that shows the confirmation button
import React from 'react';
import {
  Modal,
  Button,
  MenuItem,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  Toggle
} from 'react-bootstrap/lib/';

import API from '../../utils/api';

import { LinkContainer } from 'react-router-bootstrap';
import MediaQuery from 'react-responsive';
import { NavLink, Redirect } from 'react-router-dom';
import AvatarDrop from '../AvatarDrop';

import logoBrand from 'images/logoMx.png';
import iconLobby from 'images/lobbyIconBlanco-50.png';
import iconQuinielas from 'images/quinielasIconBlanco-50.png';
import abonarSaldo from 'images/abonarIconBlanco-50.png';
import billete from 'images/cash1-50.png';
import quinielaPriv from 'images/privadaIconBlanco-50.png';
import iconInvitar from 'images/invitarIconBlanco-50.png';
import retirarIconBlanco from 'images/retirarIconBlanco.png'
import historialIcon from 'images/historialIconBlanco-50.png'
import chromeIcon from 'images/chrome.png'
import firefoxIcon from 'images/mozilla.png'
import safariIcon from 'images/safari.png'

// import RegistroFormPanel from '../RegistroFormPanel';

import RegistrarPopUp from '../RegistrarPopUp';
import LoginPopUp from '../LoginPopUp';

// import AbonarSaldo from '../AbonarSaldo';
// import AvatarDrop from '../AvatarDrop';
// import PanelQuinielasPrivadas from '../PanelQuinielasPrivadas';
import utils from '../../utils/utils';
// import NotificationCenterPopover from "../NotificationCenterPopover/NotificationCenterPopover";

//import iconRegistro from 'images/iconsREGISTRADOflechas.png';
//import iconLogin from 'images/iconsREGISTRADOflechas.png';


const ShareLinkButton = ( { handleOpenInvite, label} ) => {
  return (
    <div className='flex-col'>
      <Button className='btn btn-pick callToAction'  onClick={ handleOpenInvite }>
        <i className='ion-ios-personadd' style={ { marginRight:'3px', fontSize:'1.5em' } } />
        Invita a tus amigos
      </Button>
    </div>
)
}

const NotSupportedBrowser = ( { isSupported, closeNote , showNote} ) => {
  if( isSupported ){ return null }
  return (
    <div id='not-supported-message' className={` ${ showNote ? '' : 'not-show' } flex-row row-center`}>
      <div className='big-icon alert'>
        <i className='ion-alert-circled'/>
      </div>
      <strong className=''>
        Te recomendamos que actualices la versión de tu navegador, sólo asi podrás tener la mejor experiencia jugando en Pickwin
      </strong>
      <div className='flex-col '>
        <div className='flex-row'>
          <a href='https://www.google.com/chrome/' target='blank'>
            <img className='browse-badge' src={chromeIcon} />
          </a>
          <a href='https://support.apple.com/downloads/safari' target='blank'>
            <img className='browse-badge' src={safariIcon} />
          </a>
          <a href='https://www.mozilla.org/en-US/firefox/new/' target='blank'>
          <img className='browse-badge' src={firefoxIcon} />
        </a>
        </div>
      </div>

      <button className='btn-close-panel' onClick={ () => { closeNote() } } ><i className='ion-ios-close-outline'/></button>
    </div>
  )
}


/**
 *This function return the Type of bar dependig
 * if the user is logged and the screen size of the device
 */
class DrawNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegistrationPopUp: false,
      showLoginPopUp: false,
    };

    this.updatePanelSate = this.updatePanelSate.bind(this);

    this.showAbonarPop = this.showAbonarPop.bind(this)

    this.updateQuinasP = this.updateQuinasP.bind(this);

    this.showRegisterPopup = () => {
      this.setState({ showRegistrationPopUp: true });
    };
    this.hideRegisterPopup = () => {
      this.setState({ showRegistrationPopUp: false });
    };
    this.showLoginPopup = () => {
      this.setState({ showLoginPopUp: true });
    };
    this.hideLoginPopup = () => {
      this.setState({ showLoginPopUp: false });
    };
  }

  /**
   * this function hides hthe QuinielasPrivadas PAnel
   * */
  updateQuinasP(e) {
    // console.log('UpdatingQP');
    this.setState({
      showQuinielasPrivadas: false
    });
  }

  /**
   * This Function makes visible the Abonar Saldo Panel
   * */
  showAbonarPop() {
    console.log('ShowAbonar');
    this.setState(prevState => {
      return {
        showAbonarSaldo: !prevState.showAbonarSaldo
      };
    });
  }

  /**
   * This Function hides the Abonar Saldo Panel
   * */
  updatePanelSate() {
    console.log('Updating Abonar Pop');
    this.setState({
      showAbonarSaldo: false
    });
  }

  render() {
    let conditionalNavbar = null;
    if (this.props.logged) {
      conditionalNavbar = (
        <div className="nav-container">
          <MediaQuery query="(max-width: 767px)">
            <UsrNavBarMobile
              logout={this.props.logout}
              showAbonarPop={this.showAbonarPop}
              showAbonarSaldo={this.state.showAbonarSaldo}
              showQuinasP={this.showQuinasP}
              showQuinielasPrivadas={this.state.showQuinielasPrivadas}
              updatePanelSate={this.updatePanelSate}
              updateQuinasP={this.updateQuinasP}
              updateUserInfo={this.props.updateUserInfo}
              userData={this.props.userData}
              userBalance={ this.props.userBalance }
            />
          </MediaQuery>
          <MediaQuery query="(min-width: 768px)">
            <UsrNavBar
              showOpenInvite={ this.props.showOpenInvite }
              logout={ this.props.logout }
              updatePanelSate={ this.updatePanelSate }
              updateQuinasP={ this.updateQuinasP }
              updateUserInfo={ this.props.updateUserInfo }
              userData={ this.props.userData }
              userBalance={ this.props.userBalance }
            />
          </MediaQuery>
        </div>
      );
    }
    else {
      conditionalNavbar = (
        <div className="nav-container">
          <RegistrarPopUp
            showRegisterPopup={this.state.showRegistrationPopUp}
            hideRegister={this.hideRegisterPopup}
            updateUserInfo={this.props.updateUserInfo}
            userData={this.props.userData}
          />
          <LoginPopUp
            showRegisterPopup={this.showRegisterPopup}
            showLoginPopup={this.state.showLoginPopUp}
            hideLogin={this.hideLoginPopup}
            updateUserInfo={this.props.updateUserInfo}
            userData={this.props.userData}
          />
          <GuestNavBar
            showRegisterPopup={this.showRegisterPopup}
            showLoginPopup={this.showLoginPopup}
          />
        </div>
      );
    }
    return conditionalNavbar
  }
}

/**
 *This function return the UserBar for Desktop
 */
function UsrNavBar( props ) {
  return (
    <Navbar className="navbar-custom" fixedTop inverse fluid collapseOnSelect>
      <Navbar.Header>
        <Navbar.Toggle />
        <Navbar.Brand>
          <NavLink exact to="/" style={{padding: '0px', margin: '0px', marginTop: '10px'}}>
            <img src={logoBrand} className="pickwin-logo" width="100" height="auto" />
          </NavLink>
        </Navbar.Brand>
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav className="pickwin-nav-right" pullRight>
          <NavItem className="nav-item-avatar">
            <AvatarDrop userData={props.userData} userBalance={ props.userBalance } logout={props.logout} />
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

/**
 *This function return the UserBar for Mobile
 */
function UsrNavBarMobile(props) {
  return (
    <Navbar className="navbar-custom" fixedTop inverse fluid collapseOnSelect>
      <Navbar.Header>
        <div className='flex-row row-center'>
          <Navbar.Brand >
            <NavLink exact to="/" style={{padding: '0px', margin: '0px', marginTop: '15px', marginLeft: '1rem'}}>
              <img src={logoBrand} className="pickwin-logo " width="100" height="auto" />
            </NavLink>
          </Navbar.Brand>
          <div className='flex-col col-no-padding'>
            <AvatarDrop userData={props.userData} logout={props.logout} />
          </div>
          <Navbar.Toggle />
        </div>
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav className="pickwin-nav-categories">
          <LinkContainer to="/reportes/principal">
            <NavItem>
              <i className="ion-android-home"></i> Inicio
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/reportes/usuarios">
            <NavItem>
              <i className="ion-pie-graph"></i> Reportes
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/pagos">
            <NavItem>
              <i className="ion-card"></i> Pagos
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/enlaces">
            <NavItem>
              <i className="ion-link"></i> Enlaces
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/materiales">
            <NavItem>
              <i className="ion-android-image"></i> Materiales
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/detalles-cuenta">
            <NavItem>
              <i className="ion-android-person"></i> Cuenta
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/informacion">
            <NavItem>
              <i className="ion-ios-information"></i> Información
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/support">
            <NavItem>
              <i className="ion-ios-telephone"></i> Soporte
            </NavItem>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

/**
 *This function return the GuestBar
 */
const GuestNavBar = ({ showRegisterPopup, showLoginPopup }) => {
  return (
    <Navbar inverse defaultExpanded fluid collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <NavLink activeClassName="active" to="/" style={{padding: '0.5rem 0 0 0.5rem'}}>
            <img src={logoBrand} className="pickwin-logo-not-logged" width="100" height="auto" />
          </NavLink>
        </Navbar.Brand>
      </Navbar.Header>
      {/* <Nav className="pickwin-nav-right" pullRight>
        <NavItem className="nav-item" onClick={showRegisterPopup} eventKey={6}>
          <img src={iconInvitar} width="10" height="auto" className="mr-1" />
          <span className="textNavbar">REGÍSTRATE</span>
        </NavItem>
        <NavItem className="nav-item" onClick={showLoginPopup} eventKey={6}>
          <span className="textNavbar">
            <i className="ion-log-in" /> INGRESAR
          </span>
        </NavItem>
      </Nav> */}
    </Navbar>
  );
};

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectLoggedOut: false,
      logged: utils.isLogged(),
      mounted: false,
      usrData: utils.getUserInfo(),
      showNote: true,
      showLoggingOut: false,

    };
    // console.log('Navbar',this)
    this.closeNote = this.closeNote.bind(this);
    this.logout = this.logout.bind(this);

    this.showLoggingOut = () => {
      this.setState({ showLoggingOut: true  });
    }

  }

  closeNote(){
    this.setState({ showNote: false, })
  }

  /**
   * This funciton deletes the data from the local storage
   * and logout the user
   */
  logout() {
    this.showLoggingOut();
    setTimeout( () => {
      API.postLogoutData()
    }, 800);
  }

  /**********************+
   *LifeCylce Methods
   **************************/
  componentDidMount() {
    this.setState({
      logged: this.props.isLogged,
      mounted: true,
      isSupported: utils.supportEs6(),
      userData: this.props.usrData,
    });
  }


  render() {
    let conditionalComponent;
    if (this.state.redirectLoggedOut) {
      // console.log('rediregtin after logout');
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: '/'
          }}
        />
      );
    } else {
      if (this.state.mounted) {
        conditionalComponent = (
          <React.Fragment>
            <Modal
              hide={ null }
              show={this.state.showLoggingOut}
              >
              <div className='pickwin-panel flex-container column justify align '>
                <i className='ion-load-a loading '/>
                <span>Regresa pronto a Pickwin ...</span>

              </div>
            </Modal>
            <DrawNavBar
              showOpenInvite={ this.props.showOpenInvite }
              userData={this.props.userData}
              userBalance={this.props.userBalance}
              logged={this.props.isLogged}
              logout={this.logout}
              updateUserInfo={this.props.updateUserInfo}
            />
            <NotSupportedBrowser
              isSupported={this.state.isSupported}
              closeNote={this.closeNote}
              showNote={ this.state.showNote }/>
          </React.Fragment>
        );
      } else {
        conditionalComponent = (
          <div className="flex-container justify align ">
            <i className="ion-load-a loading" />
          </div>
        );
      }
    }
    return conditionalComponent;
  }
}

export default NavigationBar;
