const localStorage = window.localStorage;
import moment from 'moment';
import firstBy from 'thenby';
import React from 'react';
import jwt_decode from 'jwt-decode';
import Fingerprint2 from 'fingerprintjs2';
import Cookies from 'js-cookie';
import numeral from 'numeral';

import * as Sentry from '@sentry/browser';

const uagent = navigator.userAgent.toLowerCase();

let utils = {
  convertThousndsToK: function ( number ) {
    if( number >= 1000 ){
      number  = number / 1000;
      if( Number( number ) === number && number % 1 !== 0 ){
        return numeral(number).format( '$0.0' )+'K'
      }
      else {
        return numeral(number).format( '$0' )+'K'
      }
    }
    else {
      return numeral(number).format('$0,000')
    }
  },
  supportEs6: function () {
    // console.log( '-----SupportES6-----' );
    if (   Array.prototype.hasOwnProperty( 'find' )
        && Array.prototype.hasOwnProperty( 'filter' )
        && Object.hasOwnProperty( 'values' )
        && Object.values.name == 'values'
        && Object.hasOwnProperty( 'assign' )
        && Object.assign.name == 'assign' ){
          Sentry.configureScope( ( scope ) => {
            scope.setTag("isSupported", true);
          });
      return true
    }
    else {
      console.error( 'Your browser is old maybe an update will be nice.' )
      localStorage.setItem( 'notSupported', 'true' );
      Sentry.configureScope( ( scope ) => {
        scope.setTag("isSupported", false );
      });
      return false
    }
  } ,
  /**
   * This method initialize the Raven Configuration
   */
  startRaven: function ( enviroment ) {
    let ravenOptions = {
        dsn: 'https://25de692b101449b89c8a808cb7a2933f@sentry.io/1377332',
        ignoreUrls:[
          /api2\.branch\.com\//i,
          /api\.branch\.com\//i,
        ],
        enviroment: enviroment,
        debug: enviroment == 'debug',
        release: '1.0' + '1639178720',
      }
      // Initialization for new version
      // Sentry 4.2

    Sentry.init( ravenOptions );
  },
  captureMessage( message ){
    console.log(message);
    Sentry.captureMessage(message);
  },
  setSentryTag( tag , value){
    console.log('setTag',tag, value)
    Sentry.setTag( tag, value  );
  },
  /**
   *This method starts the contet in the raven instance
   *
   * @param { Object } userData
   *
   */
  setUserContextRaven( userData ) {
    console.log( 'Setting user ContextRaven' );
    const user = this.getUserInfo();

    console.log( 'this user ', user);
    Sentry.configureScope( ( scope ) => {
      scope.setUser( {
        username: user.nick,
        email: user.email,
        id: user.id,
      } );
    } );

  },
  catchErrorRaven: function ( error, userData, extraInfo, extra ) {
    // console.log('CatchError');
    if ( !userData || !userData ) {
      return;
    }
    Sentry.captureException( error, {
      extra: extra
    } );
    // Sentry.captureMessage('Error in Component:' + extraInfo);
  },
  postMessageRaven: function ( messageInfo ) {
    Sentry.captureMessage( 'Error Message:' + messageInfo );
  },
  removeDownloadAppView: function () {
    localStorage.removeItem( 'viewDownloadApp' );
  },
  detectDevice: () => {
    let device = null;
    if ( uagent.search( 'iphone' ) > -1 ) {
      device = 'apple';
    } else if ( uagent.search( 'ipad' ) > -1 ) {
      device = 'apple';
    } else if ( uagent.search( 'ipod' ) > -1 ) {
      device = 'apple';
    } else if ( uagent.search( 'ndroid' ) > -1 ) {
      device = 'android';
    }
    return device;
  },
  isVerified: function ( userData ) {
    if ( userData.identity_verified ) {
      return true
    } else {
      return false
    }
  },
   /**
   * This method checks if the jwt has been expired or has 5 minutes of activespan
   * @param { String } token
   * return { Boolean }
   */
  isTokenExpired: function ( token ) {
    // console.log('%c Checking Token expiration...', 'color: #FEC830 ; font-size:2em;')
    // console.log( token );
    if ( token ) {
      const decode = jwt_decode( token );
      // console.log( 'Jwtoken data', decode.exp );
      if ( Date.now() > decode.exp * 1000 - 300000 ) {
        return true
      } else {
        return false
      }
    } else {
      // console.error('Token Didnt exist');
      return false;
    }
  },
  /**
   * This method check if the received responses arent completed or has an error in it
   * @param { Array } responses
   * return { Array }
   */
  checkAxiosAllErrors: function ( responses ){
    // console.log('%c Check Axios Failed Responses','color:#2e8ccf; font-size:2em;');
    // console.log( responses );
    if( !responses || responses.length == 0 ){
      // console.error( 'responses Empty' );
      throw 'Responses are empty'
    }
    else {
      // let = [];
      let failedResponses = responses.filter( response => {
        if( !response ){
          // console.error( 'response dont exist');
          return [null]
        }
        else if( !response.hasOwnProperty('data') || response.status != 200 || response.data.error ){
          // console.log('Response Active but failed');
          // axiosErrors.push( response.config.url )
          return response;
        }
      });

      // console.log( failedResponses.length, failedResponses[0]  );
      if (failedResponses.length > 0 ){
        // console.log('%c A Response failed', 'color:#981713; font-size:2em;')
        // console.log(failedResponses);
        // console.log(failedResponses[0]);

        let url = failedResponses[0] == null || !failedResponses[0].hasOwnProperty('config') ? 'Didnt Received ' : failedResponses[0].config.url;

        throw `FailedResponse: ${ url } `
      }
      else {
        // console.log('%c All Responses succed', 'color:#78bc28; font-size:2em;')
      }
    }
  },
  /**
   *Delete localstorage data user
   **/
  clearStorage: function () {
    console.log( 'Clear Storage' );
    localStorage.removeItem( 'user' );
    localStorage.removeItem( 'jwtoken' );
    localStorage.removeItem( 'prevPoolId' );
    localStorage.removeItem( 'prevBracketId' );
    localStorage.removeItem( 'mobileBanner' );
  },
  /**
  * EnableBanner Moobile
  **/
  setUserBalance: function ( userData ) {
    // console.log('Set USer Balance');
    // console.log( userData.tickets );
    if ( !userData ) {
      return null
    }
    const balanceObj = {
      "balancePc"     : userData.balancePc,
      "balancePcReal" : userData.balancePcReal,
      "balanceReal"   : userData.balanceReal,
      "balanceReferral": userData.balanceReferral,
      "tickets"        : userData.tickets,
    }
    localStorage.setItem( 'balance', JSON.stringify( balanceObj ) );
    // console.log( "newBalance", balanceObj);
    return balanceObj
  },
 setUserInfo: function ( userData ) {

    // console.log( 'setUserInfo', userData);

    if ( !userData ) {
      console.error('Not correct form of user');
      return
    }
    Sentry.configureScope( ( scope ) => {
      const user = userData.hasOwnProperty('user') ? userData : userData ;
      scope.setUser( {
        username: user.nick ,
        email: user.email,
        id: user.id,
      } );
    } );
    /**
     * Update value if the user is super user
     * **/

    const balanceObj = {
      "balancePc"     : userData.balancePc,
      "balancePcReal" : userData.balancePcReal,
      "balanceReal"   : userData.balanceReal,
      "balanceReferral":userData.balanceReferral,
      "tickets"           : !userData.tickets ? [] : userData.tickets,
    }


    localStorage.setItem( 'user', JSON.stringify( userData ) );
    localStorage.setItem( 'balance', JSON.stringify( balanceObj ) );
  },
  getUserMetadataRegister: function ( search ) {
    // console.log('%c ------------Get User Metadata------------', 'color:#2e8ccf; font-size:2em;');
    // console.log( search.length );
    // console.log( !search );
    // console.log(typeof(search));

    if ( !search || search == '' || !search.length ) {
      // console.error('Search Empty');
      return;
    } else {
      let params = null;
      let userMetadata = {};
      params = new URLSearchParams( search );
      // console.log( 'params' );
      // console.log( params );

      if ( params.has( 'utm_source' ) ) {
        /**
         * Store Utm source value
         */
        userMetadata[ 'utm_source' ] = params.get( 'utm_source' );
      }
      if ( params.has( 'utm_campaign' ) ) {
        /**
         * Store Utm campaign value
         */
        userMetadata[ 'utm_campaign' ] = params.get( 'utm_campaign' );
      }
      if ( params.has( 'utm_medium' ) ) {
        /**
         * Store Utm medium value
         */
        userMetadata[ 'utm_medium' ] = params.get( 'utm_medium' );

      }
      if ( params.has( 'utm_term' ) ) {
        /**
         * Store Utm term value
         */
        userMetadata[ 'utm_term' ] = params.get( 'utm_term' );
      }
      if ( params.has( 'utm_content' ) ) {
        /**
         * Store Utm content value
         */
        userMetadata[ 'utm_content' ] = params.get( 'utm_content' );
      }
      if ( params.has( 'referer' ) ) {
        /**
         * Store referer value
         */
        userMetadata[ 'referer' ] = params.get( 'referer' );
      }

      console.log( 'userMetadata', userMetadata );

      console.log( 'userMetadata', JSON.stringify( userMetadata ) );

      let stringedMetadata = JSON.stringify( userMetadata );

      localStorage.setItem( 'userMetadata', stringedMetadata );


    }
  },
  /**
   * Sets the users metadata
   * @param {object} metadata
   */
  setUserMetadata: function ( metadata ) {
    console.log( 'METADATA' );
    if ( !metadata || metadata.length == 0 || _.isEmpty( metadata ) ) {
      return
    }
    console.log( metadata );
    localStorage.setItem( 'userMetadata', JSON.stringify( metadata ) );
  },
  clearUserMetadata: function () {
    console.log( 'Clear USerMEtadata' );
    localStorage.removeItem( 'userMetadata' );
  },
  /**
   * This method initialize the fingerprint of the user and set it to localStorage
   */
  initFingerprint: function () {
    console.log( '%c initFingerprint', 'font-size:2em;' )
    let fingerprintExist = !localStorage.getItem( 'fingerprint' ) ? false : true;
    // console.log('fingerprint Exist', fingerprintExist);
    // console.log( fingerprintExist );

    if ( fingerprintExist ) {
      return localStorage.getItem( 'fingerprint' )
    }

    let fingerPrint = '';

    Fingerprint2.getPromise( {} )
      .then( ( components ) => {
        // console.log( components );
        let values = components.map( ( component ) => {
          return component.value
        } );

        let murmur = Fingerprint2.x64hash128( values.join( '' ), 31 );

        fingerPrint = murmur;

        let cookieFingerprint = Cookies.get( 'fingerprint' );
        console.log( 'CoookieFP', cookieFingerprint, fingerPrint );
        if ( cookieFingerprint == undefined ) {
          Cookies.set( 'fingerprint', fingerPrint );
          localStorage.setItem( 'fingerprint', fingerPrint );
          return fingerPrint
        } else {
          localStorage.setItem( 'fingerprint', fingerPrint );
          return fingerPrint
        }
      } ).catch( e => {
        console.error( e );
        Sentry.captureException( e );
      } );

  },
  /**
   * Set & get the Fingerprint for the user
   **/
  setFingerprint: function ( fingerprint ) {
    localStorage.setItem( 'fingerprint', fingerprint );
  },
  getFingerprint: function () {
    return localStorage.getItem( 'fingerprint' );
  },
  /**
   * Check if user is logged
   **/
  isLogged: function () {
    //TODO: check for user.active == 1
    let user = null;
    try {
      user = JSON.parse( localStorage.getItem( 'user' ) );
    } catch ( error ) {
      this.clearStorage();
      Sentry.captureException( error, {
        extra: 'Error getting UserInfo from storage'
      } )
      return false
    }
    return user !== null;
  },
  getUserBalance: function () {
    let balance = null;
    // console.log( 'getUserBalance' );
    try {
      // console.log(JSON.parse(localStorage.getItem('balance') ));
      balance = JSON.parse( localStorage.getItem( 'balance' ) );
    } catch ( error ) {
      console.error('Error getting balance from local storage', error);
      // this.clearStorage();
      Sentry.captureException( error, {
        extra: 'Error getting UserBalance from storage'
      } )
      return null
    }
    // console.log( balance );

    return balance;
  },
  /**
   * Get user info of the user
   **/
  getUserInfo: function () {
    // console.log('getUserInfo');
    // console.log(localStorage.getItem("user"));
    let user = null;
    try {
      user = JSON.parse( localStorage.getItem( 'user' ) );
    } catch ( error ) {
      console.error(error);
      this.clearStorage();
      Sentry.captureException( error, {
        extra: 'Error getting UserInfo from storage'
      } )
      return null
    }

    if( user && user.hasOwnProperty('user') ){
      // console.log('Old Storage', user.hasOwnProperty('user'), user.user );
      localStorage.setItem( 'user', JSON.stringify(user.user) );
      const balanceObj = {
        balanceReal       : user.balanceReal,
        balancePcReal     : user.balancePcReal,
        balancePc         : user.balancePc,
        balanceReferral   : user.balanceReferral,
        tickets           : !user.tickets ? [] : user.tickets,
      }
      localStorage.setItem( 'balance', JSON.stringify( balanceObj ) );
      return user.user;
    }

    return user;
  },
  /**
   * Delete user local strage to logout
   **/
  logout: function () {
    console.log('logout');
    localStorage.removeItem( 'user' );
    localStorage.removeItem( 'jwtoken' );
    localStorage.removeItem( 'referer' );
    localStorage.removeItem( 'prevPoolId' );
    localStorage.removeItem( 'misQuinielasItems' );
    localStorage.removeItem( 'prevBracketId' );
    localStorage.removeItem( 'mobileBanner' );
    localStorage.removeItem( 'redirectTo' );
    window.location = '/';
  },
  /**
   * Set token to check User ID
   **/
  setToken: function ( token ) {
    localStorage.setItem( 'jwtoken', token );
  },
  /**
   * Get token to handle User ID
   **/
  getToken: function () {
    // console.log(localStorage.getItem("jwtoken"));
    return localStorage.getItem( 'jwtoken' );
  },
  /**
   * This method checks if is the first time that the user visit the site
   * @return boolean
   */
  isFirstTimeUser: () => {
    // console.log( '%c isFirstUser', 'font-size:2em; color:rgba(53, 122, 240, 1) ;' )
    // console.log(localStorage.getItem('isFirstTimeUser') );

    let isFirstTimeUser = localStorage.getItem( 'isFirstTimeUser' ) ===
      undefined || localStorage.getItem( 'isFirstTimeUser' ) === null ||
      localStorage.getItem( 'isFirstTimeUser' ) == 'true' ? true : false;
    // console.log('----isFirstTiner-----');
    //
    // console.log( isFirstTimeUser );
    // // console.log( localStorage.getItem('isFirstTimeUser') );

    if ( isFirstTimeUser && $( window ).width() < 769 ) {
      localStorage.setItem( 'isFirstTimeUser', true );
      return true
    } else {
      // localStorage.setItem('isFirstTimeUser', false);
      return false;
    }
  },
  /**
   * This method deletes the flag of the first time user in local Storage
   */
  turnOffFirstTimeUser: () => {
    localStorage.setItem( 'isFirstTimeUser', false );
  },
};

export default utils;
