import axios from 'axios';
import io from 'socket.io-client';
import 'jquery';
import utils from './utils';
// const buildProduction = process.env.NODE_ENV == 'production';
// const buildCompile = process.env.NODE_ENV == 'compileTest';

// const APIurl = "https://api-sales.pickwin.net";
// const APIurl = 'https://api.pickwin.mx';
// const APIurl = 'https://staging.pickwin.net';
// const APIurl = 'http://10.10.0.109';


const buildProduction   = process.env.NODE_ENV == 'production';
const devMode           = process.env.NODE_ENV == 'development';

let APIurl            = process.env.PW_API_URL_TEST;

console.log('isProd:', buildProduction );
console.log( 'isDev:', devMode );

if( buildProduction ){
  APIurl              = process.env.PW_API_URL_PROD;
}

// import mockResourcesSales from '../tests/__mockData__/resources/mockResourcesSales.json';

const api =  {
  fetchSurvivorDetail: (survivorId) => {
    console.log(survivorId);

    const url = `${APIurl}/api/survivors/view/${survivorId}.json`;
    return axios.get(url)
  },
  postDeleteRegisterSurvivorPrivate: async (which) => {
    let url = `${APIurl}/api/survivor_entries/delete/${which}.json`;
    let result = await axios.post(url);
    return result
  },
  fetchSurvivorStandings: (which, queryObject) =>  {
    let url = null;
    console.log(which);
    // => "forms%5Bperfect%5D=circle&forms%5Bimperfect%5D=square"

    if (queryObject === undefined || queryObject === null) {
      url = `${APIurl}/api/survivor_standings/get/${which}.json?limit=100&page=1`;
    } else {
      const query = $.param(queryObject);
      url = `${APIurl}/api/survivor_standings/get/${which}.json?${query}`;
    }
    return axios.get(url)
  },
  fetchPoolDetail:  ( poolId ) => {
    console.log('getting Data...', poolId);
    let url = `${APIurl}/api/pools/view/${poolId}.json`;
    return axios.get(url)
  },
  fetchPoolPrizes: (which) =>  {
    // console.log('getting Data...')
    let url = `${APIurl}/pools/prize_distribution/${which}.json`;
    return axios.get(url)
  },
  fetchStandings: (which, queryObject) =>  {
    let url = null;
    // console.log($.param( queryObject ));
    // => "forms%5Bperfect%5D=circle&forms%5Bimperfect%5D=square"

    if (queryObject === undefined || queryObject === null) {
      url = `${APIurl}/api/standings/get/${which}.json?limit=100&page=1`;
    } else {
      const query = $.param(queryObject);
      url = `${APIurl}/api/standings/get/${which}.json?${query}`;
    }
    return axios.get(url)
  },
  fetchMaterials: function fetchMaterials(){
    const url = `${APIurl}/sales/sales_materials/index.json`;
    return axios( url )
    // return new Promise( ( resolve ,reject ) => {
    //   if( !mockResourcesSales ){
    //     reject( { "success": false , "error": "No Data Found" } )
    //   }
    //   else {
    //     resolve( mockResourcesSales );
    //   }
    // } )
  },
  fetchUserDocuments: function fetchUserDocuments() {
    // console.log('getting Data...')
    let url = `${APIurl}/api/documents/index.json`;
    return axios.get(url)
  },
  fetchIpValidation: function fetchIpValidation( ) {
    const url = `${APIurl}/api/users/validate.json`;
    return axios.get(url)
  },
  fetchAvailableCashout: function fetchAvailableCashout( ) {
    const url = `${APIurl}/api/cashouts/available.json`;
    return axios.post(url)
  },
  fetchCashoutBanks: function fetchCashoutBanks( ){
    let url = `${APIurl}/api/cashouts/banks.json`;
   return axios.post(url)
  },
  fetchTransactions : function fetchTransactions( query ){
    let url ;
    let queryObject = null;
    if( !query  ) {
      url= `${ APIurl }/api/transactions/history.json?page=1`
    }
    else {
      queryObject = $.param(query);
      url= `${ APIurl }/api/transactions/history.json?${queryObject}`;
    }
    return axios.get( url, query )
  },
  fetchCashoutBanks: function fetchCashoutBanks( ){
    let url = `${APIurl}/api/cashouts/banks.json`;
   return axios.post(url)
  },
  fetchPools : function fetchPools() {
   let url = `${APIurl}/pools/available.json`;
   if ( utils.isLogged() ) {
     url = `${APIurl}/api/pools/available.json`;
   }
   return axios.get(url)
  },
  fetchUserData: function fetchUserData(  ){
    const url = `${APIurl}/api/users/details.json`;
    return axios.get(url)
  },
	postLoginData:function postLoginData( data ){
		let url = `${ APIurl  }/sales/users/login.json?`;
    return axios.post( url, data )
      .then(response => {
        var data = response.data;
        var user = data.user;
        var jwtoken = data.jwtoken;

        axios.defaults.headers.common['Authorization'] = `Bearer ${jwtoken}`;

        utils.setUserInfo(data);
        utils.setToken(jwtoken);

        return response;
      })
      .catch(error => {
        console.log('Error Login');
        console.error(error);
        return error
       });
	},
  postCashout : function postCashout(form_data, config){
    let url = `${APIurl}/api/cashouts/request.json`;
    return axios.post(url, form_data, config);
  },
  fetchSports: (gameType) => {
    if(!gameType){
      let url = `${ APIurl }/api/sports/forprivate.json`;
      return axios.get( url )
    }
    else {
    let url = `${ APIurl }/api/sports/forprivate/${ gameType }.json`;
    return axios.get( url )
    }
  },
  fetchTeams: (which) =>  {
    let url = `${APIurl}/api/teams/from_tournament/${which}.json`;
    return axios.get(url)
  },
  fetchTournamentInstancesWeek: (which) =>  {
    let url = `${APIurl}/api/tournament_instances/next_weeks/${which}.json`;
    return axios.get(url)
  },
  fetchTournamentInstancesWeekSurvivor: ( which ) =>  {
    let url = `${APIurl}/api/tournament_instances/survivor_weeks/${which}.json`;
    return axios.get(url)
  },
  fetchTournamentInstancesFixtures: ( which ) =>  {
    let url = `${APIurl}/api/tournament_instances/next_fixtures/${which}.json`;
    return axios.get(url)
  },
  getMisQuinielas: ( ) =>  {
    let url = `${APIurl}/api/users/pools.json`;
    // console.log('API LOG  Mis quinielas...');
    return axios.get(url)
  },
  fetchSportsIndex: ( ) => {
    let url = `${APIurl}/api/sports/index.json`;
    return axios.get(url)
  },
  /**********************
   * Survivors Private
   **********************/
  postCreatePrivateSurvivor: (data) => {
    let url = `${APIurl}/api/survivors/create_private.json`;
    return axios.post(url, data)
  },
  postDeleteRegisterSurvivorPrivate:  (which) => {
    let url = `${APIurl}/api/survivor_entries/delete/${which}.json`;
    return axios.post(url)
  },
  postEditPrivateSurvivor: (data) => {
    let url = `${APIurl}/api/survivors/edit_private.json`;
    return axios.post(url, data)
  },
  postSearchPrivateSurvivor: (data )=>  {
    let url = `${APIurl}/api/survivors/search_private_logged.json`;
    return axios.post(url, data)
    },

  /**********************
   * Pools Private
   *********************/
  postCreatePrivate: (data) => {
    let url = `${APIurl}/api/pools/create_private.json`;
    return axios.post(url, data)
  },
  postDeleteRegisterPrivate: async (which) => {
    let url = `${APIurl}/api/entries/delete/${which}.json`;
    let result =  await axios.post(url)
    return result
  },
  postEditPrivate: (data) =>  {
    let url = `${APIurl}/api/pools/edit_private.json`;
    return axios.post( url, data )
  },
  fetchSportsIndex: () =>  {
    let url = `${APIurl}/api/sports/index.json`;
    return axios.get(url)
  },
  fetchNamePrivateStatus: (name) => {
    const url = `${APIurl}/api/pools/check_private_name.json`;
    return axios.post(url, { name: name })
  },
  fetchNamePrivateSurvivorStatus: (name) => {
    const url = `${APIurl}/api/survivors/check_private_name.json`;
    return axios.post(url, { name: name })
  },
  postSearchPrivate: (data) => {
    let url = `${APIurl}/api/pools/search_private_logged.json`;
    return axios.post(url, data)
  },
  postLogoutData: function postLogoutData() {
    if(utils.isLogged ){
      utils.logout();
    }
    else  null
     //     branch.logout();
     //     // console.log('API LOG LogOUT...',response);
     //     return response;
     //   })
     //   .catch(error => {console.error(error); return error;} );
     // }
     // else return null
   },
   postChangePassword : function postChangePassword( data ){
     let url = `${APIurl}/api/users/change_password.json`;
     return axios.post(url, data)
   },
   postRecoverPass: function postRecoverPass( email ){
    let url = `${APIurl}/api/users/recover.json`;
    return axios.post( url, { email: email } )
   },
   postAvatar: function postAvatar ( data ) {
     let url = `${APIurl}/api/users/change_avatar.json`;
     return axios.post(url, data)
  },
	postRegister: function postRegister( data ){
	  let url = `${ APIurl  }/sales/users/register.json`;
	  return axios.post( url, data )
	  	.then( response => {
	  		let responseData = response.data;
				if( responseData.hasOwnProperty( 'success' ) && response.data.success ){

					axios.defaults.headers.common['Authorization'] = `Bearer ${ responseData.jwtoken }`;
					utils.setUserInfo( responseData );
					utils.setToken( responseData.jwtoken );
				}
				return response
			}  )
			.catch( error => {
				console.error(error);
				return error;
			} )
	},
	fetchReferrals: function fetchReferrals(){
		let url = `${ APIurl  }/sales/referrals/get.json`;
    return axios.get(url)
  },
  getDashUrl: function() {
    let url = `${APIurl}/sales/affiliates/get_url.json`;
    return axios.get(url);
  },
  requestSupport: function(data) {
    let url = `${APIurl}/sales/affiliates/request_support.json`;
    return axios.post(url, data);
  },
  getAccountDetails: function() {
    return axios.get(`${APIurl}/sales/affiliates/account_details.json`);
  },
  getPayments: function() {
    return axios.get(`${APIurl}/sales/affiliates/payments.json`);
  }
};


export default api;
