import React from 'react';

import utils from '../../utils/utils';
import ReactTooltip from 'react-tooltip';
import rewardIcon from 'images/iconRewardBorder-50.png';

import { Modal, Overlay, Button } from 'react-bootstrap/lib';


import inviteReferrals from 'images/inviteReferrals.jpg';

import bannerInvitaReferral from 'images/banners/banner-referral-invita.jpg';

import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton
} from 'react-share';

import Clipboard from 'clipboard';
import NewReferralMailInvite from '../NewReferralMailInvite';

let clipboardCode, clipboardReferal;

import Clock from '../Clock';


const CallToActionLink = ( {callToActionLink, userInviteObject, poolSelected, timeToRegister } ) => {
  let conditionalComponent = null;
  if( !callToActionLink){
    conditionalComponent = (
      <div className='flex-container column justify align'>
        <div>
          {`Invita a ${!userInviteObject ? 'tu amigo': userInviteObject.nick } todavía tiene `}
        </div>
        <Clock
          type='row'
          changeStatusPool={null}
          poolID={ poolSelected.id }
          faltnte={ timeToRegister }
          closingDate={ poolSelected.deadline}/>
        <div>
          para registrarse.
        </div>
      </div>
    )
  } else{
    conditionalComponent = (
      <React.Fragment>
        <img className='flex-col col-10 invite-icon' src={ rewardIcon } />
        <strong className='text-center flex-col col-80'>  {callToActionLink} </strong>
        <i style={{ fontSize:'20px' }} className=' invite-icon flex-col col-10 ion-arrow-down-a'/>
      </React.Fragment>
    )
  }

  return (
    <div className= {`${!callToActionLink ? ' flex-col ' : ' flex-col col-80' }`}>
      <div className={` ${!callToActionLink ? 'flex-container column justify align ' : 'flex-row row-center' }  `}>
        { conditionalComponent }
      </div>
    </div>
  )

  return ( conditionalComponent );
}

const PanelInvitarAmigos = ({
  isEmbedded,
  title,
  showPanel,
  openInvitePanel,
  closePanel,
  branchLink,
  defaultLink,
  type,
  callToActionLink,
  whatsAppInvitationReferer,
  showMailInvite,
  handlePanelInvite,
  poolSelected,
  timeToRegister,
  userInviteObject,
  isFile,
  hidePanel
}) => {
  let searchThis = `.copiar-btn` ;

  console.log(searchThis);

  clipboardCode = new Clipboard( searchThis);
  clipboardCode.on('success', e => {
    const wich = e.trigger.attributes['data-clipboard-target'].value;
      console.log(wich);
    $(wich).addClass('copied');
    e.clearSelection();
  });


  let url = branchLink ? branchLink : `${defaultLink}${ userData ? userData.referalCode : utils.getUserInfo().referalCode }` ;
  let invitationText = `Te invito a jugar quinielas en ${ url }`;

  if( type === 'challenge'){
      invitationText =  ' ¿Ya viste esta quiniela? Éntrale ¡Tiene buena bolsa! '
  }

  const userData = utils.getUserInfo();

  if( isEmbedded ){
    return (
      <div className="panel-abonar flex-row row-no-padding">
        <div className="flex-col pickwin-panel">
          <div className="detalles-privadas-compartir-container">
            <div className="flex-col col-center">
              <div className="panel-invite-title text-center">
                <img src={inviteReferrals} className="img-responsive" />
              </div>
              <div className='flex-container column justify align'>
              </div>
              </div>
                <div className="flex-row row-center flex-container justify wrap align">
                  <div className="codigo-amigo flex-col">
                    <input
                      id={`copiar-clipboard-${type === 'challenge' ? 'challenge' : 'embedded' }`}
                      value={ url }
                      />
                  </div>
                  <div className="copiar-button-codigo flex-col col-15">
                    <ReactTooltip
                      id="copiar-tool"
                      place="bottom"
                      type="light"
                      effect="solid">
                      Copia tú código y compártelo
                    </ReactTooltip>
                    <button
                      data-tip
                      data-for="copiar-tool"
                      className={`copiar-btn ${ !userInviteObject ? 'user' : userInviteObject.id}`}
                      data-clipboard-target={`#copiar-clipboard-${ type==='challenge'  ? 'challenge' : 'embedded'}`}>
                      <i className="ion-ios-copy " />
                      <span className="visible-md">Copiar</span>
                    </button>
                  </div>
                </div>
            </div>
            <div className="btn-group inviteToPlay flex-row flex-container justify align wrap">
              <FacebookShareButton
                quote={ invitationText }
                hashtag={'#Pickwin #registrate #JalaTusPicks'}
                url={ url }>
                <Button className="fb-btn">
                  Por Facebook <i className="ion-social-facebook" />
              </Button>
            </FacebookShareButton>
            <TwitterShareButton
              title={ invitationText }
              url={ url }
              hasthag={'#Pickwin #registrate #JalaTusPicks'}>
              <Button className="twitter-btn">
                {' '}
                Por Twitter <i className="ion-social-twitter" />
            </Button>
          </TwitterShareButton>
          <div className="SocialMediaShareButton">
            <Button className="mail-btn" onClick={openInvitePanel}>
              Por Mail <i className="ion-email" />
          </Button>
        </div>
            <div className="SocialMediaShareButton">
              <Button
                className="whatsapp-btn"
                onClick={() => {
                  whatsAppInvitationReferer( branchLink ? branchLink : `${ defaultLink }${ userData ? userData.referalCode : utils.getUserInfo().referalCode}`);
                }}>
                Por WhatsApp <i className="ion-social-whatsapp" />
            </Button>
          </div>
        </div>
      </div>
      <NewReferralMailInvite
        showPanel={showMailInvite}
        hidePanel={hidePanel}
        userInviteObject={userInviteObject}
        />
    </div>
    );
  }
  else if( type =='inviteFriends'){
    return (
      <Modal
        backdropClassName="inviteFriendsBackdrop"
        dialogClassName="inviteFriendsBackdrop"
        onHide={handlePanelInvite}
        show={showPanel}>

       <div className="panel-abonar flex-row row-no-padding">
          <div id="panelTitle" className="panel-abonar-titulo">
            <span>
              {title}
              </span>
              <button className="cerrar-panel" onClick={() => { handlePanelInvite( false ) }}>
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty"/>
                </div>
              </button>
            </div>
            <div className="panel-abonar flex-row row-no-padding panel-pickwin">
              <div className="flex-col pickwin-panel">
                <div className="detalles-privadas-compartir-container">
                  <div className="flex-col col-center">
                    <div className="panel-invite-title text-center">
                      <img src={'https://pickwin.net/images/banners/banner-referral-invita.jpg'} className="img-responsive" />
                    </div>
                    <div className='flex-container column justify align'>
                    </div>
                    </div>
                      <div className="flex-row row-center flex-container justify wrap align">
                        <div className="codigo-amigo flex-col">
                          <input
                            id={`copiar-clipboard-${type === 'challenge' ? 'challenge' : 'embedded' }`}
                            value={ url }
                            />
                        </div>
                        <div className="copiar-button-codigo flex-col col-15">
                          <ReactTooltip
                            id="copiar-tool"
                            place="bottom"
                            type="light"
                            effect="solid">
                            Copia tú código y compártelo
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="copiar-tool"
                            className={`copiar-btn ${ !userInviteObject ? 'user' : userInviteObject.id}`}
                            data-clipboard-target={`#copiar-clipboard-${ type==='challenge'  ? 'challenge' : 'embedded'}`}>
                            <i className="ion-ios-copy " />
                            <span className="visible-md">Copiar</span>
                          </button>
                        </div>
                      </div>
                  </div>
                  <div className="btn-group inviteToPlay flex-row flex-container justify align wrap">
                    <FacebookShareButton
                      quote={ invitationText }
                      hashtag={'#Pickwin #registrate #JalaTusPicks'}
                      url={ url }>
                      <Button className="fb-btn">
                        Por Facebook <i className="ion-social-facebook" />
                    </Button>
                  </FacebookShareButton>
                  <TwitterShareButton
                    title={ invitationText }
                    url={ url }
                    hasthag={'#Pickwin #registrate #JalaTusPicks'}>
                    <Button className="twitter-btn">
                      {' '}
                      Por Twitter <i className="ion-social-twitter" />
                  </Button>
                </TwitterShareButton>
                <div className="SocialMediaShareButton">
                  <Button className="mail-btn" onClick={openInvitePanel}>
                    Por Mail <i className="ion-email" />
                </Button>
              </div>
                  <div className="SocialMediaShareButton">
                    <Button
                      className="whatsapp-btn"
                      onClick={() => {
                        whatsAppInvitationReferer( branchLink ? branchLink : `${ defaultLink }${ userData ? userData.referalCode : utils.getUserInfo().referalCode}`);
                      }}>
                      Por WhatsApp <i className="ion-social-whatsapp" />
                  </Button>
                </div>
              </div>
            </div>
            <NewReferralMailInvite
              showPanel={showMailInvite}
              hidePanel={hidePanel}
              userInviteObject={userInviteObject}
              />
          </div>
        </div>
    </Modal>
    );
  }
  else {
    if( !poolSelected && !isFile ){
      return null
    }
    return (
      <Modal
        backdropClassName="inviteFriendsBackdrop"
        dialogClassName="inviteFriendsBackdrop"
        onHide={closePanel}
        show={showPanel}>
          <div className="panel-abonar">
            <div id="panelTitle" style={{padding: '0.5rem'}}>
              <div className="flex-row flex-container align between">
                <h4>{title}</h4>
                <div onClick={closePanel} style={{fontSize: '30px', cursor: 'pointer'}} >
                  <i className="ion-ios-close-empty"/>
                </div>
              </div>
              <div className="flex-row">
                <img src={inviteReferrals} className="img-responsive" />
              </div>
              <div className="flex-row">
                {isFile ? null : <CallToActionLink callToActionLink={ callToActionLink } userInviteObject={ userInviteObject } poolSelected={ poolSelected } timeToRegister={ timeToRegister } /> }
                <div className='flex-row flex-contaienr align between'>
                  <div className="codigo-amigo flex-col">
                    <input type="text" id="copiar-clipboard-embedded" className="form-control" value={url} onChange={() => {}} />
                  </div>
                  <div style={{margin: 'auto 0'}}>
                    <ReactTooltip
                      id="copiar-tool"
                      place="bottom"
                      type="light"
                      effect="solid">
                      Copia tú código y compártelo
                    </ReactTooltip>
                    <button
                      data-tip
                      data-for="copiar-tool"
                      className="copiar-btn"
                      data-clipboard-target="#copiar-clipboard-embedded">
                      <i className="ion-ios-copy " />
                      <span className="visible-md">Copiar</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex-row flex-container align around wrap">
                <FacebookShareButton
                  quote={ invitationText }
                  hashtag={'#Pickwin #registrate #JalaTusPicks'}
                  url={ url }>
                  <Button className="btn btn-primary social-button">
                    Por Facebook <i className="ion-social-facebook" />
                  </Button>
                </FacebookShareButton>
                <TwitterShareButton
                  title={ invitationText }
                  url={ url }
                  hasthag={'#Pickwin #registrate #JalaTusPicks'}>
                  <Button className="btn btn-primary social-button">
                    {' '}
                    Por Twitter <i className="ion-social-twitter" />
                  </Button>
                </TwitterShareButton>
                <div className="SocialMediaShareButton">
                  <Button className="btn btn-primary social-button" onClick={openInvitePanel}>
                    Por Mail <i className="ion-email" />
                  </Button>
                </div>
                <div className="SocialMediaShareButton">
                  <Button className="btn btn-primary social-button"
                    onClick={() => {
                      whatsAppInvitationReferer( branchLink ? branchLink : `${ defaultLink }${ userData ? userData.referalCode : utils.getUserInfo().referalCode }` );
                    }}>
                    Por WhatsApp <i className="ion-social-whatsapp" />
                  </Button>
                </div>
              </div>
              <div className="flex-row text-center mt-1">
                <Button className="btn btn-danger" onClick={closePanel} style={{margin: '0 auto', width: '260px'}}>
                  No, gracias
                </Button>
              </div>
            </div>
          </div>
      </Modal>
    );
  }
};

export default PanelInvitarAmigos;
