import React from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Button, ButtonGroup, FormControl} from 'react-bootstrap/lib/';
import pickwinLogo from 'images/pickwin-winner-100.png';
import mailIcon from 'images/mensajesIconBlanco-50.png'
import passwordIcon from 'images/password.png'
import {
  BrowserRouter as Router,
  History,
  Route,
  NavLink
} from 'react-router-dom';
import API  from '../../utils/api';

class RecuperarPanel extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      userData:null,
      email:undefined,
      success:null,
      token:null,
      response:null,
    }
    console.log(this);
    // this.sendCredentials = this.sendCredentials.bind(this);
    this.handleEmail = this.handleEmail.bind(this);

    this.sendCredentials = (event) => {
      let that = this;

      console.log( this.state.email );
      API.postRecoverPass(this.state.email)
        .then((response) => {
          if(response.data.success){
            this.setState( () => {
              return {
                response: response,
                success:response.data.success,
              };
            });
          }
          else {
            this.setState( () => {
              return {
                response: response,
                success:response.data.success,
              };
            });
          }
          console.log(this.state);
        });
    }
  }

  handleEmail(e){
    this.setState({
      email:e.target.value,
    })
  }

  render(){
    console.log("STATEREC:", this.state);
    let conditionalComponent;

    let loggedIn = false;
    if( this.state.userData === null){
      loggedIn = false
    }else {
      loggedIn = true;
    }

    if( this.state.success === null){
      conditionalComponent = (
        <Form horizontal onSubmit={this.sendCredentials}>
          <div className="flex-col">
            <span style={{'textAlign':'center'}}>Se enviarán instrucciones para cambiar tu contraseña al email con el que te registraste.</span>
          </div>
          <div className="flex-col">
            <FormControl onChange={this.handleEmail} placeholder="Email" />
          </div>
          <div className="flex-col">
            <Button onClick={this.sendCredentials} className='btn-pickwin btn btn-block' block>Recuperar Contraseña</Button>
          </div>
        </Form>
      )
    } else {
      if (this.state.success){
        conditionalComponent = (
          <>
            <div className="flex-col text-center">
              <i className="ion-checkmark-circled" style={{color:'#78BC28', fontSize:'52px', textAlign:'center'}}></i>
              <h4>Ahora revisa tu correo para restablecer tu contraseña</h4>
            </div>
            <div className="flex-col text-center">
              <a href="/ingresar" className="btn btn-pickwin btn-block">
                Regresar
              </a>
            </div>
          </>
        )
      } else {
        conditionalComponent = (
          <Form horizontal >
            <div className="flex-col">
              <span style={{'textAlign':'center'}}>Se enviarán instrucciones para cambiar tu contraseña al email con el que te registraste.</span>
            </div>
            <div className="flex-col">
              <FormControl onChange={this.handleEmail} placeholder="Email" />
              <span className={'error-formulario'}> El email que ingresaste no existe, por favor ingrésalo nuevamente.</span>
            </div>
            <div className="flex-col">
              <Button onClick={this.sendCredentials} className='btn-pickwin btn btn-block' block>Recuperar Contraseña</Button>
            </div>
          </Form>
        )
      }
    }

    return(
      <div id='loginPanel' >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-md-offset-3">
              <h3>Recuperar password</h3>
              {conditionalComponent}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default RecuperarPanel;
