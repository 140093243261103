import React from 'react';
import LoginPanel from '../LoginPanel';
import { Modal } from 'react-bootstrap';

class LoginPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: null,
      mounted: false,
      userId: null,
      userData: null,
      from: { pathname: '/' }
    };
    // this.updateUserData = this.updateUserData.bind(this);
    this.loggedInCorrect = status => {
      this.setState({ loggedIn: status });
    };
  }

  render() {
    return (
      <Modal
        bsSize="lg"
        className="absoluteModal"
        backdropClassName="loginPanelBackdrop "
        dialogClassName="loginPanelDialog "
        onHide={this.props.hideLogin}
        show={this.props.showLoginPopup}>
        <div className="modal-login flex-row">
          <LoginPanel
            type="popup"
            loggedInCorrect={this.loggedInCorrect}
            hideLogin={this.props.hideLogin}
            showRegisterPopup={this.props.showRegisterPopup}
            updateUserInfo={this.props.updateUserInfo}
          />
        </div>
      </Modal>
    );
  }
}

export default LoginPopUp;
