import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import MediaQuenry from 'react-responsive';
import twitterButton from 'images/twitterButton.png';
import facebookButton from 'images/fbButton.png';
import instagramButton from 'images/igButton.png';
import comodoButton from 'images/comodo_secure_seal_76x26_transp.png';
import correoButton from 'images/mailButton.png';
import pickBco from 'images/quinielasIconBlanco-50.png';
import pickwin from 'images/logoMx.png';
import iconLobby from 'images/lobbyIconBlanco-50.png';
import iconQuinielas from 'images/quinielasIconBlanco-50.png';
import iconPremios from 'images/premiosIconBlanco-50.png';
import iconAyuda from 'images/ayudaIconBlanco-50.png';
import segob from 'images/segob.png';

const scriptsComodo = `<script language="JavaScript" src="https://secure.comodo.net/trustlogo/javascript/trustlogo.js" type="text/javascript">
</script>`;
const scriptsComodo01 = `<script language="javascript" type="text/javascript">
TrustLogo("http://pickwin.net/images/comodo_secure_seal_76x26_transp.png", "CL1", "none");
</script>`;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <>

      <div className="footer flex-row row-no-padding responsive-sm row-center">
        <div className="container">
          <div className="footer-upper flex-row row-no-padding responsive-sm row-center">
            <div className="footer-left flex-col">
              <ul className="flex-row row-center legals">
                <li className="legal-item flex-col ">
                  <NavLink className='flex-row row-no-padding' activeClassName="activeNav" to="/legal/terminos">
                    <span className="flex-col textNavbar text-center" style={{color: "#000", fontSize: '10px'}}>Terminos y Condiciones</span>
                  </NavLink>
                </li>
                <li className="legal-item flex-col ">
                  <NavLink className='flex-row row-no-padding' activeClassName="activeNav" to="/legal/privacidad">
                    <span className="flex-col text-center textNavbar" style={{color: "#000", fontSize: '10px'}}>Aviso de Privacidad</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-center flex-col">
              <div className="logos-footer row text-center">
                <span>©</span>
                <img src={pickwin} width="150" height="auto" />
              </div>
            </div>
            <div className="footer-right flex-col mt-2">
              <div className="flex-container around">
                <a target='_blank' href="https://www.facebook.com/pickwin.mx">
                  {/*<i className="ion-social-facebook-outline"></i>*/}
                  <img src={facebookButton} className="img-responsive navbar-icons social" width="30" height="auto"/>
                </a>
                <a target='_blank' href="https://twitter.com/PickwinMX">
                  {/*<i className="ion-social-twitter-outline"></i>*/}
                  <img src={twitterButton} className="img-responsive navbar-icons social" width="30" height="auto" />
                </a>
                <a target='_blank'  href='https://instagram.com/pickwin.mx'>
                  {/* <i className="ion-social-instagram-outline"></i> */}
                  <img src={instagramButton} className="img-responsive navbar-icons social" width="30" height="auto" />
                </a>
                <a href="mailto:hola@pickwin.net">
                  {/*<i className="ion-ios-email-outline"></i>*/}
                  <img src={correoButton} className="img-responsive navbar-icons social" width="30" height="auto"/>
                </a>
              </div>
            </div>
          </div>
          <div className='flex-row row-center responsive-sm row-no-padding row-center'>
            <div className='flex-col col-80'>
              <p className="text-center" style={ {padding: '8px', fontSize: '10px'}} >
                <small> Picwin.mx es operada en México por la permisionaria en materia de juegos y sorteos “Producciones Móviles, S.A de C.V.”, titular del permiso de gobernación DGAJS/SCEVF/P-06/2005-TER, en unión de su operador “CAPALI LA PAZ OPERACIONES, S.A de C.V.”, de conformidad con el oficio DGJS/DCRCA/1584/2019. Los juegos con apuestas están estrictamente prohibidos para menores de edad. El principal propósito es de entretenimiento, diversión y esparcimiento. Juega de manera responsable.</small>
              </p>
            </div>
            <div className='flex-col  col-center text-center'>
              <img src={ segob } className='logo-gob' width="100" height="auto" />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Footer;
