import React from 'react';
import axios from 'axios';
import {
  FormControl,
  FormGroup,
  Button,
  ControlLabel,
  HelpBlock,
  Modal
} from 'react-bootstrap/lib';

import FormMailInvite from './FormMailInvite';

import closeW from 'images/close.png';
import API from '../../utils/api';

class NewReferralMailInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      userToInvite       : null,
      showPanel          : '',
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }


  close() {
    // console.log('Close panel');
    this.setState({
      showPanel: false
    });
  }

  open() {
    console.log('InvitationPanel, open', this.state);
    this.setState({
      showPanel: true
    });
  }

  componentDidMount() {
    // console.log(this.props.pool);
    // this.props.pool != undefined  ?
    //     this.setState({
    //         payType:this.props.payType,
    //         logged: this.props.logged
    //     })
    //     :
    //     null
    // console.log('Panel Mounted', this.state)
  }

  render() {
    let conditionalComponent;
    conditionalComponent = (
      <div>
        <Modal
          backdropClassName="backdropInvitarMail"
          dialogClassName="invitarMailModalDialog"
          className="invitarMailModal"
          show={this.props.showPanel}
          onHide={this.props.hidePanel}>
          <div id="invitar-panel">
            <div id="panelTitle" className="panel-abonar-titulo">
              <span>Invita por email</span>
              <button
                className="cerrar-panel"
                onClick={this.props.hidePanel}>
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty" />
                </div>
              </button>
            </div>
              <div className="firstSection pickwin-panel">
                <FormMailInvite userInviteObject={ this.props.userInviteObject }/>
              </div>
          </div>
        </Modal>
      </div>
    );

    // console.log('Render Panel',this.props);
    return conditionalComponent;
  }
}

export default NewReferralMailInvite;
