import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import MediaQuery from 'react-responsive';
import onClickOutside from 'react-onclickoutside';
import ReactTooltip from 'react-tooltip';
//Iconos e imagenes
import abonar from 'images/abonarIconBlanco-50.png';
import invitados from 'images/invitarIconBlanco-50.png';
import infoPersonal from 'images/quinielasIconBlanco-50.png';
import iconInvitar from 'images/invitarIconBlanco-50.png';
import historial from 'images/historialIconBlanco-50.png';
import retirarIconBlanco from 'images/retirarIconBlanco-50.png';
import tickets from 'images/silver_ticket_pick_empty.png';
import misTarjetas from 'images/tarjetasIconBlanco-50.png';
import salir from 'images/salirIconBlanco-50.png';
import misDocs from 'images/documentosIconBlanco-50.png';
import cash from 'images/cash2-50.png';
import pickcoin from 'images/pickcoin1-50.png';
import avatar from 'images/avatar-50.png';
import quinielaPriv from 'images/privadaIconBlanco-100.png';
import numeral from 'numeral';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import {
  Button,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Modal
} from 'react-bootstrap/lib/';

import utils from '../../utils/utils';


class AvatarDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDropdown: false,
      userData: this.props.userData,
      showQuinielasP: false
    };
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.handleDropdownUserOver = this.handleDropdownUserOver.bind(this);
    this.handleDropdownUserOut = this.handleDropdownUserOut.bind(this);
    this.handleDropdownUserOnBlur = this.handleDropdownUserOnBlur.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  /**
   *This function the Updates User Info rendered in the component
   *
   * @memberof AvatarDrop
   */
  updateUserInfo() {
    this.setState({
      userData: utils.getUserInfo()
    });
  }

  /**
   *Handlers for the Dropdown Menu
   *
   * includes Mouse Over
   * Click
   * Focus
   * Blur
   * @param {*} e
   * @memberof AvatarDrop
   */
  handleDropdownUserOver(e) {
    console.log('over');
    this.setState({
      activeDropdown: !this.state.activeDropdown
    });
  }

  handleClickOutside() {
    this.setState({
      activeDropdown: false
    });
  }

  handleDropdownUserClick(e) {
    console.log('click');
    if (window.innerWidth > 669) {
      window.location = '/usuario/personal';
    }
  }

  handleDropdownUserOnBlur(e) {
    // console.log('onBlur',e);
    this.setState({
      activeDropdown: false
    });
  }
  handleDropdownUserOut(e) {
    // console.log(e);
    this.setState(prevState => {
      return {
        activeDropdown: !prevState.activeDropdown
      };
    });
  }

  render() {
    return (
      <div className="wrapper avatar-drop">
        {/* {
          window.innerWidth > 669
          ?
          <ReactTooltip
            id='userInfoTooltip'
            type='light'
            place='bottom'
            effect='solid'
            >
            Información del usuario
          </ReactTooltip>
          :
          null
        } */}
        <div
          data-tip
          data-for='userInfoTooltip'
          className={`avatar-wrapper flex-container ${this.state.activeDropdown ?'active' : ''} `}  // onClick={this.handleDropdownUserOut}
          /*onClick={this.handleDropdownUserOver}*/>
          <div className="flex-container end align menu-user">
            {/* <div className="mr-1">
              <img src={cash} className="dropdown-icons" width="30" height="auto" />
              <span
                style={
                  utils.getUserBalance().balanceReal < 100
                    ? { color: '#e5e6e8' }
                    : { color: '#78BC28', fontWeight: 'bold' }
                }>
                {numeral( utils.getUserBalance().balanceReal / 100).format(
                  '$0,000.00'
                )}
                <small>MXN</small>
              </span>
            </div>
            <div style={{border: '1px solid'}}>
              {this.props.userData.avatar === null ? (
                <img style={{ backgroundColor: '#fff' }} src={avatar} className="img-responsive usr-avatar" width="30" height="auto" />
              ) : (
                <img style={{ backgroundColor: '#fff' }}
                  src={
                    this.props.userData.avatar === avatar
                      ? avatar
                      : `${this.props.userData.avatar_dir +
                        this.props.userData.avatar}`
                  }
                  className="img-responsive usr-avatar"
                  width="25" height="auto"
                />
              )}
            </div> */}
            <Button
              bsStyle="pickwin"
              bsSize="sm"
              onClick={this.props.logout}>
              <i className="ion-log-out" /> <span>SALIR</span>
            </Button>
          </div>
        </div>
        {/* {this.state.activeDropdown
          ?
          <div className="user-Dropdown flex-col">
            <div className="user-info-dropdown flex-col">
              <div className="flex-row row-no-padding">
                <span className="user-info-item">
                  <strong style={{ fontSize: '1.1em' }}>
                    { this.props.userData.nick }
                  </strong>
                </span>
              </div>
            </div>
            <div className="user-actions-dropdown flex-col">
              <div className="flex-container align around header-menu-items">
                <LinkContainer to="/usuario/personal">
                  <NavItem>
                    <i className="pool-icons ion-android-person" style={{ marginRight: '3px' }} />
                    Mi Perfil
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/usuario/estadisticas">
                  <NavItem>
                    <i className="pool-icons ion-pie-graph" style={{ marginRight: '3px' }} />
                    Stats
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/usuario/retiros">
                  <NavItem>
                    <img src={retirarIconBlanco} className="pool-icons" style={{ marginRight: '3px' }} width="15" height="auto" />
                    Retiros
                  </NavItem>
                </LinkContainer>
                <LinkContainer to="/usuario/historial">
                  <NavItem>
                    <img src={historial} className="pool-icons" style={{ marginRight: '3px' }} width="15" height="auto" />
                    Historial
                  </NavItem>
                </LinkContainer>
                <Button
                  className="btn-transparent btn-salir"
                  onClick={this.props.logout}>
                  <i className="ion-log-out" />
                  <span>SALIR</span>
                </Button>
              </div>
            </div>
          </div>
          : null
        } */}
      </div>
    );
  }
}
export default onClickOutside(AvatarDrop);
