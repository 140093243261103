import React from 'react';
import { Button } from 'react-bootstrap';
import utils from '../../utils/utils';

import apuestas from '../../assets/images/sports/apuestas.png';
import golf from '../../assets/images/sports/golf.png';
import soccer from '../../assets/images/sports/fut.png';
import basketball from '../../assets/images/sports/basketball.png';
import baseball from '../../assets/images/sports/baseball.png';
import americano from '../../assets/images/sports/americano.png';
import f1 from '../../assets/images/sports/f1.png';


const Enlaces = () => {
  const links = [
    {url: '/conoce-pickwin/apuestas', img: apuestas},
    {url: '/conoce-pickwin/futbol', img: soccer},
    {url: '/conoce-pickwin/futbol-americano', img: americano},
    {url: '/conoce-pickwin/baseball', img: baseball},
    {url: '/conoce-pickwin/basketball', img: basketball},
    {url: '/conoce-pickwin/golf', img: golf},
    {url: '/conoce-pickwin/f1', img: f1},
  ];

  const userInfo = utils.getUserInfo();

  const copyToClipBoard = (index) => {
    var el = document.querySelector(`#landing-url-${index}`);
    el.select();
    try {
      document.execCommand('copy');
      el.setSelectionRange(0, 0);
      el.blur();
      alert('Enlace copiado');
    } catch( e ) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="flex-row row-center">
        <div className="flex-col text-center">
          <h1>Tu código de referido es</h1>
          <h2>{userInfo.referalCode}</h2>
        </div>
      </div>
      <div className="flex-row row-center">
        <div className="flex-col">
          <p>Creamos páginas especializadas por deporte para personalizar la experiencia por interés.</p>
          <p>Usa estos enlaces (links) en tus comunicaciones, posts o mensajes para que podamos asociar a tus usuarios con tu perfil de afiliado.</p>
          <p><em>Recuerda que los usuarios que creen su cuenta sin tu enlace no serán contabilizados.</em></p>
        </div>
      </div>
      {
        links.map((link, index) => {
          return (
            <div className="flex-container align mb-1" key={index}>
              <div className="mr-1">
                <Button bsSize="sm" bsStyle="pickwin" onClick={() => copyToClipBoard(index) }>
                  <i className="ion-android-share-alt"></i>
                </Button>
              </div>
              <div className="mr-1">
                <img src={link.img} className="img-responsive" style={{maxWidth: '20px', display: 'inline-block'}}/>
              </div>
              <div className="" style={{fontSize: "12px"}}>
                <a target="_blank" href={`https://www.pickwin.mx${link.url}?u=${userInfo.nick.trim()}&referer=${userInfo.referalCode}&utm_source=afiliados`}>
                  https://www.pickwin.mx{link.url}?u={userInfo.nick.trim()}&referer={userInfo.referalCode}&utm_source=afiliados
                </a>
                <input style={{width:0, height:0, border: '0 none', opacity: 0}} type="text" readOnly id={`landing-url-${index}`} value={`https://www.pickwin.mx${link.url}?u=${userInfo.nick.trim()}&referer=${userInfo.referalCode}&utm_source=afiliados`}/>
              </div>
            </div>
          );
        })
      }
    </>
  )
};

export default Enlaces;