import React from 'react';
import { Col, Row , Grid } from 'react-bootstrap/lib';
import NavigationBar from '../NavigationBar';
import Footer from '../Footer';
import API from '../../utils/api';
import ErrorBoundary from '../ErrorBoundary';
import { UserDataContextProvider } from '../../context/UserDataContext';

import Loading from '../Loading';

import LoggingOutPanel from '../LoggingOutPanel';

import utils from '../../utils/utils';
import branchUtils from '../../utils/branchUtils';

class PageWrapper extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLogged          : false,
      userData          : false,
      userBalance       : false,
      loggingOut        : false,
      mounted           : false,
      showInvite        : false,
      branchLink        : null,
      availableCashout  : null,
    }

    /**
     * this function handles the redirection from whats app click
     */
    this.whatsAppInvitation = () => {
      console.log('WhatsApp invitation');
      branchUtils.redirectToWhatsApp(
        this.state.branchLink,
        'open',
        this.state.poolInfo.pool.id
      );
    };

    /**
     * This function updates the branch Link after it is ready
     */
    this.updateBranchLink = newLink => {
      console.log('UpdateBranchLink', newLink);
      this.setState({
        branchLink: newLink
      });
    };

    this.getBranchLink = () => {
     return branchUtils.createLink( utils.getUserInfo() )
   }

   this.updateUserAvailable = () => {
     API.fetchAvailableCashout()
     .then( responseAvailable => {
       // utils.setUserInfo( responseUserData.data );
          // console.log( 'response', response );
         this.setState({
           availableCashout: responseAvailable.data,
         })
       })
       .catch( error =>{
         this.setState({
           mounted: true,
         })
         console.error( 'Error getting branch Link', error );
         Sentry.captureException( error );
       })
   }

   this.updateUserBalance = ( newBalance ) => {
    console.log( 'UpdateUser Balance from children', newBalance );
      const balanceObj = utils.getUserBalance();

      balanceObj.balanceReal = newBalance;
      console.log('SetBalance ');
      utils.setUserBalance( balanceObj );
      console.log( balanceObj );
      this.setState({ userBalance: balanceObj, isLogged: true } );
   }

   this.updateUserInfo = ( userData ) => {
    //  console.log( 'UpdateUser Info from children in PAge Wrapper' );
     if( !userData || userData == 'isNewRegister' ){
      //  console.log( 'UserData dont exist so fetch it' );
       API.fetchUserData()
        .then(responseUserData => {
          utils.setUserInfo( responseUserData.data );
          // console.log('SetBalance ', responseUserData );
          const balanceObj = utils.setUserBalance( responseUserData.data )
          this.setState( { isLogged: true, userData: responseUserData.data.user , userBalance: balanceObj } );
        })
        .catch(error => {
          utils.logout();
          Sentry.captureException(error, {
            extra: 'Error fetching user data'
          });
        });
     }
     else {
       console.log( 'UpdatedData from user' , userData );
      if( typeof(userData) != 'object'){ return }

       utils.setUserInfo( userData );
       const balanceObj = {
        balanceReal:userData.balanceReal,
        balancePc: userData.balancePc,
        balanceReferral: userData.balanceReferral,
        tickets: userData.tickets,
       }
      if( userData.hasOwnProperty('user') ){
       console.log( 'UpdatedData from user with user' , userData.user );
        this.setState({ userData: userData.user,  userBalance:balanceObj, isLogged: true } );
      }
      else {
       console.log( 'UpdatedData from user without' , userData );
        this.setState({ userData: userData, userBalance:balanceObj, isLogged: true } );
      }
     }
   }

    this.showOpenInvite = () => {
      console.log('ShowInvite');
      this.setState({ showInvite: !this.state.showInvite }, () => {
        if( this.state.showInvite ){
          this.getBranchLink()
          .then( response => {
            console.log(response);
            this.updateBranchLink( response );
          })
        }
      })
    }
  }

  componentDidMount(){
    if( utils.isLogged()){
      API.fetchUserData()
      .then( responseUserData  => {
        API.fetchAvailableCashout()
        .then( responseAvailable => {
          utils.setUserInfo( responseUserData.data );
          // console.log(  'Set userBalance mounting', responseUserData.data)
          const balanceObj = utils.setUserBalance( responseUserData.data );
          // console.log(  'userBalance', balanceObj );
          this.setState({
            isLogged    : true,
            branchLink  : null,
            userData    : responseUserData.data.user,
            userBalance : balanceObj,
            availableCashout: responseAvailable.data,
            userDataChanged: true,
            mounted     : true,
          })
        })
        .catch( error => {
          this.setState({
            mounted: true,
          })
          console.error('Error getting userData', error);
          Sentry.captureException( error );
        })
      })
      .catch( error => {
        utils.logout();
      })
    }
    else {
      this.setState({mounted:true})
    }
  }

  render(){
    if( this.state.mounted){
      return (
        <ErrorBoundary>
          <UserDataContextProvider
             userData={ this.state.userData }
             userBalance={ this.state.userBalance }
             isLogged={ this.state.isLogged }
             availableCashout={  this.state.availableCashout }
             branchRegisterLink={ this.state.branchLink }
             updateUserInfo={ this.updateUserInfo }
             updateUserBalance={ this.updateUserBalance }
             updateUserAvailable={ this.updateUserAvailable }
            >
            <div className='page-wrapper'>
              <div className='inner-wrapper'>
                {this.state.isLogged ? <LoggingOutPanel type='expired'/> : null}
                <NavigationBar isLogged={ this.state.isLogged} userBalance={ this.state.userBalance } userData={ this.state.userData} />
      					{ this.props.children }
                <div id='push'></div>
              </div>
              {utils.isLogged() ? (
                <div className='col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main' style={{height:'120px', paddingTop: '0px'}}>
                  <Footer/>
                </div>
              ) : (
                <div className='col-sm-10 col-sm-offset-1' style={{height:'120px', paddingTop: '0px'}}>
                  <Footer/>
                </div>
              )}
            </div>
          </UserDataContextProvider>
      </ErrorBoundary>
      )
    }
    else {
      return (
      <div className='page-wrapper'>
        <div className='inner-wrapper'>
          <Loading/>
          <div id='push'></div>
        </div>
        <div className='footer'>
					<Footer/>
				</div>
      </div>
    )
    }

  }
}

export default PageWrapper;
