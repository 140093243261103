import React from "react";
import { Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import api from "../../utils/api";
import Loading from "../Loading";

const AccountDetails = () => {
  const [loading, setLoading] = React.useState(true);
  const [details, setDetails] = React.useState({
    contact_email: null,
    representative: null,
    phone: null,
    business_name: null,
    tax_identifier: null,
    business_address: null,
    payment_method: null,
    bank: null,
    account: null,
  });

  React.useEffect(() => {
    api.getAccountDetails().then((response) => {
      const data = response.data;
      const newDetails = {...details, ...data.details};
      setDetails(newDetails);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex-row row-center">
        <div className="flex-col">
          <h3>Información de tu cuenta</h3>
          <p>
            Para cambiar la información de tu cuenta por favor contacta a{" "}
            <a href="mailto:soporte@pickwin.net">soporte</a>
          </p>
        </div>
      </div>

      <Form horizontal>
        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Nombre del representante legal</Col>
          <Col md={4}>
            <FormControl readOnly value={details.representative}></FormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Correo Electrónico</Col>
          <Col md={4}>
            <FormControl readOnly value={details.contact_email}></FormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Número de Contacto</Col>
          <Col md={4}>
            <FormControl readOnly value={details.phone}></FormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Razon Social</Col>
          <Col md={4}>
            <FormControl readOnly value={details.business_name}></FormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>RFC</Col>
          <Col md={4}>
            <FormControl readOnly value={details.tax_identifier}></FormControl>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Dirección Fiscal</Col>
          <Col md={4}>
            <FormControl readOnly componentClass="textarea" rows="10" value={details.business_address}></FormControl>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Forma de pago</Col>
          <Col md={4}>
            <FormControl readOnly value={details.payment_method}></FormControl>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Banco</Col>
          <Col md={4}>
            <FormControl readOnly value={details.bank}></FormControl>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col md={3} componentClass={ControlLabel}>Cuenta</Col>
          <Col md={4}>
            <FormControl readOnly value={details.account}></FormControl>
          </Col>
        </FormGroup>

      </Form>

    </>
  );
};

export default AccountDetails;
