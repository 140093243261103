import React from 'react';
import Formsy from 'formsy-react';
import API from '../../utils/api';
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  NavLink,
  HelpBlock} from 'react-bootstrap/lib';

import MailInviteInput from './MailInviteInput';


class FormMailInvite extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      modelInvite:{
        name      : '',
        email     : '',
        message   : '',
      },
      errorInvite       : false,
      successInvite     : false,
      submitting        : false,
      failed            : false,
      canSubmit         : false,
    }

    this.disableButton                = this.disableButton.bind(this);
    this.enableButton                 = this.enableButton.bind(this);
    this.resetData                    = this.resetData.bind(this);
    // console.log('formLogin', this.props);

    this.sendMail = () => {
      this.setState({ submitting: true });
      API.postNewReferralInvitation( this.state.modelInvite )
      .then( response => {
        this.callbackResponseInvite();
      })
      .catch( error => {
        console.error( error );
        Sentry.captureException( error );
      })
    }

    this.callbackResponseInvite = ( response ) => {
      if( !response || !response.data || !response.data.success ){
        console.error('Error invite');
        this.setState({
          submitting: false,
          errorInvite : true,
          successInvite: false,
        });
      }
      else {
        this.setState({
          submitting: false,
          errorItem: false,
          successInvite: true,
        });
      }
    }

    this.handleNameMail = ( e ) => {
     let val = e.target.value;

     let temporalModel = this.state.modelInvite;
     temporalModel.name = val;
     this.setState({ modelInvite: temporalModel });
    }

    this.handleInviteMail = ( e ) => {
     let val = e.target.value;

     let temporalModel = this.state.modelInvite;
     temporalModel.email = val;
     this.setState({ modelInvite: temporalModel });
    }

    this.handleInviteMessage = ( e ) => {
     let val = e.target.value;

     let temporalModel = this.state.modelInvite;
     temporalModel.message = val;
     this.setState({ modelInvite: temporalModel });
    }


  }



  disableButton() {
    // console.log('disable button');
    
    this.setState({ canSubmit: false });
  }

  enableButton() {
    // console.log('Enable Button')
    this.setState({ canSubmit: true });
  }

  resetData(){

  }

  componentDidMount(){
    console.log('Mounted', this.props.userInviteObject);
    if( this.props.userInviteObject ){
      console.log(this.props.userInviteObject);
      let temporalModel = this.state.modelInvite;
      temporalModel.name = this.props.userInviteObject.name;
      temporalModel.message = `¿Ya viste esta quiniela? Éntrale ¡Tiene buena bolsa!`;
      this.setState({ modelInvite: temporalModel })
    }
  }

  render(){
  const prevForm = (
    <Formsy
			className='flex-col col-offset-5 col-90'
			onValidSubmit={ e => { this.props.sendMail(e); }}
      onValid={this.enableButton}
      onInvalid={this.disableButton}>
      <MailInviteInput
        inputHandler={this.handleNameMail}
        label='Nombre'
        name="nick"
        required
        type='NICK'
        defaultValue={ this.state.modelInvite.name }
        validationError="El nombre de tu invitado no puede estar vacìo."
      />
      <MailInviteInput
        inputHandler={this.handleInviteMail}
        label='Dirección de email'
        name="email"
        required
        type='MAIL'
        defaultValue={ this.state.modelInvite.email }
        validations='isEmail'
        validationError="El email no es válido."
      />
      <MailInviteInput
        inputHandler={ this.handleInviteMessage }
        label='Escribe tu mensaje'
        name="message"
        required
        type='MESSAGE'
        defaultValue={ this.state.modelInvite.message }
        validationError="Tù mensaje no puede estar vacìo."
      />
			<div className='flex-row row-center' >
      <Button
        className='login-btn'
        bsStyle="primary"
        disabled={this.props.submitting || !this.state.canSubmit}
        bsSize="large"
        onClick={ this.sendInvitation }
        block>
        <i className="ion-ios-email"></i> Invitar </Button>
		</div>
			<div className='flex-row responsive-sm'>
      </div>
    </Formsy>
  );
      return prevForm
  }
}

export default FormMailInvite;
