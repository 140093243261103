import  React from 'react';

import RegisterFormPanel from '../RegisterFormPanel';

import { Modal } from 'react-bootstrap';

import utils from '../../utils/utils';

// console.log(utils);

class RegisterPopUp extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loggedIn    : null,
      mounted     : false,
      userId      : null,
      userData    : null,
      trackPixel  : true,
      from        : {pathname:'/'},
    }

    this.initFingerprint = utils.initFingerprint.bind(this);

    this.loggedInCorrect = () => {
        this.setState({ loggedIn: status })
    }

  }

  componentDidUpdate(prevProps, prevState){
    if( !prevProps.showRegisterPopup && this.props.showRegisterPopup && this.state.trackPixel){
      this.setState({ trackPixel: false });
      // fbq('track', 'PageView');
      // fbq('trackCustom', 'RegisterForm')
    }
    if( !prevProps.showRegisterPopup && this.props.showRegisterPopup && this.props.referer != prevProps.referer && this.props.referer != undefined && this.props.referer != null) {
      console.log('Referer set in Registrar Popup');
      utils.setReferer(this.props.referer)
      this.setState({
        referer: this.props.referer
      })
    }
  }

  componentDidMount() {
    let userData = utils.getUserInfo();
    let referal = null;

    // Parse Referal
    if(this.props.referer != undefined && this.props.referer != null) {
      // utils.setReferer(this.props.referer)
      referal = this.props.referer;
    }
    else  {
      // referal = utils.getReferer();
    }

  if(window.requestIdleCallback){
    requestIdleCallback( () => {
      this.setState({
        fingerprint     : this.initFingerprint(),
        mounted         : true,
        referal         : referal,
        userData        : userData,
        userId          : userData ? userData.id : null,
    });
    })
  }
  else {
    setTimeout( ( ) => {
      this.setState({
        fingerprint     : this.initFingerprint(),
        mounted         : true,
        referal         : referal,
        userData        : userData,
        userId          : userData ? userData.id : null,
    });
    }, 500 )

  }

  }

  render(){
    return(
      <Modal
        bsSize='lg'
        className='absoluteModal'
        backdropClassName='registroPanelBackdrop'
        dialogClassName='registroPanelDialog'
        onHide={this.props.hideRegister}
        show={this.props.showRegisterPopup}
        >
          <div className="modal-registro flex-row">
            <div id='registrarsePanel' className='flex-col'>
              <RegisterFormPanel
                type='popup'
                referal={this.state.referal}
                hideRegister={this.props.hideRegister}
                updateUserInfo={this.props.updateUserInfo}
                fingerPrint={this.state.fingerprint}
              />
            </div>
          </div>
      </Modal>
    )
  }

}

export default RegisterPopUp;
