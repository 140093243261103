import React from 'react';
import utils from '../../utils/utils';

import PageWrapper from '../PageWrapper';
import Loading from '../Loading';

import LoggingOutPanel from '../LoggingOutPanel/LoggingOutPanel';


const ContentPrivacidad = () => {
  return (
    <div className="flex-row responsive-md">
      <div className="flex-col col-80 col-offset-10 responsive-sm legal-section lateralPadding">
        <h1 style={{fontSize:'130%'}}><u>AVISO DE PRIVACIDAD</u></h1>
        <ol start="1" type="1">
          <li>
            <strong>Identidad y Domicilio del Responsable.</strong>
            <p>Capali La Paz Operaciones, S.A. de C.V. (en lo sucesivo CAPALI) Operador debidamente autorizado de Producciones Móviles, S.A. de C.V. (Permisionaria autorizado por la Secretaría de Gobernación) es Responsable del Tratamiento de sus datos personales conforme a los términos del presente con motivo de uso y/o registro de Usuario que Ud. haga de los servicios que presta a través del portal de Internet www.Capali.net (en lo sucesivo, el “Portal”).</p>
            <p>Los términos identificados con mayúscula inicial tendrán el significado que les corresponda de acuerdo con la Ley de Protección de la Persona Frente al Tratamiento de sus Datos Personales (LPPFTDP) y/o los Términos de Uso de los cuales este Aviso de Privacidad es parte integrante.</p>
          </li>
        </ol>
        <ol start="2" type="1">
          <li>
            <strong>Datos Personales Sujetos a Tratamiento</strong>
            <p>Para los fines establecidos en este Aviso de Privacidad, podemos obtener de usted los siguientes datos personales:</p>
            <ul type="circle">
              <li>Datos de identificación: nombre, nacionalidad, lugar y fecha de nacimiento, registro federal de contribuyentes, C.U.R.P., fecha de nacimiento, estado civil, profesión, y copia de su identificación oficial, en su caso.</li>
              <li>Datos de contacto: domicilio incluyendo estado, código postal y país, correo electrónico y teléfonos.</li>
              <li>Registros informáticos relacionados con actividades y comportamiento en internet: horario de navegación, dirección de Protocolo de Internet ("IP"), tiempo de navegación en nuestro sitio web, secciones consultadas y páginas web que visitó antes de ingresar a nuestro Portal.</li>
              <li>Datos Financieros: para efectos de completar las transacciones en línea, CAPALI podrá recolectar su número de tarjeta de crédito o débito, fecha de vencimiento, nombre del titular y copia de las tarjetas y recibos de las compras realizadas en EL PORTAL, en su caso.</li>
            </ul>
            <p>Nota: Datos Personales Sensibles. Ocasionalmente podemos recibir de usted datos personales que son, o podrían llegar a ser considerados como datos personales sensibles por la LPPFTDP, como lo son hábitos y preferencias de juego; nosotros no le solicitaremos estos datos. Obtendremos esta información únicamente cuando usted nos la proporcione voluntariamente y solamente los usaremos para atender su solicitud, y en caso de ser aplicable, cumplir las obligaciones derivadas de la relación jurídica de servicios que pudiéramos tener con usted.</p>
          </li>
        </ol>
        <ol start="3" type="1">
          <li>
            <strong>Finalidades del tratamiento de sus datos personales.</strong>
            <p>La obtención y tratamiento de sus datos personales se realiza para las siguientes finalidades, mismas que consideramos como necesarias para la existencia, mantenimiento y cumplimiento de la relación que tenemos con usted:</p>
            <ol start="1" type="a">
              <li>Proporcionarle información de nuestros productos y servicios cuando usted lo solicite;</li>
              <li>Recibir quejas, y dar seguimiento a solicitudes;</li>
              <li>Mantener actualizados nuestros registros a fin de poder responder a sus consultas;</li>
              <li>Venta del bien y/o servicio que solicite;</li>
              <li>Para la participación en concursos y/o promociones a través de nuestros sitios web y/o cuentas de redes sociales oficiales;</li>
              <li>Para encuestas de servicios que nos permitan mejorar nuestros productos; y</li>
              <li>Para fines de mercadotecnia y/o estudio de mercado;</li>
              <li>Para elaborar los reportes de actividades vulnerables a que estamos obligados conforme a la legislación correspondiente.</li>
            </ol>
            <p>Solamente que usted así nos lo indique, procederemos a inscribirlo a nuestra base de datos para enviarle información de nuestros productos, a través del cual recibirá mensajes publicitarios, información que usted puede solicitar dejar de recibir en cualquier momento, utilizando el mecanismo incluido en todos nuestros correos.</p>
            <p>Las finalidades identificadas en los incisos a, b, c, d, e son requeridas, con motivo de nuestra relación jurídica que tenemos con usted. Las finalidades identificadas en los incisos f y g no son necesarias para mantener nuestra relación jurídica por lo que usted tiene el derecho a oponerse o bien revocar su consentimiento para que Pickwin deje de tratar sus datos personales con dichas finalidades, lo cual podrá contactándonos a las direcciones que aparecen en el numeral 8 de este Aviso.</p>
          </li>
        </ol>
        <ol start="4" type="1">
          <li>
            <strong>Uso de cookies, web beacons y dispositivos similares.</strong>
            <p>En nuestro sitio web, recabamos información empleando cookies, web beacons o dispositivos similares. Las cookies, web beacons y dispositivos similares utilizados en nuestro sitio web simplifican la administración de los sitios y la navegación a través del mismo y mejoran la experiencia en línea del visitante. La información recogida incluye, entre otra, (1) dirección IP del usuario (por ejemplo, princeton.edu-192.168.1.1), el tipo de navegador que utiliza (Netscape Navigator o Microsoft Explorer) y su sistema operativo (Windows o Linux); (2) las páginas de los sitios web que visita el usuario; y (3) otros sitios web que visitó el usuario antes de acceder al nuestro.</p>
            <p>Las cookies NO contienen datos personales del usuario como puede ser su nombre o dirección de correo electrónico. La mayoría de los navegadores aceptan cookies automáticamente y pueden configurarse para que no lo hagan o para que notifiquen al usuario cada vez que reciben una cookie. Los siguientes enlaces muestran como ajustar la configuración del navegador de los navegadores comúnmente usados:</p>
            <ul type="circle">
              <li>Chrome <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">https://support.google.com/chrome/answer/95647?hl=es</a></li>
              <li>Internet Explorer <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
              <li>Firefox <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a></li>
              <li>Safari <a href="https://support.apple.com/kb/ph21411?locale=es_ES" target="_blank">https://support.apple.com/kb/ph21411?locale=es_ES</a></li>
            </ul>
            <p>Por favor considere que si usted elige bloquear las cookies es posible que se afecte la forma en que usted usa nuestro sitio web o algún recurso en particular.</p>
            <p>En ocasiones también utilizamos web beacons. Utilizamos web beacons en nuestros sitios web para contabilizar y reconocer a los usuarios mediante al acceso a nuestras cookies. De esta forma, podemos personalizar nuestro sitio web y mejorar su experiencia de navegación. También podemos incluir web beacons en forma de mensajes de correo eléctrico en formato HTML que enviamos para determinar qué mensajes electrónicos han sido abiertos.</p>
          </li>
        </ol>
        <ol start="5" type="1">
          <li><strong>Remisiones de datos personales a proveedores.</strong>
            <p>Capali puede remitir total o parcialmente los datos personales de nuestros usuarios a proveedores de servicios que nos apoyan en algún proceso. Estos proveedores incluyen: (i) empresas que tecnología que nos prestan diversos servicios de comunicaciones, contabilidad o infraestructura; (ii) empresas que nos prestan servicios de mercadotecnia y estudios de mercado. En todos estos casos, Capali se asegura de que estas empresas asuman obligaciones contractuales que permitan que los datos personales de nuestros usuarios se encuentren protegidos, además de que se hace de su conocimiento las obligaciones aplicables conforme a la LPPFTDP.</p>
          </li>
        </ol>
        <ol start="6" type="1">
          <li><strong>¿A quién podemos transferir sus datos personales?</strong>
            <p>Podemos transferir los datos personales de nuestros usuarios a otras empresas o a autoridades en ciertos casos, para las finalidades establecidas en este Aviso de Privacidad. Estas transferencias incluyen las siguientes:</p>
            <ol type="a">
              <li>Capali puede transferir datos personales de sus usuarios a autoridades administrativas o judiciales, cuando la transferencia sea necesaria o legamente exigida para la salvaguarda de un interés público, la protección de los derechos de Capali, la protección de derechos de terceros o para la procuración o administración de justicia.</li>
              <li>Asimismo, ocasionalmente, Capali puede ser parte en alguna transacción corporativa (incluyendo fusiones, escisiones, ventas de activos o acciones, reestructuras corporativas, entre otras transacciones). En tanto que usualmente los usuarios son uno de los activos a ser considerados como parte de la transacción, Capali puede comunicar sus datos personales a terceros en el contexto de dichas transacciones. Entre dichos terceros se encuentran, entre otros, los potenciales adquirentes de los activos y los asesores (legales, contables o financieros, entre otros) que participen en la transacción. Únicamente comunicaremos sus datos personales a dichos terceros en la medida que dicha comunicación resulte necesaria para evaluar o completar la transacción o cuando la transferencia resulte necesaria como consecuencia de la misma.</li>
              <li>En otros casos, cuando la ley permita esta transferencia sin el consentimiento previo del titular.</li>
            </ol>
            <p>Todas las transferencias anteriores (y las remisiones incluidas en el numeral 5 anterior) pueden tener el carácter nacional o internacional; asimismo, todas son precisas para el mantenimiento o cumplimiento de la relación comercial con los clientes y, por lo tanto, no requerimos el consentimiento del cliente para realizarlas.</p>
            <p>Al proporcionar sus datos personales, usted acepta los términos y condiciones establecidos en el presente aviso de privacidad, usted manifiesta su consentimiento expreso para que Capali haga uso, manejo y tratamiento de los mismos para los fines aquí estipulados.</p>
            <p>Usted consiente su tratamiento tanto dentro como fuera de Curacao y entiende que podrán ser tratados por Pickwin, sus sociedades subsidiarias, afiliadas o relacionadas, sus distribuidores autorizados y/o por aquellos proveedores de servicios con quienes tiene una relación jurídica y fungen como encargados en términos de los establecido por la LPPFTDP.</p>
            <p>Por lo anterior, la información y datos personales que usted proporcione a Capali se almacenarán en la Base de Datos de Capali que podría localizarse fuera de territorio costarricense. A su vez, Capali en este acto manifiesta que los datos personales que sean recolectados de usted, podrán ser proporcionados a cualquier tercero con quien Capali hubiese contratado servicios para el manejo de la Base de Datos para los fines indicados en el presente aviso.</p>
          </li>
        </ol>
        <ol start="7" type="1">
          <li><strong>¿Cuáles son sus derechos?</strong>
            <p>Usted goza de los derechos de Acceso, Rectificación, Cancelación y Oposición al Tratamiento (los “Derechos ARCO”); asimismo, cuenta con el derecho de revocar en cualquier tiempo el consentimiento otorgado para el tratamiento de sus datos personales en la medida que la ley lo permita. Para el ejercicio de sus Derechos ARCO o su derecho de revocación o para formular cualquier duda o queja con relación al tratamiento de sus datos personales por favor contacte a nuestro Departamento de Datos Personales (“DDP”). Sus solicitudes serán evaluadas en los términos establecidos en la LPPFTDP y otras obligaciones aplicables a Pickwin. Nuestro DDP le comunicará (i) la información que se le solicitará para que se identifique así como los documentos que necesitará enviar junto con su solicitud; (ii) los plazos en los que recibirá una contestación sobre su solicitud; (iii) cómo debe de presentar su solicitud, incluyendo los formularios que puede usar para presentar su solicitud, si los hubiere, y; (iv) la modalidad o medio en que le entregaremos la información a usted. En virtud de lo anterior, Pickwin cumplirá en todo momento a las solicitudes que en el ejercicio de sus derechos contenidos en la LPPFTDP le haga llegar mediante un correo electrónico.</p>
          </li>
        </ol>
        <ol start="8" type="1">
          <li><strong>Contacte a nuestro DDP.</strong>
            <p>Para efectos de cualquier aclaración, duda o solicitud conforme el presente aviso de privacidad y/o el manejo de sus datos personales, por favor envíenos un correo electrónico en atención a:</p><p>
              <a href="mailto:hola@pickwin.net">{'hola@pickwin.net'}</a>
            </p>
          </li>
        </ol>

        <ol start="9" type="1">
          <li><strong>¿Qué opciones ofrece Capali para limitar el uso o divulgación de sus datos personales?</strong>
            <p>Capali le ofrece la posibilidad de tomar decisiones sobre cómo usamos sus datos personales; le ofrecemos diversos medios para informarnos acerca de su decisión.</p>
            <p>Si usted no desea recibir mensajes publicitarios de nosotros o cancelar su suscripción a nuestras bases de datos, usted: (a) puede solicitar cancelar su suscripción a nuestras listas de correo haciendo uso del mecanismo establecido en nuestros correos electrónicos; y/o (b) podrá contactar a nuestro DDP.</p>
          </li>
        </ol>



        <ol start="10" type="1">
          <li><strong>Seguridad de sus datos personales</strong>
            <p>Nos comprometemos a cumplir con todo lo dispuesto por la LPPFTDP y a observar en todo momento, los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad contenidos en la misma.</p>
            <p>Capali ha adoptado medidas de seguridad físicas, organizacionales, y técnicas razonables para proteger sus datos personales en contra de pérdida, uso indebido o acceso no autorizado, alteración o divulgación ilegal de la información y datos personales que nos llegase a proporcionar en cumplimiento de lo dispuesto por la LPPFTDP.</p>
          </li>
        </ol>



        <ol start="11" type="1">
          <li><strong>Modificaciones al Aviso de Privacidad</strong>
            <p>Capali se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad. El aviso modificado será publicado en lugares visibles en nuestra página web o se le hará llegar a través de correo electrónico, en caso de contar con información de contacto. Nuestros usuarios podrán verificar que el Aviso ha sido modificado en virtud de que haremos notar siempre la fecha de última actualización. En caso de que Capali realice algún cambio sustancial en el tratamiento de sus datos personales, se lo haremos saber a través de una notificación. Dichos cambios le serán notificados por correo electrónico a la dirección de correo que usted haya registrado al momento de proporcionar sus datos personales o anunciando estos cambios en nuestros sitios web. Todo cambio entra en nuestra aviso de privacidad entra en vigor a los 15 días naturales de la publicación del nuevo aviso. Si usted no esta de acuerdo con las modificaciones, por favor contacte a nuestro DDP solicitando la cancelación de sus datos personales.</p>
          </li>
        </ol>
      </div>
    </div>
  )
}


export const ContentTerminos = () => {
  return (
    <div className="flex-row">
      <div className="flex-col">
        <h3>Términos y Condiciones de Servicio</h3>

        <p><strong className='text-center' style={{ fontSize:'1.2em'}}>Fecha de actualización: Julio 15 de 2019.</strong></p>
        <p>
          El programa de socios vendedores de Pickwin es para personas mayores de 18 años que vivan en la República Mexicana y que hayan sido invitados por Pickwin para ser parte del programa.
        </p>
        <p>
          Los participantes del programa de socios vendedores deberán crear una cuenta en www.sales.pickwin.mx usando información verídica y que corresponda con la de su identificación oficial. Posteriormente deberán validar su número de celular dentro de la plataforma.
        </p>
        <p>
          Pickwin se compromete a pagarle a los socios vendedores una comisión de acuerdo a los montos jugados de los usuarios (por usuario) que hayan invitado a jugar quinielas en www.pickwin.mx. Para poder contabilizar a los usuarios que los socios vendedores hayan invitado, éstos deberán registrarse usando el enlace de invitación del socio referido.
        </p>
        <p>
          Para que los montos jugados de un usuario invitado le cuenten al socio vendedor, el invitado deberá de:
        </p>
        <ol>
          <li>Haber creado su cuenta en www.pickwin.mx usando el enlace de invitación del socio vendedor</li>
          <li>Proveer información real y verídica al crear su usuario</li>
          <li>La información de registro debe de coincidir con la identificación oficial del usuario así como el beneficiario de la cuenta de banco o tarjeta de débito dónde se depositarían las ganancias</li>
          <li>El usuario deberá validar su cuenta por medio de un SMS que se enviará a su número celular</li>
          <li>Haber realizado al menos un depósito de $50 pesos en Pickwin</li>
          <li>Haber jugado quinielas públicas con costo de entrada en pesos mexicanos</li>
        </ol>
        <p> El pago de las comisiones fijas al socio vendedor se realizarán de acuerdo a la siguiente tabla:</p>

        <div className='flex-col' style={ {border:'1px solid #fff', background:'rgba(120,120,120,.3)' } }>
          <div className='flex-row headers'>
            <div className=' flex-col col-center col-70'>
              <strong>Descripción</strong>
            </div>
            <div className=' flex-col col-center'>
              <strong>Comisión</strong>
            </div>
          </div>

          <div className='flex-row content'>
            <div className=' flex-col col-center col-70'>
              <strong>Primer Deposito</strong>
            </div>
            <div className=' flex-col'>
              <p>$ 50</p>
            </div>
          </div>

          <div className='flex-row content'>
            <div className=' flex-col col-center col-70'>
              <strong>Suma de entradas = $ 300</strong>
            </div>
            <div className=' flex-col'>
              <p>$ 50</p>
            </div>
          </div>

          <div className='flex-row content'>
            <div className=' flex-col col-center col-70'>
              <strong>Suma de entradas = $ 650</strong>
            </div>
            <div className=' flex-col'>
              <p>$ 100</p>
            </div>
          </div>

          <div className='flex-row content'>
            <div className=' flex-col col-center col-70'>
              <strong>Suma de entradas = $ 1,100</strong>
            </div>
            <div className=' flex-col'>
              <p>$ 150</p>
            </div>
          </div>

        </div>

        <p>Cuando la suma de entradas mensuales de un usuario exceda $1,100 pesos mexicanos, al socio vendedor se la pagará el 2% de la suma de montos jugados menos $1,100. Ejemplo:</p>
        <ul>
          <li><strong> Suma montos jugados: </strong>$2,000 pesos </li>
          <li> <strong> Fórmula para cálculo de comisión:</strong> 2% *(2,000 – 1,100) = $18</li>
        </ul>

        <p>Cuándo la suma de entradas mensuales de un usuario exceda los $6,500 pesos mexicanos, al socio vendedor se le pagará el 1% de la suma de montos jugados menos $6,500 pesos. Ejemplo: </p>
        <ul>
          <li><strong> Suma montos jugados: </strong>$20,000 pesos </li>
          <li><strong> Fórmula para cálculo de comisión:</strong> 1% *(20,000 – 6,500) = $135</li>
        </ul>

        <p>La comisión se abonará de forma automática una vez al mes a finales de mes al saldo de la cuenta en Pickwin del socio vendedor cuando la suma de las entradas de quinielas públicas de un invitado sume el monto jugado indicado en la tabla. Ejemplo: cuando la suma de entradas a quinielas públicas de un invitado sumen $200 se le abonarán $100 pesos al saldo de la cuenta en Pickwin del socio vendedor. </p>
        <p>El socio vendedor podrá retirar el saldo en su cuenta en Pickwin a una tarjeta de débito o cuenta de banco que el socio vendedor prefiera. Los datos de la cuenta bancaria deben de corresponder con los datos que el socio vendedor uso para crear su cuenta en www.sales.pickwin.mx así como con los de su identificación oficial. El monto mínimo a retirar de la cuenta de Pickwin a cuenta de banco o tarjeta de débito es de $100 pesos.</p>
        <p>La suma de entradas de quinielas privadas no se tomarán en cuenta para el cálculo del pago de comisiones. Tampoco se considerarán los montos de entrada que provengan de regalos, tickets o promociones organizadas por Pickwin para incentivar la participación de los usuarios. </p>
        <p>Pickwin se reserva el derecho de cancelar los bonos y cualquier ganancia en caso de que sospeche que los clientes poseen registros de cuentas duplicadas, falsas o con información de tarjetas de crédito robadas con el único propósito de reclamar estos bonos.</p>
        <p>Pickwin se reserva el derecho de cancelar los bonos y cualquier ganancia en caso de que sospeche que los clientes poseen registros de cuentas duplicadas, falsas o con información de tarjetas de crédito robadas con el único propósito de reclamar estos bonos.</p>
        <p>En caso de que un usuario invitado por un socio vendedor presente contra cargos o cargos no reconocidos después de haber abonado saldo a su cuenta en Pickwin, se retendrá y cancelara cualquier pago de comisión al socio vendedor.</p>
        <p>PRODUCCIONES MÓVILES S.A. DE C.V.", TITULAR DEL PERMISO DE GOBERNACIÓN  </p>
        <p>DGAJS/SCEVF/P-06/2005-Ter EN UNIÓN DE SU OPERADOR "CAPALI LA PAZ OPERACIONES S.A. DE C.V." DE CONFORMIDAD CON EL OFICIO DGJS/SCEV/1339/2012. JUEGOS DE APUESTAS PROHIBIDOS PARA MENORES DE EDAD. JUEGA RESPONSABLEMENTE.</p>
        <p>NO OLVIDES QUE EL PROPÓSITO ES EL ENTRETENIMIENTO, DIVERSIÓN Y ESPARCIMIENTO.</p>
      </div>
    </div>
  )
}

class Terminos extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      userData: utils.isLogged() ? utils.getUserInfo() : null,
      logged: utils.isLogged(),
      mounted: false,
    }
  }

  componentDidMount(){
    this.setState({
      userData: utils.isLogged() ? utils.getUserInfo() : null,
      logged: utils.isLogged(),
      mounted: true,
    });
  }

  render(){
    let conditionalComponent = null;

    if(this.state.mounted){
      switch (this.props.which) {
        case 'privacidad':
          conditionalComponent = (
            <ContentPrivacidad/>
          )
          break;
        case 'terminos':
          conditionalComponent = (
            <ContentTerminos/>

          )
          break;
      }
    }
    else {
      conditionalComponent = (<Loading label='...'/>)
    }
    return (
      <PageWrapper>
        <LoggingOutPanel type='expirationLogout'/>
          {conditionalComponent}
          <div id='push'/>
      </PageWrapper>
    )
  }
}

export default Terminos;
