import React from 'react';
import Formsy from 'formsy-react';
import API from '../../utils/api';
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  NavLink,
  HelpBlock} from 'react-bootstrap/lib';

  import { Redirect } from 'react-router'

import utils from '../../utils/utils';
import ErrorField from '../ErrorField';
import FacebookLogin from 'react-facebook-login';
import RegisterInput from './RegisterInput';

class RegisterForm extends React.Component{
  constructor( props ){
    super(props);
    this.state = {
      registerModel : {
        fingerprint   : null,
        phone         : null,
        name          : null,
        last_name     : null,
        nick          : null,
        email         : null,
        password      : null,
        cpassword     : null,
        tos           : false,
        oldenough     : false,
        newsletter    : true,
        users_metadata: null,
				viewPassConf  : false,
				viewPass			: false,
			} ,
      fbPop         : false,
      fbStatus      : null,
      fbData        : null,
      submitting    : false ,
      canSubmit     : false ,
    }

    this.disableButton                = this.disableButton.bind(this);
    this.enableButton                 = this.enableButton.bind(this);
    this.resetData                    = this.resetData.bind(this);

    this.responseFacebook = ( fbResponse ) =>  {
      console.log(' %c fbResponse %o', 'color:#4267B2;', fbResponse);
      if (
        !fbResponse.hasOwnProperty('userID') && !fbResponse.hasOwnProperty('accessToken')
      ) {
        this.setState({
          fbFailed: true,
          fbData:   fbResponse,
        });
      }
        else {
          API.postFacebookLogin( fbResponse.userID, fbResponse.accessToken )
          .then(response => {
            try{
              utils.checkAxiosAllErrors( [response] );
            }
            catch(e){
              console.error(e);
              // Sentry.captureException( e );
            }
            let data = null;

            try{
              data = response.data;
            }
            catch(e){
              throw 'Failed Fb Login Response without data'
            }

            if (this.props.type == 'popup') {
              console.log( 'Login Facebook in register Popupfrom register', this.props.updateUserInfo );
              this.setState( {
                  submitting: false
                },
                () => {
                  if (!this.props.updateUserInfo) {
                    setTimeout(() => {
                      window.location.assign('/');
                    }, 1000);
                  } else {
                    this.props.updateUserInfo(response.data);
                    this.props.hideRegister();
                  }
                }
              );
            }
              else {
                  utils.setUserInfo(data);

                this.setState({
                  redirectToLobby: true,
                  registerSuccess: true
                });
              }
            })
        .catch(error => {
          this.setState({ fbFailed: true, fbData: fbResponse });
          Sentry.captureException(error )
        });
      }
    }

    this.handleName = ( e ) => {
        let temporalRegisterModel = this.state.registerModel;
        temporalRegisterModel.name = e.target.value;

        // console.log( 'Name: ', temporalRegisterModel.name );

        this.setState({ registerModel: temporalRegisterModel });
    }

    this.handlePhone = ( e ) => {
        let temporalRegisterModel = this.state.registerModel;
        temporalRegisterModel.phone = e.target.value;

        // console.log( 'Phone: ', temporalRegisterModel.phone );

        this.setState({ registerModel: temporalRegisterModel });
    }

    this.handleLastName = ( e ) => {
        let temporalRegisterModel = this.state.registerModel;
        temporalRegisterModel.last_name = e.target.value;

        // console.log( 'LastName: ', temporalRegisterModel.last_name );

        this.setState({ registerModel: temporalRegisterModel });

    }
    this.handleMail = ( e ) => {
        let temporalRegisterModel = this.state.registerModel;
        temporalRegisterModel.email = e.target.value;

        // console.log( 'Email: ', temporalRegisterModel.email );

        this.setState({ registerModel: temporalRegisterModel });

    }
    this.handleNick = ( e ) => {
        let temporalRegisterModel = this.state.registerModel;
        temporalRegisterModel.nick = e.target.value;

        // console.log( 'Nick: ', temporalRegisterModel.nick );

        this.setState({ registerModel: temporalRegisterModel });
    }

    this.handlePass = ( e ) => {
        let temporalRegisterModel = this.state.registerModel;
        temporalRegisterModel.password = e.target.value;

        // console.log( 'Password: ', temporalRegisterModel.password );

        this.setState({ registerModel: temporalRegisterModel });
    }
    this.handlePassConfirm = ( e ) => {
        let temporalRegisterModel = this.state.registerModel;
        temporalRegisterModel.cpassword = e.target.value;

        // console.log( 'ConfPassword: ', temporalRegisterModel.cpassword );

        this.setState({ registerModel: temporalRegisterModel });
    }

    /**
     * HAndle checkboxes in form
     */
    this.handleOldEnough = (e) => {
      let temporalRegisterModel = this.state.registerModel;
      temporalRegisterModel.oldenough = !temporalRegisterModel.oldenough;

      // console.log( 'Oldenough: ', temporalRegisterModel.oldenough );

      this.setState({ registerModel: temporalRegisterModel,  canSubmit: temporalRegisterModel.oldenough != false});
    }
    this.handleNewsLetter = (e) => {
      let temporalRegisterModel = this.state.registerModel;
      temporalRegisterModel.newsletter = !temporalRegisterModel.newsletter;

      // console.log( 'Newsletter: ', temporalRegisterModel.newsletter );

      this.setState({ registerModel: temporalRegisterModel });
    }

    this.handleTOS = (e) => {
      let temporalRegisterModel = this.state.registerModel;
      temporalRegisterModel.tos = !temporalRegisterModel.tos;

      // console.log( 'TOS: ', temporalRegisterModel.tos );

      this.setState({ registerModel: temporalRegisterModel , canSubmit: temporalRegisterModel.tos != false });
    }

    this.resetErrors = () => {
      this.setState({
        mailError          : false,
        mailErrorType      : null,
        mailErrorLabel     : null,
        nickError          : false,
        nickErrorType      : null,
        nickErrorLabel     : null,
        defaultError       : false,
        defaultErrorType   : null,
        defaultErrorLabel  : null
      })
    }

    this.sendRegister = () => {
      let temporalRegisterModel = this.state.registerModel;

      temporalRegisterModel.fingerprint = utils.getFingerprint();

      this.setState({	submitting: true , registerModel: temporalRegisterModel }, () => { this.resetErrors(); });

			console.log('Send register', temporalRegisterModel  );
			API.postRegister( temporalRegisterModel  )
				.then( response => {
          console.log(response);
					this.callbackRegister( response.data  );
				} )
				.catch( error => {
					console.error('error', error )
				}  );
		}

		this.callbackRegister = ( data ) => {
			console.log('%c Callback Register', data );
      console.log(data);
			if( data && data.success ){

				if(  this.props.type == 'popup' ){
					console.log('Registration in Popup');

					this.setState({
						submitting:false,
					}, () => {
						//Update user info
						this.props.hideRegister();
					});
				}
        this.setState({
          submitting:false,
          registerSuccess: true,
        });
			}
			else {
				this.handleErrors( data );
			}
		}
		/**
		 * This method handles the error if the request of being registered isnt success
		 * @params data { Object }
		 * return { Change of state }
		 */
		this.handleErrors = ( data  ) => {
			console.log( '%c Handle Errors', data );

        const errors = data.errors;
        const errorsKeys = Object.keys(errors);
        console.log(errors, errorsKeys);
        errorsKeys.forEach(err => {
          console.error(err, errors[err]);
          switch (err) {
            case 'nick':
              const errorTypeNick = Object.keys(errors[err]);
              console.log(errors[err][errorTypeNick[0]]);
              this.setState({
                submitting: false,
                registroSuccess: false,
                nickError: true,
                correctNick: false,
                nickErrorType: errorTypeNick[0],
                nickErrorLabel: errors[err][errorTypeNick[0]]
              });
              break;
            case 'email':
              const errorTypeMail = Object.keys(errors[err]);
              console.log(errors[err][errorTypeMail[0]]);
              this.setState({
                submitting: false,
                registroSuccess: false,
                correctMail: false,
                mailError: true,
                mailErrorType: errorTypeMail[0],
                mailErrorLabel: errors[err][errorTypeMail[0]]
              });
							break;
						default:
							const errorTypeDefault = Object.keys(errors[err]);
							console.log('default error');
              this.setState({
                submitting: false,
                registroSuccess: false,
                correctMail: false,
                defaultError: true,
								defaultErrorType: null,
								defaultErrorType: errorTypeDefault[0],
                defaultErrorLabel: errors[err][errorTypeDefault[0]]
              });
							break;
          }
        });
		}

    this.facebookClicked = ( e ) => {
      this.setState({
        fbPop: true,
      })
		}

		this.handleViewPass = () => {
			this.setState({
				viewPass: !this.state.viewPass
			})
		}

		this.handleViewPassConf = () => {
			this.setState({
				viewPassConf: !this.state.viewPassConf
			})
		}
  }

    disableButton() {
      // console.log('disable button');
      this.setState({ canSubmit: false });
    }

    enableButton() {
      console.log('Enable Button')
      this.setState({ canSubmit: this.state.registerModel.tos && this.state.registerModel.oldenough });
    }

    resetData(){

    }

    componentDidMount(){
      console.log('Mounted', utils.getFingerprint() );
      let temporalRegisterModel = this.state.registerModel;
      temporalRegisterModel.fingerprint = utils.getFingerprint();
      this.setState({ fingerprint: utils.getFingerprint() , registerModel: temporalRegisterModel})
    }

    render(){
      if( this.state.registerSuccess ){
        console.log('registerSuccess');
        return (
          <Redirect
            exact
            push
            to={{
              pathname:'/'
            }}
          />
        )
      }
      else {
        const  prevForm = (
          <Formsy
            className='flex-col col-90 col-offset-5'
            onValidSubmit={ e => { this.sendRegister( e ); } }
            onValid={ this.enableButton }
            onInvalid={ this.disableButton }
          >
          <div className='flex-row responsive-sm '>
            <div className='flex-col col-50 col-no-padding'>
              <RegisterInput
                inputHandler={ this.handleName }
                label='Nombre'
                name='name'
                required
                type='NAME'
                validations='isWords'
                validationError='Solo puedes ingresar letras'
              />
            </div>
            <div className='flex-col col-50 col-no-padding'>
              <RegisterInput
                inputHandler={ this.handleLastName }
                label='Apellidos'
                name='lasName'
                required
                type='NAME'
                validations='isWords'
                validationError='Solo puedes ingresar letras'
              />
            </div>
          </div>
          <div  className='flex-row responsive-sm'>
            <div className='flex-col col-50 col-no-padding'>
                <RegisterInput
                  showError={ this.state.mailError === undefined ? false : this.state.mailError }
                  errorLabel={ this.state.mailErrorLabel }
                  errorType={ this.state.mailErrortype }
                  inputHandler={ this.handleMail }
                  label='Mail'
                  name='email'
                  required
                  type='MAIL'
                  validations='isEmail'
                  validationError='Utiliza un email válido.'
                />
            </div>
            <div className='flex-col col-50 col-no-padding'>
              <RegisterInput
                inputHandler={ this.handleNick }
                label='Apodo'
                name='nick'
                required
                max='12'
                type='NICK'
                showError={ this.state.nickError === undefined ? false : this.state.nickError }
                errorLabel={ this.state.nickErrorLabel }
                errorType={ this.state.nickErrortype }
                validations='maxLength:12'
                validationError='Tu nick no puede tener mas de 12 caractéres'
                />
            </div>
          </div>
          <div  className='flex-row responsive-sm'>
            <div className='flex-col col-50 col-no-padding'>
              <RegisterInput
                inputHandler={ this.handlePass }
                label='Contraseña'
                name='password'
                required
                viewPass={ this.state.viewPass }
                handleViewPass={ this.handleViewPass }
                min='6'
                type='PASSWORD'
                validations='minLength:6'
                validationError='Tu contraseña debe de tener al menos 6 carácteres'
              />
            </div>
            <div className='flex-col col-50 col-no-padding'>
              <RegisterInput
                inputHandler={ this.handlePassConfirm }
                label='Confirma tu contraseña'
                name='confirmaContraseña'
                required
                viewPass={ this.state.viewPassConf }
                handleViewPass={ this.handleViewPassConf }
                type='PASSWORD'
                validations='equalsField:password'
                validationError='Tus contraseñas no son iguales'
              />
            </div>
          </div>
          <div className='flex-row responsive-sm'>
            <div className='flex-col col-50 col-no-padding'>
              <RegisterInput
                inputHandler={ this.handlePhone }
                label='Teléfono'
                name='phone'
                type='PHONE'
                minLength='10'
                validations='minLength:10'
                required
                validationError='Tu tenéfono debe de contener al menos 10 números'
              />
            </div>
          </div>
          <div  className='flex-row responsive-sm'>
            <div className='flex-col col-no-padding'>
              <RegisterInput
                checkboxValue={ this.state.registerModel.tos }
                inputHandler={ this.handleTOS }
                label='Acepto términos y condiciones'
                required
                name='tos'
                type='CHECKBOX'
              />
            </div>
          </div>
          <div  className='flex-row responsive-sm'>
            <div className='flex-col col-no-padding'>
              <RegisterInput
                checkboxValue={ this.state.registerModel.oldenough }
                inputHandler={ this.handleOldEnough }
                label='Certifico que tengo al menos 18 años de edad'
                required
                name='oldenough'
                type='CHECKBOX'
              />
          </div>
        </div>
        <div  className='flex-row responsive-sm'>
          <div className='flex-col col-no-padding'>
            <RegisterInput
              checkboxValue={ this.state.registerModel.newsletter }
              inputHandler={ this.handleNewsLetter }
              label='Acepto que Pickwin me envíe avisos y comunicados por correo electrónico'
              name='newsletter'
              type='CHECKBOX'
            />
        </div>
      </div>
      <ErrorField
        showError={this.state.defaultError === undefined ? false : this.state.defaultError }
        errorLabel={ this.state.defaultErrorLabel }
        errorType={ this.state.defaultErrortype }
      />
        <div className='flex-row row-center'>
          <Button
            onClick={ (e) => { this.sendRegister(); }}
            className='btn-pick btn flex-col col-no-padding btn-pickwin text-bold'
            disabled={ !this.state.canSubmit || this.state.submitting }
            style={{padding: '5px 0'}}
            >
            Completa tu registro
          </Button>
        </div>
        {/*<FacebookLogin
          appId="973176056074364"
          textButton="Regístrate con Facebook"
          icon="fa-facebook"
          autoLoad={false}
          fields="id,name,first_name,last_name,gender,installed,verified,email,picture.type(large)"
          scope="email,public_profile,publish_actions"
          cssClass="my-facebook-button-class btn flex-col"
          disableMobileRedirect={true}
          onClick={this.facebookClicked}
          callback={this.responseFacebook}
        />*/}
      </Formsy>
      )
      return prevForm
      }
  }
}

export default RegisterForm;
