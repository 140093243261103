import React from 'react';
import { Button, Row, Col, Form, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import api from '../../utils/api';


const Support = () => {
  const [helpType, setHelpType] = React.useState('pagos');
  const [helpMessage, setHelpMessage] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  /**
   *
   * @param {event} e
   */
  const requestSupport = (e) => {
    e.preventDefault();
    setSubmitting(true);
    api.requestSupport({
      'type': helpType,
      'message': helpMessage,
    })
    .then((response) => {
      document.querySelector('#request-support-form').reset();
      setHelpType('pagos');
      setHelpMessage('');
      setSubmitting(false);
      alert('Solicitud enviada');
    });

  };

  return (
    <>
      <div className="flex-row">
        <div className="flex-col">
          <h3>¿Cómo podemos ayudarte?</h3>
          <p>Llena el siguiente formulario para abrir un ticket de soporte</p>
        </div>
      </div>
      <Form horizontal onSubmit={requestSupport} id="request-support-form">
        <FormGroup>
          <Col componentClass={ControlLabel} md={3}>
            Necesito ayuda con:
          </Col>

          <Col md={6}>
            <FormControl componentClass="select" placeholder="select" required
              onChange={(e) => setHelpType(e.target.value) }
            >
              <option value="pagos">Pagos</option>
              <option value="materiales">Materiales</option>
              <option value="enlaces">Enlaces</option>
              <option value="reportes">Reportes</option>
              <option value="cuenta">Cuenta</option>
              <option value="otros">Otro</option>
            </FormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} md={3}>
            Mensaje:
          </Col>
          <Col md={6}>
            <FormControl componentClass="textarea" rows="6" placeholder="Escribe tus dudas o comentarios" required
              onChange={(e) => setHelpMessage(e.target.value) }
            ></FormControl>
          </Col>
        </FormGroup>
        <Row>
          <Col md={9} className="text-right">
            <Button
              type="submit"
              bsStyle="pickwin"
              disabled={submitting}
            >
                 {submitting ? 'Enviando...' : 'Enviar'}
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="flex-row">
        <div className="flex-col">
          <h5>
            <a href="https://wa.me/+5215561988150?text=Necesito ayuda" target="_blank">Si es urgente puedes escribirnos por WhatsApp <i className="ion-social-whatsapp-outline"></i></a>
          </h5>
        </div>
      </div>
    </>


  );
};

export default Support;