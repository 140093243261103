import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  Link,
} from "react-router-dom";

import utils from "../../utils/utils";
import PageWrapper from "../PageWrapper";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Materiales from "../Materiales";
import Affiliates from "../Affiliates";
import RecuperarPass from "../RecuperarPass";
import Enlaces from "../Enlaces";
import Login from "../Login";
import Support from "../Support";
import AccountDetails from "../AccountDetails";
import { ContentTerminos } from "../Terminos/Terminos";
import Payments from "../Payments";


const SideBar = () => {
  return (
    <div className="col-sm-3 col-md-2 sidebar">
      <Nav bsStyle="pills" bsSize="sm" stacked>
        <LinkContainer to="/reportes/principal">
          <NavItem>
            <i className="ion-android-home"></i> Inicio
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/reportes/usuarios">
          <NavItem>
            <i className="ion-pie-graph"></i> Reportes
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/pagos">
          <NavItem>
            <i className="ion-card"></i> Pagos
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/enlaces">
          <NavItem>
            <i className="ion-link"></i> Enlaces
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/materiales">
          <NavItem>
            <i className="ion-android-image"></i> Materiales
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/detalles-cuenta">
          <NavItem>
            <i className="ion-android-person"></i> Cuenta
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/informacion">
          <NavItem>
            <i className="ion-ios-information"></i> Información
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/support">
          <NavItem>
            <i className="ion-ios-telephone"></i> Soporte
          </NavItem>
        </LinkContainer>
      </Nav>
    </div>
  );
};

const App = ({history}) => {
  const isLogged = utils.isLogged();
  console.log("App isLogged", isLogged);
  if (!isLogged) {
    return (
      <Router>
        <Switch>
          <Route exact path="/ingresar" component={Login} />
          <Route exact path="/password_perdido" component={RecuperarPass} />
        </Switch>
      </Router>
    );
  } else {
    console.log("not routing");
    return (
      <Router>
        <PageWrapper>
          <div className="container-fluid">
            <div className="flex-row">
              <SideBar />
              <div className="col-sm-8 col-sm-offset-3 col-md-10 col-md-offset-2 main mt-4" style={{width: '100%'}}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/reportes/principal" />}
                  />
                  <Route path="/reportes/:tipo" component={Affiliates} />
                  <Route path="/pagos" component={Payments} />
                  <Route exact path="/enlaces" component={Enlaces} />
                  <Route exact path="/materiales" component={Materiales} />
                  <Route exact path="/detalles-cuenta" component={AccountDetails} />
                  <Route exact path="/informacion" component={ContentTerminos} />
                  <Route exact path="/support" component={Support} />
                </Switch>
              </div>
            </div>
          </div>
        </PageWrapper>
      </Router>
    );
  }
};

export default App;
