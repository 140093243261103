import { withFormsy } from 'formsy-react';
import React from 'react';
import {FormControl, FormGroup} from 'react-bootstrap';
import  { Button } from 'react-bootstrap/lib';

class LoginInput extends React.Component{
  constructor(props){
    super(props);
    this.changeValue = this.changeValue.bind(this);
    // console.log('LoginInput')
    // console.log(this);
  }
  changeValue(event) {
    if(event.currentTarget.value === ' ' ){return}
    this.props.setValue(event.currentTarget.value);
  }
  render() {
    const errorMessage = this.props.getErrorMessage();
    let conditionalInput = null;
    switch ( this.props.type ) {
    case 'PASS':
      conditionalInput = (
        <FormGroup controlId={this.props.name}>
          <FormControl
            type={this.props.viewPass ? 'text' : 'password'}
            placeholder={ this.props.label }
            minLength={ this.props.minLength }
            maxLength={ this.props.maxLength }
            value={ this.props.getValue() || '' }
            onChange={ (e) => {this.changeValue(e); this.props.inputHandler(e);} }/>
            <span className='error-formulario'>{ errorMessage }</span>
        </FormGroup>
      )
      break;
    case 'NICK':
      conditionalInput = (
        <FormGroup controlId={this.props.name}>
          <FormControl
            type={'text'}
            placeholder={this.props.label}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            value={this.props.getValue() || ''}
            onChange={(e) => {this.changeValue(e); this.props.inputHandler(e);}}/>
          <span className='error-formulario'>{errorMessage}</span>
        </FormGroup>
      )
      break;
    }

    return conditionalInput;
  }
}

export default withFormsy(LoginInput);
