//Libraries
import React from 'react';

//Components
import PageWrapper from '../PageWrapper';
import LoginPanel from '../LoginPanel'
import RegistrarPopUp from '../RegistrarPopUp';
import utils from '../../utils/utils'
import NewLogin from './NewLogin';

class Login extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			mounted			: false,
			loggedIn		: null,
			userId			: null,
			userData		: null,
			showRegister: false ,
			from				: { pathname:'/'  }
		}
		this.loggedInCorrect = ( status ) => {
			this.setState({
				loggedIn: status
			})
		}
		this.handleRegisterPop = () => {
			this.setState({   showRegister: !this.state.showRegister })
		}

	}

	componentDidMount(){
		let userData = utils.getUserInfo();

		this.setState({
			from			: this.props.location.state ? this.props.location.state.form : { pathname:'/' },
			mounted		:true,
			userData	: userData,
			userId		: userData ? userData.id : null
		})
	}

	render(){		;
		let conditionalComponent = null;

		if( this.state.userData  ){
			window.location = '/';
		}

		if(this.state.loggedIn){
			let notLoggedData = JSON.parse( utils.getDataNotLoggedUser() );

			if( !notLoggedData ){
				conditionalComponent = (
					<Redirect
						to={{
							pathname	: this.state.form.pathname,
							state			: this.state
						}}
					/>
				)
			}
			else {
				conditionalComponent = (
					<Redirect
						to={{
							pathname: notLoggedData.pathname
						}}
					/>
				)
			}

		}
		else {
			conditionalComponent=(
				<React.Fragment>
          <RegistrarPopUp
            showRegisterPopup={this.state.showRegister}
            hideRegister={this.handleRegisterPop}
            updateUserInfo={this.props.updateUserInfo}
            userData={ utils.getUserInfo() }
          />
					<LoginPanel
						fromPath={ this.state.from ? this.state.from.pathname : null }
	          showRegisterPopup={this.handleRegisterPop}
	          loggedInCorrect={this.loggedInCorrect}
					/>
			</React.Fragment>
			)
		}


		return (
      <>
        {this.props.location.pathname === '/ingresar' ?
          <NewLogin
            updateUserInfo={this.props.updateUserInfo}
            userData={ utils.getUserInfo() }
          />
        :
          <PageWrapper>
            { conditionalComponent }
          </PageWrapper>
        }
      </>
			)
	}
}

export default Login;
