import { withFormsy } from 'formsy-react';
import React from 'react';
import { FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import { Button } from 'react-bootstrap/lib';

class MailInviteInput extends React.Component{
  constructor(props){
    super(props);
    this.changeValue = this.changeValue.bind(this);
    // console.log('MailInviteInput')
    // console.log(this);
  }
  changeValue(event) {
    console.log( event )
    if(event.currentTarget.value === ' ' ){return}
    this.props.setValue(event.currentTarget.value);
  }
  render() {
    const errorMessage = this.props.getErrorMessage();
    let conditionalInput = null;
    switch ( this.props.type ) {
    case 'MAIL':
        conditionalInput = (
          <div className='flex-row row-center'>
            <FormGroup controlId={this.props.name} className='flex-col col-no-padding'>
            <ControlLabel>{ this.props.label }</ControlLabel>
              <FormControl
                type={'email'}
                onBlur={(e) => {this.changeValue(e); this.props.inputHandler(e);}}
                placeholder={this.props.label}
                value={this.props.getValue() || this.props.defaultValue }
                onChange={(e) => {this.changeValue(e); this.props.inputHandler(e);}}/>
              <span className='error-formulario'>{errorMessage}</span>
            </FormGroup>
          </div>
          );
          break;
    case 'NICK':
      conditionalInput = (
        <div className='flex-row row-center'>
          <FormGroup controlId={this.props.name} className='flex-col col-no-padding'>
          <ControlLabel>{ this.props.label }</ControlLabel>
            <FormControl
              onBlur={ ( e ) => { this.changeValue( e ); this.props.inputHandler( e ); } }
              type={'text'}
              placeholder={this.props.label}
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              value={this.props.getValue() || this.props.defaultValue }
              onChange={(e) => {this.changeValue(e); this.props.inputHandler(e);}}/>
            <span className='error-formulario'>{errorMessage}</span>
          </FormGroup>
        </div>
     );
      break;
    case 'MESSAGE':
      conditionalInput = (
        <div className='flex-row row-center'>
          <FormGroup controlId={this.props.name} className='flex-col col-no-padding text-message'>
          <ControlLabel>{ this.props.label }</ControlLabel>
            <FormControl
              onBlur={(e) => {this.changeValue( e ); this.props.inputHandler( e );}}
              componentClass="textarea"
              placeholder={this.props.label}
              value={this.props.getValue() || this.props.defaultValue }
              onChange={(e) => {this.changeValue( e ); this.props.inputHandler( e );}}/>
            <span className='error-formulario'>{errorMessage}</span>
          </FormGroup>
        </div>
     );
      break;

    }

    return conditionalInput;
  }
}

export default withFormsy(MailInviteInput);
