import React from 'react';
import api from '../../utils/api';
import IframeResizer from 'iframe-resizer-react'
import { Row, Tab, Tabs } from 'react-bootstrap';
import Loading from '../Loading';

const Affiliates = ({match, location, history, ...rest}) => {

  const [tipo,   setTipo]   = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [urls,   setUrls]   = React.useState(null);

  React.useEffect(() => {
    setTipo(match.params.tipo);
  }, [match.params.tipo])

  React.useEffect(() => {
    api.getDashUrl()
    .then((response) => {

      const data = response.data;
      setUrls(data.urls);
      setLoaded(true);

    });
  }, []);

  if ( !loaded ) {
    return <Loading/>
  }

  let component;
  switch( tipo ) {
    case 'principal':
      component = (
        <div className="flex-row">
          <IframeResizer
            src={urls.mainUrl}  style={{ width: '1px', minWidth: '100%', minHeight: '1224px', border: 0}}
            scrolling
            heightCalculationMethod="grow"
          />
        </div>
      );
      break;
    case 'usuarios':
      component = (
        <div className="flex-row">
          <div className="flex-col">
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" bsStyle="pills" justified>
              <Tab eventKey="home" title="Ingresos" bsClass="default" style={{color: 'red'}}>
                <IframeResizer
                  src={urls.incomeUrl}
                  style={{ width: '1px', minWidth: '100%', minHeight: '1224px', border: 0}}
                  scrolling
                  heightCalculationMethod="grow"
                />
              </Tab>
              <Tab eventKey="users" title="Usuarios" bsClass="danger font-chivo">
                <IframeResizer
                  src={urls.usersUrl}
                  style={{ width: '1px', minWidth: '100%', minHeight: '1224px', border: 0}}
                  scrolling
                  heightCalculationMethod="grow"
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      );
      break;
    default:
      component = (
        <div className="flex-row"></div>
      );
      break
  }

  return component;
};

export default Affiliates;