import React from 'react';
import API from '../../utils/api';
import NavigationBar from '../NavigationBar';
import {Route, Redirect, Link, History} from 'react-router-dom';
import RecuperarPanel from '../RecuperarPanel';

import utils from '../../utils/utils.js';

class RecuperarPass extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      loggedIn:false,
      mounted:false,
      userData: null,
      userId:null,
      userFullData:null,
    }
    this.updateUserData = this.updateUserData.bind(this);
  }

  updateUserData(userD){
    this.setState({
      loggedIn:userD.data.success,
      userFullData:userD.data,
      userId:userD.data.user.id,
    });
    console.log(this.state.userFullData);
  }

  componentDidMount() {
    let userData = utils.getUserInfo();
    this.setState(() => {
      return {
        mounted: true,
        userData: userData,
      }
    });

  }

  render() {
    let match = this.props.match;
    let loggedIn = false;
    let mounted = false;
    let conditionalComponent;
    if( this.state.mounted && this.state.userFullData != null ){
       console.log('mounted and logged');
      mounted = true;
      loggedIn = true;
      conditionalComponent = (
        <Redirect
          to={{
            pathname:'/',
            search: '?userId='+this.state.userFullData.user.id,
            state:  this.state,
          }}/>
      );
    } else if( this.state.mounted ){
      console.log('mounted ',this.state.mounted);
      mounted = true;
      loggedIn = false;
      conditionalComponent = (
        <div>
        <NavigationBar isLogged={loggedIn} userData={this.state.userData} />
        <RecuperarPanel updateUserDataFunc={this.updateUserData} />
      </div>
    )
    } else if(!this.state.mounted) {
      console.log('loading');
      conditionalComponent = (<div>
        <NavigationBar isLogged={this.props.isLogged} userData={this.state.userData} />
        <div id='loading'>
          Cargando...
        </div>
      </div>)
    }
    return (
      conditionalComponent
    )
  }
}

export default RecuperarPass;
