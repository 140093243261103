import React from 'react';
import pickLoader from 'images/qunielasIconVerde-50.png'

const Loading = (props) =>(
    <div id="loading">
        <span className="circuloExterno">
            <span className="circuloInterno">
            </span>
        </span>
      {/*<span>*/}
        {/*<img className='loading-animation' src={pickLoader}/>*/}
      {/*</span>*/}
      <span className="label-load">
        <span>{props.label}</span>
      </span>
    </div>
  );

export default Loading;
