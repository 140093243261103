import React from 'react';
import Cookies from 'js-cookie';
import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  FormControl
} from 'react-bootstrap/lib/';
import pickwinLogo from 'images/pickwin-winner-100.png';
import mailIcon from 'images/mensajesIconBlanco-50.png';
import passwordIcon from 'images/password.png';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Redirect
} from 'react-router-dom';
import API from '../../utils/api';

import { detectDevice } from '../../utils/utils';

import FacebookLogin from 'react-facebook-login';

import { DescargarAppBanner } from '../RegisterFormPanel';

import  FormularioLogin  from '../FormularioLogin';

import utils from '../../utils/utils';


class LoginPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData        : null,
      username        : '',
      password        : '',
      fingerprint     : '',
      failed          : null,
      fbFailed        : null,
      fbData          : null,
      fbPop           : null,
      submitting      : false,
      errorIngresar   : null,
      loginState      : null,
      viewPass        : false,
    };
    this.handleViewPass     = this.handleViewPass.bind(this);
    this.handleUsername     = this.handleUsername.bind(this);
    this.handlePassword     = this.handlePassword.bind(this);
    this.componentClicked   = this.componentClicked.bind(this);
    this.sendCredentials    = this.sendCredentials.bind(this);
    this.responseFacebook   = this.responseFacebook.bind(this);
    this.initFingerprint    = utils.initFingerprint.bind(this);

  }

  componentDidMount() {
    if( window.requestIdleCallback ){
      requestIdleCallback( ( ) => {
         console.log('requestIdleCallback');
        this.setState({
          fingerprint:this.initFingerprint()
        })
      });

    }
    else {
      setTimeout( () => {
        console.log('requestIdleCallback Didnt exist');
        this.setState({
          fingerprint:this.initFingerprint()
        })
      }, 500)
    }

  }

  responseFacebook(fbResponse) {
    if (
      !fbResponse.hasOwnProperty('userID') &&
      !fbResponse.hasOwnProperty('accessToken')
    ) {
    } else {
      this.setState({ submitting: true });
      API.postFacebookLogin(fbResponse.userID, fbResponse.accessToken)
        .then(response => {
          const data = response.data;

          if (this.props.type == 'popup') {
            console.log('Login Facebook in register Popup f');
            this.setState(
              {
                failed: false,
                loginState: true,
                errorIngresar: false,
                registerSuccess: true,
                submitting: false
              },
              () => {
                if (!this.props.updateUserInfo) {
                  setTimeout(() => {
                    // window.location.assign('/');
                  }, 1000);
                } else {
                  this.props.updateUserInfo(data);
                  this.props.hideLogin();
                }
              }
            );
          } else {
            console.log('Facebook login in path ingresar');
            this.setState({
              redirectToLobby: true,
              registerSuccess: true,
              submitting: false,
              userData: response,
              failed: false,
              loginState: true,
              errorIngresar: false
            });
            utils.setUserInfo(data);
            // window.location.assign('/');
          }
        })
        .catch(error => {
          console.log(error);
          // utils.catchErrorRaven(
          //   error,
          //   utils.getUserInfo(),
          //   'Error trying to Login with Facebook',
          //   fbResponse
          // );
          this.setState({
            fbFailed: true,
            // loginState:false,
            fbData: fbResponse,
            fbDataLogin: error
          });
          // console.log('Logged in Failed',error);
        });
    }
  }

  componentClicked(e) {
    // console.log(e);
    this.setState({
      fbPop: true
    });
  }

  sendCredentials(event) {
    let that = this;
    console.log('sending credentials');
    this.setState({ submitting: true });
    // credentials are correct?
    // if they are correct send to the lobby
    // if they arent send an alert or message
    let dataObject = {
      email           : this.state.username,
      password        : this.state.password,
      fingerprint     : this.state.fingerprint
    };

    API.postLoginData(  dataObject )
      .then(response => {
        const responses = [ response ];

        try{
          utils.checkAxiosAllErrors( responses );
        }
        catch(e){
          console.error(e);
          // Sentry.captureException( e );
          throw ('Error en passwword o usuario');
        }
        // console.log('Response',response);
        if (response && response.data.success) {
          console.log('Logged In Success',response);
          that.setState(() => {
            return {
              submitting: false,
              userData: response,
              failed: false,
              loginState: true,
              errorIngresar: false
            };
          });
          // console.log(that);
          if (that.props.type == 'popup') {
            console.log('POPUP');
            if (!this.props.updateUserInfo) {
              setTimeout(() => {
                this.props.hideLogin();
                window.location.assign('/reportes/principal');
              }, 1000);
            } else {
              this.props.hideLogin();
            }
          }
          else {
            setTimeout(() => {
              window.location.assign('/reportes/principal');
            }, 800);
          }
        }
        else {
          console.log('Logged In Error', response);
          that.setState({
            submitting: false,
            errorIngresar: true,
            failed: true,
            loginState: false
          }, () => { throw 'Error en Login al Ingresar ' });
        }
      })
      .catch(error => {
        console.error(error);
        // Sentry.captureException( error );
        this.setState({
          submitting: false,
          errorIngresar: true,
          failed: true,
          loginState: false
        });
      });
  }

  handleUsername(e) {
    // console.log(e.target.value);
    let usrName = String(e.target.value);
    usrName = usrName.trim();

    //if( usrName === '' ){ return ;}
    this.setState({
      username: usrName,
      failed: null
    });
  }

  handlePassword(e) {
    let password = String(e.target.value);
    password = password.trim();
    //if( password === '' ){ return ;}
    this.setState({
      password: password
    });
  }

  handleViewPass(){
    this.setState( {
      viewPass: !this.state.viewPass,
    }, () => {
      console.log('handleViewPass', this.state.viewPass );
    } )
  }

  render() {
    // let loggedIn = false;
    let conditionalComponent;
    if (this.state.loginState != null) {
      console.log('Tried');
      // Tried to login
      // console.log(this.state);
      let notLoggedData = null;
      if (this.state.loginState) {
        // console.log('notLoggedData', notLoggedData);
        if (!notLoggedData) {
          if (this.props.type == 'popup') {
            console.log('PopUp');
            // this.props.updateUserInfo( this.state.userData.data );
            conditionalComponent = (
              <div id="loginPanel">
                <div
                  id="panelTitle"
                  className="row-center flex-row row-no-padding loginTitle">
                  <span className="flex-col"> Te estamos redirigiendo</span>
                </div>
                <div className="login-fields responsive-sm">
                  <span className="login-message flex-container column justify align ">
                    <i className="ion-load-a loading" />
                  </span>
                </div>
              </div>
            );
          }
          else {
            let gameType = null;
            conditionalComponent = (
              <div>
                <Redirect
                  to={{
                    pathname: '/'
                  }}
                />
              </div>
            );
          }
        } else {
          let gameType = null;
          switch (notLoggedData.game_type) {
            case 'pool':
              gameType = 'quiniela';
              break;
            default:
              gameType = notLoggedData.game_type;
          }

          conditionalComponent = (
            <div>
              <Redirect
                to={{
                  pathname: `/${gameType}/${gameType}-invite/${notLoggedData.id}/${notLoggedData.entry_id ? notLoggedData.entry_id : ''}`
                }}
              />
            </div>
          );
        }

        // console.log('redirect', this.props.fromPath);
        //Login Success adn redirect
      } else {
        if (this.state.userData != null) {
          conditionalComponent = (
            <div>
              <Redirect
                to={{
                  pathname: this.props.fromPath
                }}
              />
            </div>
          );
        } else {
          // console.log('Not Tried yet');
          conditionalComponent = (
            <div id="loginPanel">
              <div
                id="panelTitle"
                className=" flex-row row-center row-no-padding failed">
                <span className="flex-col">
                  ERROR AL INGRESAR, POR FAVOR INTÉTANLO DE NUEVO
                </span>
                {this.props.type && this.props.type == 'popup' ? (
                  <div className="flex-col col-15">
                    <button
                      className="cerrar-panel"
                      onClick={this.props.hideLogin}>
                      <div className="btn-icon-close">
                        <i className="ion-ios-close-empty" />
                      </div>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="login-fields repsonsive-sm">
                <FormularioLogin
                  componentClicked={this.componentClicked}
                  errorIngresar={this.state.errorIngresar}
                  handlePassword={this.handlePassword}
                  handleUserName={this.handleUsername}
                  handleViewPass={ this.handleViewPass }
                  hideLogin={this.props.hideLogin}
                  nick={this.state.username}
                  password={this.state.password}
                  responseFacebook={this.responseFacebook}
                  sendCredentials={this.sendCredentials}
                  showRegisterPopup={this.props.showRegisterPopup}
                  submitting={this.state.submitting}
                  viewPass={this.state.viewPass}
                />
              </div>
            </div>
          );
        }
      }
    } else {
      //havent tried to login
      if (this.state.fbFailed) {
        console.log('FBFAILED');

        let fingerPrint = utils.getFingerprint();
        console.log('fingerPrint', fingerPrint);
        conditionalComponent = (
          // <RegistroFB
          //   type={this.props.type}
          //   fingerPrint={fingerPrint}
          //   hideRegister={this.props.hideLogin}
          //   formReferall={utils.getReferer()}
          //   updateUserInfo={this.props.updateUserInfo}
          //   fbData={this.state.fbData}
          // />
          <div>Facebook</div>
        );
      } else if (this.state.fbFailed === null) {
         console.log('Default Login');
        conditionalComponent = (
          <div id="loginPanel">
            <div id="panelTitle" className=" flex-row row-center row-no-padding">
              <div className="container">
                <span className="flex-col text-center font-chivo">INGRESAR</span>
                {this.props.type && this.props.type == 'popup' ? (
                  <div className="flex-col col-10">
                    <button className="cerrar-panel" onClick={this.props.hideLogin}>
                      <div className="btn-icon-close">
                        <i className="ion-ios-close-empty" />
                      </div>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="login-fields">
              <FormularioLogin
                componentClicked={this.componentClicked}
                errorIngresar={this.state.errorIngresar}
                handlePassword={this.handlePassword}
                handleUserName={this.handleUsername}
                handleViewPass={ this.handleViewPass }
                hideLogin={this.props.hideLogin}
                nick={this.state.username}
                password={this.state.password}
                responseFacebook={this.responseFacebook}
                sendCredentials={this.sendCredentials}
                showRegisterPopup={this.props.showRegisterPopup}
                submitting={this.state.submitting}
                viewPass={this.state.viewPass}
              />
            </div>
          </div>
        );
      }
    }
    return conditionalComponent;
  }
}
export default LoginPanel;
