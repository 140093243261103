import React from 'react';
import Formsy from 'formsy-react';
import API from '../../utils/api';
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  NavLink,
  HelpBlock} from 'react-bootstrap/lib';

import FacebookLogin from 'react-facebook-login';
import LoginInput from './LoginInput';


class FormularioLogin extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      submitting: false,
      failed: false,
      canSubmit: false,
    }
    this.disableButton                = this.disableButton.bind(this);
    this.enableButton                 = this.enableButton.bind(this);
    this.resetData                    = this.resetData.bind(this);
    // console.log('formLogin', this.props)
  }

  disableButton() {
    // console.log('disable button');
    this.setState({ canSubmit: false });
  }

  enableButton() {
    // console.log('Enable Button')
    this.setState({ canSubmit: true });
  }

  resetData(){

  }

  componentDidMount(){
    // console.log('Mounted');
  }

  render(){
  const prevForm = (
    <Formsy
			className='flex-col col-offset-5 col-90'
			onValidSubmit={ e => { this.props.sendCredentials(e); }}
      onValid={this.enableButton}
      onInvalid={this.disableButton}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <LoginInput
                inputHandler={this.props.handleUserName}
                label='Email'
                name="nick"
                required
                type='NICK'
                defaultValue={ this.props.nick }
                validations='isEmail'
                validationError="Ingresa un email válido."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <LoginInput
                inputHandler={ this.props.handlePassword }
                label='Contraseña'
                name="password"
                required
                defaultValue={ this.props.password }
                type='PASS'
                validationError="La contraseña es requerida."
                viewPass={ this.props.viewPass }
                handleViewPass={ this.props.handleViewPass }
              />
              {this.props.errorIngresar && this.props.errorIngresar !== null
                ? <div className="error-formulario">
                    <i className="ion-alert-circled"></i>Por favor revisa tus datos, e inténtalo nuevamente.
                  </div>
                : null
			        }
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <Button
                disabled={this.props.submitting || !this.state.canSubmit}
                onClick={this.props.sendCredentials}
                bsStyle="pickwin"
                block>
                <i className="ion-log-in"></i>
                Ingresar
              </Button>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-6 col-md-offset-3 flex-container">
              <a className="btn btn-pickwin btn-block" href='/password_perdido'>
                ¿Olvidaste tu contraseña?
              </a>
            </div>
          </div>
        </div>
    </Formsy>
  );
      return prevForm
  }
}

export default FormularioLogin;
