//Libraries
import React from 'react';
import MediaQuery from 'react-responsive';
import utils from '../../utils/utils';
import API from '../../utils/api';
import RegistrarPopUp from '../RegistrarPopUp';

//Images
import pickwinLogo from 'images/logo-pickwin.png';
import iconSelect from 'images/icon-select.png';
import iconUser from 'images/icon-user.png';
import iconWin from 'images/icon-win.png';
import twitterButton from 'images/twitterButton.png';
import facebookButton from 'images/fbButton.png';
import instagramButton from 'images/igButton.png';
import correoButton from 'images/mailButton.png';

const NewLogin = (props) => {
  const { userData, updateUserInfo } = props;
  const [state, setState] = React.useState({
    submitting: false,
    username: '',
    password: '',
    fingerprint: null,
    userData: null,
    showRegister: false,
    errorIngresar: false,
  });

  const sendCredentials = () => {
    console.log("STATE:", state);
    setState({ ...state,  submitting: true });
    let dataObject = {
      email           : state.username,
      password        : state.password,
      fingerprint     : state.fingerprint
    };

    API.postLoginData(dataObject)
      .then(response => {
        const responses = [ response ];
        try{
          utils.checkAxiosAllErrors( responses );
        }
        catch(e){
          console.error(e);
          throw ('Error en passwword o usuario');
        }
        if (response && response.data.success) {
          console.log('Logged In Success',response);
          setState({
            ...state,
            submitting: false,
            userData: response,
            failed: false,
            loginState: true,
            errorIngresar: false
          });
          window.location.assign('/reportes/principal');
        }
        else {
          console.log('Logged In Error', response);
          setState({
            ...state,
            submitting: false,
            errorIngresar: true,
            failed: true,
            loginState: false
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          submitting: false,
          errorIngresar: true,
          failed: true,
          loginState: false
        });
      });
  }

  React.useEffect(() => {
    setState({...state, fingerprint: utils.initFingerprint()});
  }, []);

  const handleUserName = (e) => {
    setState({...state, username: e.target.value});
  }

  const handlePassword = (e) => {
    setState({...state, password: e.target.value});
  }

  const handleRegisterPop = () => {
    setState({...state, showRegister: !state.showRegister});
  }

  return (
    <>
      <RegistrarPopUp
        showRegisterPopup={state.showRegister}
        hideRegister={handleRegisterPop}
        updateUserInfo={updateUserInfo}
        userData={ userData }
      />

      <div className='flex-container align between login-header'>
        <img src={pickwinLogo} />
        <div className='login-form'>
          <input type='text' name='nick' className='login-input' placeholder='Usuario' value={ state.username } onChange={ handleUserName } />
          <div className='flex-container column align center' style={{width: '100%'}}>
            <input type='password' name='password' className='login-input' placeholder='Contraseña' value={ state.password } onChange={ handlePassword } />
            <a href='/password_perdido'>¿Olvidaste tu contraseña?</a>
          </div>
          <div className='flex-container column align center' style={{width: '100%'}}>
            <button className='btn btn-sm btn-pickwin login-button' onClick={ sendCredentials }>Entrar</button>
            {state.errorIngresar &&
              <div className='login-error'>
                <i className="ion-alert-circled"></i> Por favor revisa tus datos, e inténtalo nuevamente.
              </div>
            }
          </div>
        </div>
      </div>

      <div className='login-information'>
        <div className="login-information-content">
          <div className='title font-chivo'>¡HAGAMOS EQUIPO!</div>
          <div className="subtitle font-chivo">Únete a nuestro programa de afiliados</div>
          <div className="information">
            Si estás interesado en formar parte del programa de afiliados llena el formulario y un representante de Pickwin se pondrá en contacto contigo.
          </div>
          <button className='btn btn-sm btn-pickwin register-button' onClick={ handleRegisterPop }>Ir al formulario</button>
        </div>
      </div>

      <div className='login-howto'>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center title font-chivo">
              ¿CÓMO FUNCIONA?
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center item">
              <img src={iconSelect} className='' alt='icon select' />
              <div className='icon-text'>
                Escoge un modelo de pago
              </div>
            </div>
            <div className="col-md-4 text-center item">
              <img src={iconUser} className='' alt='icon select' />
              <div className='icon-text'>
                Refiere a nuevos usuarios
              </div>
            </div>
            <div className="col-md-4 text-center item">
              <img src={iconWin} className='' alt='icon select' />
              <div className='icon-text'>
                ¡Gana dinero!
              </div>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-md-12 text-center">
              <button className='btn btn-sm btn-pickwin information-button'>Más información</button>
            </div>
          </div>
        </div>
      </div>

      <div className='login-footer'>
        <MediaQuery maxWidth={768}>
          <div className="text-center">
            <img src={pickwinLogo} style={{width: '80px', height: 'auto', marginBottom: '1rem'}} />
          </div>
          <div className="flex-container align around" style={{margin: '1rem 0'}}>
            <a target='_blank' href="https://www.facebook.com/pickwin.mx">
              <img src={facebookButton} className="img-responsive navbar-icons social" width="30" height="auto"/>
            </a>
            <a target='_blank' href="https://twitter.com/PickwinMX">
              <img src={twitterButton} className="img-responsive navbar-icons social" width="30" height="auto" />
            </a>
            <a target='_blank'  href='https://instagram.com/pickwin.mx'>
              <img src={instagramButton} className="img-responsive navbar-icons social" width="30" height="auto" />
            </a>
            <a href="mailto:hola@pickwin.net">
              <img src={correoButton} className="img-responsive navbar-icons social" width="30" height="auto"/>
            </a>
          </div>
          <div className="flex-container align justify text-center menu-mobile">
            <a href='https://www.pickwin.mx/legal/terminos' target='_blank'>Términos y condiciones</a>
            <div className='menu-separator'></div>
            <a href='https://www.pickwin.mx/legal/privacidad' target='_blank'>Aviso de privacidad</a>
            <div className='menu-separator'></div>
            <a href='https://www.pickwin.mx/legal/legales' target='_blank'>Legales</a>
          </div>
          <div className='text'>
            www.pickwin.mx autorizada mediante el oficio de la Dirección de Juegos y Sorteos de la dirección de control y regulación de centros de apuestas remotas y salas de sorteos mediante el oficio no. DGJS/DCRCA/1584/2019. Es operada en méxico por la permisionaria en materia de juegos y sorteos "Producciones Móviles S.A. de C.V.", titular del permiso de gobernación DGAJS/SCEVF/P-06/2005-TER en unión de su operador "Capali la Paz Operaciones S.A. de C.V." de conformidad con el oficio DGJS/SCEV/1339/2012.
            Juegos de apuestas prohibidos para menores de edad. Juega responsablemente. No olvides que los propósitos son el entretenimiento, la diversión y el esparcimiento.
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <div className='flex-container align between'>
            <div className='flex-container align between text-center' style={{width: '25%', marginLeft: '3rem'}}>
              <div>
                <a href='https://www.pickwin.mx/legal/terminos' target='_blank'>Términos y condiciones</a>
              </div>
              <div className='menu-separator'></div>
              <div>
                <a href='https://www.pickwin.mx/legal/privacidad' target='_blank'>Aviso de privacidad</a>
              </div>
              <div className='menu-separator'></div>
              <div>
                <a href='https://www.pickwin.mx/legal/legales' target='_blank'>Legales</a>
              </div>
            </div>
            <div className='text-center' style={{width: '50%'}}>
              <img src={pickwinLogo} style={{width: '100px', height: 'auto'}} />
            </div>
            <div className='flex-container align end social-icons' style={{width: '25%', marginRight: '3rem'}}>
              <a target='_blank' href="https://www.facebook.com/pickwin.mx">
                <img src={facebookButton} className="img-responsive navbar-icons social" width="30" height="auto"/>
              </a>
              <a target='_blank' href="https://twitter.com/PickwinMX">
                <img src={twitterButton} className="img-responsive navbar-icons social" width="30" height="auto" />
              </a>
              <a target='_blank'  href='https://instagram.com/pickwin.mx'>
                <img src={instagramButton} className="img-responsive navbar-icons social" width="30" height="auto" />
              </a>
              <a href="mailto:hola@pickwin.net">
                <img src={correoButton} className="img-responsive navbar-icons social" width="30" height="auto"/>
              </a>
            </div>
          </div>
          <div className='text'>
            www.pickwin.mx autorizada mediante el oficio de la Dirección de Juegos y Sorteos de la dirección de control y regulación de centros de apuestas remotas y salas de sorteos mediante el oficio no. DGJS/DCRCA/1584/2019. Es operada en méxico por la permisionaria en materia de juegos y sorteos "Producciones Móviles S.A. de C.V.", titular del permiso de gobernación DGAJS/SCEVF/P-06/2005-TER en unión de su operador "Capali la Paz Operaciones S.A. de C.V." de conformidad con el oficio DGJS/SCEV/1339/2012.
            Juegos de apuestas prohibidos para menores de edad. Juega responsablemente. No olvides que los propósitos son el entretenimiento, la diversión y el esparcimiento.
          </div>
        </MediaQuery>
      </div>
    </>
  );
}

export default NewLogin;