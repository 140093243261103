import React from 'react';
import API from '../../utils/api';
import utils from '../../utils/utils';

import PageWrapper from '../PageWrapper';

import pickwinLogo from 'images/pickwin-winner-50.png';

import logoPick from 'images/qunielasIconVerde-50.png';
import botonApple from 'images/botonappstore2.png';
import botonAndroid from 'images/botonandroid2.jpg';

import RegisterForm  from './RegisterForm';

import { Redirect } from 'react-router-dom';
// import getValidationState from '../RegistroFB';
import FacebookLogin from 'react-facebook-login';

import {
  ControlLabel,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonGroup,
  HelpBlock
} from 'react-bootstrap';

import { CSSTransitionGroup } from 'react-transition-group';

export const DescargarAppBanner = ( { label, path, deviceType ,handleClick } ) => {
  if( $(window).width() >  769 ){
    return null
  }

  return (
    <CSSTransitionGroup
      transitionName="example"
      transitionAppear={true}
      transitionEnter={true}
      transitionLeave={true}
      transitionAppearTimeout={800}
      transitionLeaveTimeout={800}>
      <div className='hidden-md hidden-lg  flex-container justify align column glowingNote'>
        <div className=' register flex-col'  >
          <div className='flex-container justify align'>
            <strong>Descarga la app de Pickwin </strong>
          </div>
          <div className="flex-contaienr justify align column">
              <div className='flex-col text-center '>
                ¡ y ten la mejor experiencia !
              </div>
          </div>
          <div className='flex-row row-no-padding row-center'>
            <a
              className='flex-col col-40 col-offset-5'
              target='blank'
              href='https://itunes.apple.com/mx/app/pickwin/id1424544090'>
            <img
              onClick={() => { handleClick ? handleClick() : null } }
              src={botonApple}
              className='img-responsive '/>
            </a>
            <a
              className='flex-col col-40 col-offset-5'
              target='blank'
              href='https://www.pickwin.mx/landing/androidapp/'
              >
            <img
              onClick={() => { handleClick ? handleClick() : null } }
              src={botonAndroid}
              className='img-responsive'
              />
          </a>
          </div>
        </div>
      </div>
    </CSSTransitionGroup>
  )
}

class RegistroFormPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fingerprint         : '',
      loggedIn            : false,
    };
    // console.log(this.props.location.search););
    this.updateUserData             = this.updateUserData.bind(this);
    this.initFingerprint            = utils.initFingerprint.bind(this);

    this.callbackLogin = response => {
      console.log('Callback Login ', response.data);
      if (response.data != undefined && response.data.success) {
        utils.setUserInfo(response.data);

        if (this.props.type == 'popup') {
          console.log('Registration in Popup');
          this.setState(
            {
              // redirectToLobby: true,
              // registerSuccess: true,
              submitting: false
            },
            () => {
              if (!this.props.updateUserInfo) {
                // utils.catchErrorRaven()
                window.location.assign('/');
              } else {
                this.props.updateUserInfo(response.data);
              }
              this.props.hideRegister();
            }
          );
        } else {
          this.setState({
            redirectToLobby: true,
            registerSuccess: true,
            submitting: false
          });
        }
      } else {
        console.error('Error al hacer login despues del registro', response);
      }
    };

    this.callbackRegister = response => {
      console.log('Callback Register', response.data);
      if (response.data != undefined && response.data.success) {
        //Registro exitoso

        utils.setUserInfo(response.data);
        utils.setToken(response.data.jwtoken);

        if (this.props.type == 'popup') {
          console.log('Registration in Popup');
          this.setState(
            {
              // redirectToLobby: true,
              // registerSuccess: true,
              submitting: false
            },
            () => {
              if (!this.props.updateUserInfo) {
                // utils.catchErrorRaven()
                window.location.assign('/');
              } else {
                this.props.updateUserInfo(response.data);
                utils.clearUserMetadata();

              }
              this.props.hideRegister();
            }
          );
        } else {
          this.setState(
            {
              redirectToLobby: true,
              registerSuccess: true,
              submitting: false
            },
            () => {
              window.location.assign('/');
            }
          );
        }

      } else {
        Sentry.captureException( new Error( JSON.stringify( response.data.errors ) ), {extra:'Error In registro form'} );
        const errors = response.data.errors;
        const errorsKeys = Object.keys(errors);
        console.log(errors, errorsKeys);
        errorsKeys.forEach(err => {
          console.error(err, errors[err]);
          switch (err) {
            case 'nick':
              const errorTypeNick = Object.keys(errors[err]);
              console.log(errors[err][errorTypeNick[0]]);
              this.setState({
                submitting: false,
                registroSuccess: false,
                nickError: true,
                correctNick: false,
                nickErrorType: errorTypeNick[0],
                nickErrorLabel: errors[err][errorTypeNick[0]]
              });
              break;
            case 'email':
              const errorTypeMail = Object.keys(errors[err]);
              console.log(errors[err][errorTypeMail[0]]);
              this.setState({
                submitting: false,
                registroSuccess: false,
                correctMail: false,
                mailError: true,
                mailErrorType: errorTypeMail[0],
                mailErrorLabel: errors[err][errorTypeMail[0]]
              });
              break;
          }
        });
      }
    };
  }

  updateUserData(userD) {
    if (!userD.data) {
      return;
    }
    // console.log('UPDATEDx' ,userD.data.success);
    this.setState({
      loggedIn: userD.data.success,
      userFullData: userD.data,
      userId: userD.data.user.id
    });
    console.log(this.state.userFullData);
  }

  /*****************
   * Lifecycle Methods
   * ****************/
  componentDidMount() {
    let userData = utils.getUserInfo();
    this.setState( {
        mounted: true,
        userData: userData,
        userId: !userData ? null : userData.id,
    });
  }

  render() {
    let conditionalComponent;
    //No ha terminado su registro
    // FB  Register
    if (!this.state.mounted) {
      conditionalComponent = <div className='flex-container column justify align '> <i className='ion-load-b'/></div>;
    }
     else {
      conditionalComponent = (
        <div className="flex-row">
          <div className="flex-col">
            <div className="register-user-form">
              <div id="panelTitle" className="flex-row row-no-padding ">
                <div className='flex-container align between'>
                  <h3 className='font-chivo font-italic no-margin'>REGISTRO</h3>
                  <button
                    style={{background: 'none', border: 'none', fontSize: '30px'}}
                    onClick={this.props.hideRegister}>
                    <div className="btn-icon-close">
                      <i className="ion-ios-close-empty" />
                    </div>
                  </button>
                </div>
              </div>
              <div className='flex-container justify align'>
                <img src={pickwinLogo} className="img-responsive" />
              </div>
              <div className="titleForm flex-col col-90 col-offset-5">
                <div>Llena el formulario para ganar con Pickwin invitando a tus amigos.</div>
                <div style={{ color: '#F9CC30' }}>
                  Asegurate que tu nombre y apellidos sean correctos e iguales a
                  los de tu identificación, te los pediremos al momento de hacer
                  un retiro.
                </div>
              </div>
              <RegisterForm fingerprint={this.state.fingerprint}/>
            </div>
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}

export default RegistroFormPanel;
