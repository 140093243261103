import numeral from 'numeral';
import React from 'react';
import api from '../../utils/api';
import Loading from '../Loading';
import { Link } from "react-router-dom";

const Payments = () => {

  const [payments, setPayments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    api.getPayments()
    .then((response) => {
      const data = response.data;
      setPayments(data.payments);
      setLoading(false);
    });
  }, []);

  const renderPayments = (payments) => {
    return payments.map((payment, idx) => {
      return (
        <tr key={idx}>
          <td>{ new Date(payment.period).toLocaleDateString('es-MX')}</td>
          <td class="text-right">{ numeral(payment.amount/100).format('$0,000.00')}</td>
          <td>{payment.status}</td>
          <td>
            <a target="_blank" href={payment.invoice_pdf}>PDF</a> <a target="_blank" href={payment.invoice_xml}>XML</a>
          </td>
        </tr>
      );
    });
  };

  if ( loading ) {
    return <Loading/>;
  }
  return (
    <>
    <div className="flex-row">
      <div className="flex-col">
        <h3>Pagos</h3>
      </div>
    </div>
    <div className="flex-row">
      <div className="flex-col col-80">
        <table className="table table-condensed">
          <thead>
            <tr>
              <td>Fecha</td>
              <td>Monto</td>
              <td>Estatus</td>
              <td>Factura</td>
            </tr>
          </thead>
          <tbody>
            { renderPayments(payments) }
          </tbody>
        </table>
      </div>
    </div>
    <div className="flex-row">
      <div className="flex-col">
        ¿Necesitas ayuda, tienes dudas o aclaraciones?
        <Link to="/support">
          ¡Contáctanos!
        </Link>
      </div>
    </div>
    </>
  )
};

export default Payments;